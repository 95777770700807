@import "../../../App.scss";

.project-dev {
  text-align: center;
  padding: 84px 0 76px 0;
  .title {
    font-size: 20px;
    color: #fff;
    font-weight: 700;
    padding-bottom: 2rem;
    display: block;
    max-width: 598px;
    margin: 0 auto;
    &.title-wrap {
      margin-top: 114px;
    }
    span {
      color: $color-yellow;
      font-weight: 500;
      font-size: 21px;
      line-height: 29px;
    }
  }

  .project-dev-links {
    width: 100%;
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      gap: 55px;
      width: 100%;
      justify-content: center;
      li {
        list-style: none;

        width: 100%;
        a {
          background: #fff;
          border-radius: 16px;
          padding: 14px 53px;
          display: flex;
          align-items: center;
          flex-direction: row;
          border: 8px solid #fff;
          transition: 0.5s ease;
          min-height: 144px;
          height: 100%;
          img {
            width: 40px;
          }
          &:hover {
            border: 8px solid $color-yellow;
          }
          &.project-active {
            border: 8px solid $color-yellow;
          }
          span {
            font-size: 16px;
            padding-left: 23px;
            color: $color-black;
            text-align: left;
          }
        }
      }
    }
  }
}

.project-form {
  .ant-form-item-label > label {
    color: #fff;
  }
  .ant-input {
    border-radius: 100px;
  }

  textarea.ant-input {
    border-radius: 20px;
  }
}
@media (max-width: 992px) {
  .project-dev {
    padding: 84px 32px 0px 32px;
    .title {
      &.title-wrap {
        margin-top: 79px;
        padding: 0 0 18px 0;
      }
    }
    .project-dev-links {
      ul {
        flex-direction: column;
        gap: 40px;
        li {
          a {
            border-radius: 41px;
            flex-direction: column;
            gap: 24px;
            font-weight: 500;
            font-size: 20px;
            line-height: 30px;
            justify-content: center;
            span {
              padding: 0;
              text-align: center;
            }
          }
        }
      }
    }
  }
}
