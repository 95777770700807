.schedule-meeting-wrap {
}
.schedule-meeting-modal-wrap {
  .ant-modal-content {
    background: #ffffff;
    box-shadow: 0px 0px 19px rgba(32, 14, 50, 0.12);
    border-radius: 20px;
    .ant-modal-body {
      padding: 20px;
      h2 {
        font-weight: 500;
        font-size: 27px;
        line-height: 45px;
        text-align: center;
        color: #000000;
        margin-bottom: 23px;
      }
      .content {
        display: flex;
        flex-direction: row;
        border-radius: 8px;
        box-shadow: 0 1px 8px 0 #00000014;
        .profile-wrap {
          display: flex;
          flex-direction: column;
          text-align: start;
          font-family: "Roboto";
          width: 30%;
          padding: 20px;
          .img-pro {
            height: 50px;
            width: 50px;
            border-radius: 100%;
            overflow: hidden;
          }
          h2 {
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            margin: 0;
            text-align: start;
          }
          .main-title {
            font-weight: 700;
            font-size: 28px;
            line-height: 32px;
            margin: 0 0 19px 0;
          }
          .time-title {
            font-size: 16px;
            line-height: 24px;
            margin: 5px 0;
            color: #1a1a1a9c;
            display: flex;
            align-items: flex-start;
            gap: 8px;

            span {
              svg {
                font-size: 20px;
                margin-top: 3px;
              }
            }
          }
          .content-details {
            line-height: 16px;
            font-size: 14px;
            margin: 19px 0 0 0;
          }
        }
        .celender-wrap {
          width: 70%;
          .go695645437 {
            margin: 0;
            box-shadow: none;
            border-left: 1px solid #1a1a1a1a;
            padding: 20px;
          }
          .go4114704207 {
            font-weight: 400;
            font-size: 16px;
          }
          .go4247048326 {
            .react-calendar__month-view__weekdays__weekday {
              abbr {
                font-weight: 400;
                font-size: 12px;
              }
            }
          }
          .go4247048326 {
            .day-tile {
              abbr {
                font-size: 16px;
                font-weight: 400;
              }
            }
          }
          .go2225988090 {
            overflow: auto;
            .go3749871802 {
              display: none;
            }
            .go2876234792 {
              border-radius: 4px;
              border: 1px solid #005be2;
              margin-bottom: 10px;
              button {
                flex: 1;
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 1024px) {
    width: 90% !important;
    .ant-modal-content {
      .ant-modal-body {
        .content {
          flex-direction: column;
          .profile-wrap {
            width: 100%;
          }
          .celender-wrap {
            width: 100%;
          }
        }
      }
    }
  }
}
