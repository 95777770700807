.bank-account-details {
  &.account-list-wrap {
    .bank-detail-headding {
      &.bank-headding-wrap {
        margin-bottom: 50px;
        max-width: fit-content;
        .headding-wrap {
          display: flex;
          gap: 15px;
          align-items: center;
          margin-bottom: 5px;
          h6 {
            margin: 0;
          }
          button {
            border: 0;
            background-color: #e5c243;
            color: #000;
            border-radius: 35px;
            &:hover {
              border: 0;
            }
          }
        }
      }
      .headding-text {
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
        color: #000000;
      }
    }
    .bank-desc-lable {
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: #000000;
      text-align: left;
      margin-bottom: 12px;
    }
    .bank-desc-text {
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      color: #000000;
      display: flex;
      align-items: center;
      gap: 10px;
      svg {
        cursor: pointer;
      }
      .btn-toggle-wrapper {
        display: flex;
        gap: 10px;
        flex-wrap: wrap;
        .custom-toggle-button {
          padding-left: 0;
          border: 1px solid #000;
          height: 25px;
          box-sizing: border-box;
          border-radius: 20px !important;
          .btn-default {
            padding: 0 10px;
            margin-bottom: 0;
            font-weight: normal;
            font-size: 15px;
            line-height: 20px;
            align-items: center;
            display: flex;
            @media (max-width: 320px) {
              font-size: 14px;
            }
          }
          .active {
            background: #000;
            color: #fff;
            border-radius: 20px;
            border: 1px solid transparent;
            padding: 0 20px;
            font-weight: 500;
            align-items: center;
            display: flex;
            margin: -1px -1px;
          }
        }
        .save-btn {
          background: #e5c243;
          border: 3px solid #e5c243;
          box-sizing: border-box;
          border-radius: 56px;
          padding: 0px 10px;
          height: 25px;

          font-size: 15px;
          line-height: 20px;
        }
      }
    }
    @media (max-width: 767px) {
      padding: 0;
    }
  }
}
