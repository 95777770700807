.editProjectType-main-wrapper {
  font-family: "Roboto";
  width: 100%;

  .mainContainer {
    max-width: 1320px;
    // min-width: 1170px;
    margin: auto;
    // @media (max-width: 1320px) {
    //   min-width: 1170px;
    // }
    @media (max-width: 767px) {
      min-width: 100%;
    }
    @media (max-width: 991px) {
      min-width: 100%;
    }
    @media (max-width: 1200px) {
      min-width: 100%;
    }

    .dashboard-content {
      padding: 15px 60px;
      @media (max-width: 767px) {
        padding: 50px 30px 30px 30px;
      }

      .updateDetailCon {
        .backCon {
          display: flex;
          align-items: center;
          margin-bottom: 30px;

          @media (max-width: 767px) {
            justify-content: center;
          }

          .backIcon {
            cursor: pointer;
          }
          .backText {
            margin-left: 5px;
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 24px;
            text-transform: capitalize;
            color: #000000;
          }
        }

        .detailCardCon {
          background: #ffffff;
          box-shadow: 0px 2px 19px rgba(32, 14, 50, 0.12);
          border-radius: 13px;
          padding: 40px 50px 60px;
          text-align: left;

          @media (max-width: 767px) {
            box-shadow: none;
            padding: 25px;
          }

          .maxConatiner {
            max-width: 750px;
            margin: auto;

            .editHeading {
              font-family: Roboto;
              font-style: normal;
              font-weight: 500;
              font-size: 27px;
              line-height: 49px;
              text-align: center;
              text-transform: capitalize;
              color: #000000;
              margin-bottom: 50px;

              @media (max-width: 767px) {
                font-size: 20px;
                line-height: 28px;
                text-align: center;
                margin-bottom: 30px;
              }
            }

            .buttonContainer {
              display: flex;
              align-items: center;
              justify-content: center;
              .ant-space {
                gap: 20px !important;
              }
              button {
                font-family: Roboto;
                font-style: normal;
                font-weight: 500;
                font-size: 20px;
                line-height: 24px;
                text-align: center;
                text-transform: capitalize;
                color: #000000;
                min-width: 125px;
                min-height: 50px;
              }
              .sendButton {
                background: #e5c243;
                border-radius: 53px;
              }
              .cancelButton {
                border: 2px solid #e5c243;
                box-sizing: border-box;
                border-radius: 53px;
              }
            }

            .projectTypeCon {
              display: flex;
              flex-wrap: wrap;
              margin-bottom: 40px;
              justify-content: center;

              @media (max-width: 767px) {
                flex-direction: column;
              }

              .projectTypeItem {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 20px;
                margin-bottom: 20px;
                background: #ffffff;
                border: 4px solid #ffffff;
                box-sizing: border-box;
                box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
                border-radius: 94px;
                min-width: 165px;
                padding: 10px 15px;
                cursor: pointer;
                svg {
                  margin-right: 15px;
                }
                .projectTypeText {
                  font-family: Roboto;
                  font-style: normal;
                  font-weight: 500;
                  font-size: 18px;
                  line-height: 41px;
                  text-align: center;
                  text-transform: capitalize;
                  color: #000000;
                }

                @media (max-width: 767px) {
                  margin-right: 0px;
                  margin-bottom: 35px;
                }
              }
              .selectedProjectTypeItem {
                border: 6px solid #e5c243;
              }
            }
          }
        }
      }
    }
  }
}
