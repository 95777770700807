.invite-team-member-wrapper {
  padding: 50px 50px 50px 30px;
  @media (min-width: 992px) and (max-width: 1199px) {
    padding: 50px 30px 50px 15px;
  }
  @media (max-width: 767px) {
    padding: 30px 30px;
  }
  .invite-team-member-form {
    border: 2px solid #e8e8e8;
    box-sizing: border-box;
    border-radius: 3px;
    padding: 40px 45px;
    @media (min-width: 992px) and (max-width: 1199px) {
      padding: 40px 30px;
    }
    @media (max-width: 767px) {
      padding: 40px 25px;
    }
    .invite-team-member-headding {
      font-weight: 500;
      font-size: 19px;
      line-height: 22px;
      display: flex;
      align-items: center;
      color: #000000;
    }
    .invite-team-member-row {
      margin-top: 33px;
      text-align: left;

      .invite-team-member-formgroup {
        .formgroup-wrapper {
          .formgroup-lable {
            font-weight: normal;
            font-size: 15px;
            line-height: 22px;
            color: #000000;
            margin-bottom: 10px;
          }
          .formgroup-input {
            background: #ffffff;
            opacity: 1;
            border: 1px solid #000000;
            box-sizing: border-box;
            border-radius: 38px;
            padding: 10px 15px;
            font-weight: normal;
            font-size: 15px;
            line-height: 24px;
            color: #000000;
            &::placeholder {
              color: rgba(0, 0, 0, 0.5);
            }
          }
        }
      }
      .invite-team-member-buttons {
        .invite-now-button {
          font-weight: 500;
          font-size: 17px;
          line-height: 36px;
          height: 50px;
          text-align: center;
          color: #000000;
          background: #e5c243;
          border-radius: 38px;
          padding: 5px 55px;
          border: unset;
          @media (max-width: 767px) {
            padding: 5px 25px;
          }
        }
      }
    }
  }
  .invite-team-member-table {
    .table-header {
      background: #000000;
      border-radius: 14px;
      height: 53px;
      color: #ffffff;
      margin: 40px 0 20px;
      padding: 0 34px;
      @media (min-width: 991px) and (max-width: 1199px) {
        padding: 0 12px;
      }
      .th-data {
        font-size: 16px;
        line-height: 22px;
        cursor: pointer;
        color: #fff;
        flex: 1;
        display: flex;
        justify-content: flex-start;
        @media (min-width: 992px) and (max-width: 1199px) {
          font-size: 14px;
        }
        &:first-child {
          flex: 1 1;
          display: flex;
          justify-content: flex-start;
        }
      }
    }
    .table-body {
      width: 100%;
      margin-bottom: 60px;
      @media (max-width: 991px) {
        margin-top: 25px;
      }
      .table-row {
        padding: 14px 34px;
        flex: 1;
        display: flex;
        background: #ffffff;
        box-shadow: 0px 2px 19px rgba(32, 14, 50, 0.12);
        border-radius: 13px;
        margin-bottom: 20px;
        @media (min-width: 768px) and (max-width: 991px) {
          padding: 14px 20px;
        }
        @media (max-width: 767px) {
          padding: 15px;
          flex-direction: column;
          align-items: center;
          display: block;
        }
        .invite-team-cell {
          display: flex;
          flex: 1;
          align-items: center;
          @media (max-width: 767px) {
            margin-bottom: 20px;
          }
          &:first-child {
            flex: 1;
            display: flex;
            justify-content: flex-start;
          }
          &:nth-of-type(2) {
            @media (max-width: 991px) {
              flex: 0.5;
            }
          }
          .invite-team-wrap {
            @media (max-width: 767px) {
              width: 270px;
              justify-content: space-between;
            }
            @media (max-width: 375px) {
              width: 100%;
              flex-direction: row;
            }
          }
          .cell-text-name {
            font-weight: 500;
            font-size: 17px;
            line-height: 18px;
            color: #000000;
          }
          .cell-text-lable {
            font-weight: normal;
            font-size: 16px;
            line-height: 22px;
            color: #000000;
          }
          .cell-text-email {
            font-weight: normal;
            font-size: 17px;
            line-height: 18px;
            color: #5b5b5b;
          }
          .cm-switch {
            .ant-switch {
              background: rgba(0, 0, 0, 0.09);
              &::before {
                background-color: #989898;
              }
            }
          }
          .cm-switch.ant-switch-checked {
            background: rgba(0, 0, 0, 0.09);

            .ant-switch-handle {
              &::before {
                background-color: #000;
              }
            }
          }
        }
      }
    }
  }
}
