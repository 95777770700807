@import "../../../App.scss";

.onboard {
  height: 100vh;
  background: #000;

  .header {
    padding: 34px 44px 0 44px;
  }
  .signup-form-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: auto;
    height: 100%;
    .signup-form {
      background-color: #fff;
      padding: 2rem;
      border-radius: 1.5rem;
      width: 420px;
      iframe {
        margin: 0 auto !important;
      }
      .ant-form-vertical {
        .ant-form-item-label {
          font-weight: 400;
          font-size: 15px;
          line-height: 18px;
          color: #000000;
          padding-bottom: 10px;
        }
        .ant-form-item-control-input {
          .ant-input-affix-wrapper {
            border: 1px solid #d4d4d4;
            border-radius: 5px;
          }
        }
        .ant-form-item-explain-error {
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          margin: 8px 0 12px 0;
          color: #ff5350;
        }
      }
      .ant-divider-horizontal {
        &.ant-divider-with-text {
          margin: 18px 0;
          &::before,
          &::after {
            border-top: 1px solid #707070;
          }
        }
        .ant-divider-inner-text {
          padding: 0 10px;
          font-size: 12px;
          line-height: 14px;
          color: #333333;
        }
      }
      .btn-class {
        width: 100%;
        background-color: red;
        justify-content: center;
        box-shadow: none !important;
        border: 1px solid #d4d4d4 !important;
      }
      .signup-google-button {
        width: 100%;
        justify-content: center;
        box-shadow: none !important;
        border: 1px solid #d4d4d4 !important;
      }
      .ant-form-item {
        margin-bottom: 12px;
        .ant-input-password {
          .ant-input-suffix {
            .ant-input-feedback-icon {
              display: flex;
            }
          }
        }
      }
      .terms {
        text-align: center;
        color: $color-grey;
        padding-top: 3px;
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        &--links {
          color: $color-blue;
          margin: 0 3px;
        }
      }
      .btn-yelow {
        margin-top: 24px;
        button {
          padding: 8px;
          height: 40px;
          .btn-info-wrap {
            display: grid;
            grid-template-columns: 20px 1fr;
            min-width: 80px;
            margin: 0 auto;
            width: fit-content;
            margin-left: calc(50% - 50px);
            span {
              font-family: "Roboto";
              font-style: normal;
              font-weight: 700;
              font-size: 17px;
              line-height: 20px;
            }
          }
        }
      }
      .active-link {
        a {
          font-weight: 400;
          font-size: 15px;
          line-height: 18px;
        }
      }
      .login-link {
        color: $color-yellow;
        text-decoration: underline;
      }

      .btn-primary {
        background-color: $color-yellow;
        border-color: $color-yellow;
        color: $color-black;
        font-weight: 700;
      }
    }
    @media screen and (max-width: 576px) {
      align-items: flex-start;
      padding: 58px 0;
    }
  }
}
