.add-new-features-main {
  margin: 15px 0;
  .add-new-features-wrapper {
    padding: 0 60px;

    .add-new-features-back {
      cursor: pointer;
      width: fit-content;
      .features-back-text {
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        text-transform: capitalize;
        color: #000000;
        margin-left: 10px;
      }
    }
    .add-new-feature-card {
      background: #ffffff;
      box-shadow: 0px 2px 19px rgba(197, 197, 197, 0.47);
      border-radius: 13px;
      min-height: calc(100vh - 215px);
      .add-new-feature-header {
        background: #000000;
        border-radius: 13px 13px 0px 0px;
        padding: 25px 35px 25px 42px;
        margin-top: 25px;
        .feature-header-setting {
          .feature-header-setting-icon {
            color: #fff;
            font-size: 35px;
            margin-right: 15px;
          }
          .feature-header-setting-text {
            font-weight: 500;
            font-size: 23px;
            line-height: 24px;
            text-transform: capitalize;
            color: #ffffff;
            @media (max-width: 767px) {
              font-size: 20px;
            }
          }
        }
        .feature-header-setting-close {
          color: #fff;
          font-size: 15px;
        }
      }
      .add-new-feature-body {
        margin: 65px auto 0 auto;
        padding: 0 30px;
        max-width: 752px;
        .feature-form-wrapper {
          .feature-form {
            &:last-child {
              margin-top: 48px;
            }
            .feature-lable {
              font-weight: 500;
              font-size: 16px;
              line-height: 22px;
              color: #000000;
              margin-bottom: 10px;
            }
            .feature-input {
              background: #ffffff;
              border: 2px solid #d4d4d4;
              box-sizing: border-box;
              border-radius: 38px;
              height: 46px;
              box-shadow: none;
              padding: 6px 20px;
              &.custom-error {
                border: 2px solid #ffb0b1;
              }
            }
            .textarea-wrap {
              border: 2px solid #d4d4d4;
              background: #ffffff;
              box-sizing: border-box;
              border-radius: 38px;
              padding: 20px;
              height: 100%;

              &.error-wrapper {
                border: 2px solid #ffb0b1;
              }

              textarea {
                border: 0;
                border-color: transparent;
                border-radius: 0;
                box-shadow: none;
              }

              .feature-input {
                background: transparent;
                border: 0;
                height: 100%;
                padding: 0;
              }
            }
            .validation-eror {
              color: red;
              margin-top: 5px;
            }
          }
        }
        .feature-buttons-group {
          margin-top: 90px;
          padding-bottom: 60px;
          justify-content: center;
          .feature-send {
            background: #e5c243;
            border-radius: 53px;
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            text-align: center;
            text-transform: capitalize;
            color: #000000;

            border: 2px solid transparent;
            height: 54px;
            max-width: 125px;
            flex: 1;
          }
          .feature-cancel {
            border: 2px solid #e5c243;
            border-radius: 53px;
            height: 54px;
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            text-align: center;
            text-transform: capitalize;
            color: #000000;

            margin-left: 20px;
            background-color: transparent;
            max-width: 125px;
            flex: 1;
          }
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .add-new-features-main {
    margin: 42px 0;
    .add-new-features-wrapper {
      padding: 0 18px;
      .add-new-feature-card {
        .add-new-feature-header {
          padding: 25px;
          .feature-header-setting-close {
            display: none;
          }

          .feature-header-setting {
            .feature-header-setting-icon {
              margin-right: 10px;
              font-size: 31px;
            }
          }
        }
        .add-new-feature-body {
          margin: 50px auto 0 auto;
          .feature-buttons-group {
            margin-top: 90px;
            max-width: 270px;
            gap: 10px;
            margin: 90px auto 0 auto;
            justify-content: space-between;
            button {
              &.feature-cancel {
                margin: 0;
              }
            }
          }
        }
      }
    }
  }
}
