@import "../../../App.scss";

.onboard-land {
  display: grid;
  align-items: center;
  justify-content: center;
  text-align: center;
  &--image {
    width: 330px;
    height: 250px;
    margin: 0 auto;
  }
  &--title {
    padding-top: 2rem;
    h1 {
      color: #fff;
      font-size: 36px;
      span {
        color: $color-yellow;
      }
    }
  }
}

@media (max-width: 767px) {
  .onboard-land {
    &--image {
      width: 50%;
      height: auto;
      margin: 0 auto;
    }

    &--title {
      padding-top: 2rem;
      h1 {
        font-size: x-large;
        font-weight: 700;
      }
    }
  }
}
