.main-layout {
  background-color: #fff !important;
  .opened {
    @media (max-width: 767px) {
      position: absolute;
      top: 0;
    }
  }
  .closed {
    @media (max-width: 767px) {
      display: none;
    }
  }
  .ant-layout-sider {
    width: 90px !important;
    height: 100vh;
    flex: unset !important;
    min-width: unset !important;
    max-width: unset !important;
    border-radius: 0px 33px 33px 0px;
    background-color: transparent;

    .ant-layout-sider-children {
      width: 90px;
      height: 100%;
      position: fixed;
      z-index: 1;
      .ant-menu {
        width: 100%;
        height: 100%;
        border-radius: 0px 33px 33px 0px;
        background-color: #000000;
        overflow-y: auto;
        overflow-x: hidden;
        .close-item {
          margin-top: 24px;
          margin-bottom: 0 !important;
          .anticon {
            width: 100%;
            font-size: 28px;
            margin-left: 8px;
          }
        }
        .ant-menu-item-disabled {
          background-color: transparent;
          color: #fff !important;
          cursor: auto !important;
        }
        .lamp-menu-item {
          margin-top: 18px;
          margin-bottom: 0 !important;
        }
        .ant-menu-item {
          margin-bottom: 20px;
          height: unset !important;
          line-height: unset !important;
          color: #fff;
          font-size: 10px;
          &.item-disabled {
            opacity: 0.4;
          }
          .ant-menu-title-content {
            padding: 12px 0;
            line-height: 0 !important;
            text-align: center;
            img {
              margin-bottom: 14px;
            }
            .left-menu-p {
              margin-bottom: 0 !important;
              font-size: 10px;
              color: #fff;
            }
          }
          @media (max-width: 767px) {
            height: 72px;
            margin-left: auto;
            display: flex;
            justify-content: center;
          }
        }
        .ant-menu-item-selected {
          background: #e5c243;
          border-radius: 13px 0px 0px 13px;
          width: 100%;
          margin-left: auto;
          .ant-menu-title-content {
            .left-menu-p {
              color: #000;
            }
          }
        }
        .ant-menu-item-selected:after {
          border-right: unset !important;
        }
      }
    }
  }
  .ant-layout-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: #fff;
    padding: 12px 40px 4px !important;
    height: unset !important;
    .about-self-username {
      font-weight: normal;
      font-size: 19px;
      line-height: 36px;
      color: #5b5b5b;
      padding: 0 10px;
      @media (max-width: 767px) {
        color: #fff;
      }
    }
    .headerProfilepic {
      max-width: 46px;
      width: 46px;
      height: 46px;
      border-radius: 50%;
    }
    @media (max-width: 767px) {
      justify-content: space-between;
      background-color: #000;
      padding: 12px 20px 4px !important;
    }
    .menu-icon {
      font-size: 24px;
    }
  }
  .ant-layout {
    background-color: #fff;
  }
  .chat-icon-wrapper {
    background: #ffffff;
    box-shadow: 0px 0px 13px rgba(201, 139, 210, 0.4);
    width: 95px;
    height: 95px;
    border-radius: 50%;
    position: fixed;
    right: 75px;
    bottom: 50px;
    @media (min-width: 992px) and (max-width: 1199px) {
      right: 55px;
      bottom: 40px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      right: 30px;
      bottom: 40px;
    }
    @media (max-width: 767px) {
      right: 30px;
      bottom: 35px;
    }
    @media (max-width: 320px) {
      right: 20px;
      bottom: 25px;
    }
    .chat-warpper {
      background: #e5c243;
      border-radius: 50%;
      width: 80px;
      height: 80px;
      .chat-icon {
        width: 40px;
      }
    }
  }
  .chatting-main {
    position: fixed;
    right: 50px;
    bottom: 20px;
    @media (min-width: 992px) and (max-width: 1199px) {
      right: 40px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      right: 30px;
      bottom: 40px;
    }
    @media (max-width: 767px) {
      right: 0;
      bottom: unset;
      padding: 15px;
      top: 50%;
      transform: translateY(-50%);
      position: relative;
    }
    .chatting-wrapper {
      background: #ffffff;
      box-shadow: 0px 2px 19px rgba(201, 139, 210, 0.47);
      border-radius: 13px;
      width: 468px;
      @media (max-width: 767px) {
        width: 100%;
        height: auto;
      }
      .chatting-wrapper-header {
        background: #000000;
        border-radius: 13px 13px 0px 0px;
        color: #fff;
        padding: 13px 30px;
        .chatting-user-wrapper {
          .chatting-name-wrapper {
            margin-left: 20px;
            .chatting-name {
              font-weight: bold;
              font-size: 21px;
              text-transform: capitalize;
              color: #ffffff;
            }
            .chatting-sub-title {
              font-weight: normal;
              font-size: 11px;
              line-height: 13px;
              color: #ffffff;
            }
          }
        }
      }
      .chatting-wrapper-body {
        padding: 40px 70px;
        @media (max-width: 767px) {
          padding: 30px;
        }
        .chatting-wrapper-main {
          .chatting-body-name {
            font-weight: bold;
            font-size: 27px;
            line-height: 41px;
            text-align: center;
            text-transform: capitalize;
            color: #000000;
            margin: 30px 0 10px;
            @media (max-width: 767px) {
              font-size: 24px;
              margin: 20px 0 10px;
            }
          }
          .chatting-body-sub-title {
            font-weight: 500;
            font-size: 20px;
            line-height: 30px;
            color: #000000;
            text-align: center;
            margin-bottom: 20px;
            @media (max-width: 767px) {
              font-size: 18px;
              margin-bottom: 10px;
            }
          }
          .chatting-body-text {
            font-weight: normal;
            font-size: 16px;
            line-height: 26px;
            color: #000000;
            text-align: center;
            @media (max-width: 767px) {
              font-size: 14px;
            }
          }
          .chatting-body-button {
            background: #e5c243;
            border-radius: 34px;
            font-weight: 500;
            font-size: 22px;
            line-height: 27px;
            text-align: center;
            text-transform: capitalize;
            color: #000000;
            padding: 12px 30px;
            border: unset;
            margin-top: 20px;
            @media (max-width: 767px) {
              font-size: 20px;
              margin-top: 10px;
            }
          }
        }
      }
      .chatting-chat-wrapper-body {
        .chatting-chat-wrapper-main {
          .chatting-features {
            background: #e5c243;
            padding: 12px 20px;
            width: 100%;
            .chatting-features-text {
              font-weight: 500;
              font-size: 15px;
              line-height: 24px;
              text-transform: capitalize;
              color: #000000;
              margin-left: 5px;
            }
          }
          .chatting-chat {
            width: 100%;
            height: 450px;
            overflow-y: auto;
            overflow-x: hidden;
            &::-webkit-scrollbar {
              width: 0px;
              background: transparent;
            }
            @media (max-width: 767px) {
              height: 400px;
            }
            .chatting-admin-chat {
              width: 100%;
              margin: 10px 0;
              padding: 0 20px;
              .admin-chat-wrapper {
                background: rgba(0, 0, 0, 0.07);
                border-radius: 15px 15px 15px 0px;
                padding: 29px 32px;
                width: 80%;
                @media (min-width: 768px) and (max-width: 991px) {
                  width: 85%;
                }
                @media (max-width: 767px) {
                  width: 93%;
                }
                .admin-chat-message {
                  margin-left: 20px;
                  .admin-name {
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 150%;
                    color: #000000;
                    margin-bottom: 3px;
                  }
                  .admin-meassage {
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 150%;
                    color: #000000;
                    word-break: break-all;
                  }
                }
              }
              .admin-chat-date {
                font-weight: normal;
                font-size: 12px;
                line-height: 150%;
                color: rgba(0, 0, 0, 0.4);
              }
            }
            .chat-user-chat {
              width: 100%;
              margin: 10px 0;
              padding: 0 20px;
              .user-chat-wrapper {
                background: #000;
                border-radius: 15px 15px 0 15px;
                padding: 29px 32px;
                width: 80%;
                @media (min-width: 768px) and (max-width: 991px) {
                  width: 85%;
                }
                @media (max-width: 767px) {
                  width: 93%;
                }
                .user-chat-message {
                  .user-meassage {
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 150%;
                    color: #fff;
                    word-break: break-all;
                  }
                }
              }
              .user-chat-date {
                font-weight: normal;
                font-size: 12px;
                line-height: 150%;
                color: rgba(0, 0, 0, 0.4);
              }
            }
          }
          .chatting-card-meassage {
            padding: 20px 20px 30px;
            .chatting-meassage-wrapper {
              column-gap: 15px;
              .measssage-input {
                position: relative;
                width: 100%;
                .input {
                  width: 100%;
                  border: 2px solid #000000;
                  box-sizing: border-box;
                  border-radius: 15px;
                  padding: 10px 35px;
                }
                .meassage-icon {
                  position: absolute;
                  top: 50%;
                  transform: translateY(-50%);
                  right: 20px;
                }
              }
              .chat-icons {
                background: #000000;
                border-radius: 15px;
                padding: 12px;
                .icons {
                  color: #fff;
                  font-size: 20px;
                }
              }
              .chat-files {
                background: #000000;
                border-radius: 15px;
                padding: 12px;
                .files {
                  color: #fff;
                  font-size: 20px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.profile-menu-wrap {
  background: #ffffff;
  border: 1px solid #a0a7b2;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px rgba(22, 38, 66, 0.12);
  border-radius: 5px;
  padding: 16px 0px;
  &::before {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    background: #ffffff;
    top: -4px;
    right: 37px;
    transform: rotate(45deg);
    border-top: 2px solid #a0a7b2;
    border-left: 2px solid #a0a7b2;
    border-radius: 2px;
  }
  .profile-menu-item {
    margin-bottom: 8px;
    padding: 10px 36px;
    text-align: start;
    cursor: pointer;
    img {
      height: 20px;
      width: 20px;
      margin-right: 24px;
    }
    .item-text {
      color: #000;
      font-size: 17px;
      font-weight: 500;
      line-height: 24px;
    }
  }
  .profile-menu-item:hover {
    background-color: #e5c243;
  }
  :last-child {
    margin-bottom: 0;
  }
}
.notificationModal-wrap {
  &.ant-popover {
    max-width: 328px;
    width: 100%;
    .ant-popover-inner-content {
      padding: 0;
      margin-bottom: 16px;
      position: relative;
      .ant-spin-nested-loading {
        min-height: 300px;
      }
      .ant-empty {
        min-height: calc(300px - 42px);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }
      .ant-spin {
        min-height: calc(300px - 42px);
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .notification-modal {
      border-radius: 6px;
      .notification-header {
        color: #fff;
        background-color: black;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px 19px 15px 30px;
        border-radius: 10px;
        cursor: pointer;
        margin-bottom: 20px;
      }
    }
    .notification-content {
      max-height: 500px;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 4px;
        background: #dadada;
        border-radius: 50px;
      }
      &::-webkit-scrollbar-thumb {
        background: #e5c243;
        border-radius: 50px;
      }
    }
    .ant-popover-content {
      .ant-popover-arrow {
        top: 0.5px;
        .ant-popover-arrow-content {
          border: 1px solid #fff;
          height: 14px;
          width: 14px;
          background: #000;
          &::before {
            background: linear-gradient(to left, #000 50%, #fff 50%) no-repeat -10px -10px;
          }
        }
      }
      .ant-popover-inner {
        border-radius: 6px;
        border: 2px solid #fff;
        min-height: 100px;
        box-shadow: 0px 4px 12px rgba(22, 38, 66, 0.14);

        .notification-data-wrap {
          display: flex;
          align-items: center;
          padding: 21px 0;
          margin: 0 18px;
          border-bottom: 1px solid #d4d4d4;
          &:last-child {
            border: 0;
          }
          P {
            margin-bottom: 2px;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #000000;
          }
          span {
            font-weight: 400;
            font-size: 13px;
            line-height: 21px;
            color: rgba(22, 38, 66, 0.52);
          }
          .list-icon {
            min-width: 53px;
            display: flex;
            align-items: center;
            justify-content: center;
            height: auto;
          }
        }
      }
    }
    @media (max-width: 576px) {
      // max-width: calc(100% - 16px);
      margin: 0 8px;
      // width: calc(100% - 16px);
      right: 0 !important;
    }
  }
}
.menu-popover-custom-main {
  .ant-popover-content {
    border: 2px solid #fff;
    border-radius: 7px;
    .ant-popover-arrow-content {
      width: 16.313709px;
      height: 16.313709px;
      background-color: #fff;
      &::before {
        background: #000;
        left: -9px;
        top: -9px;
      }
    }
  }
  .ant-popover-inner-content {
    padding: 14px 20px;
    background: #000;
    color: #fff;
    border-radius: 7px;
    .popup-content-wrapper {
      max-width: 143px;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
    }
  }
}
