@import '../../../App.scss';

.confirm-email {
  display: grid;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 4rem;
  justify-items: center;
 img{
  width: 120px;
  margin: 0 auto;
 }

 .title {
  padding-top: 1rem;
    color: #fff;
    font-size: 24px;
    font-weight: 700;
    span {
      color: $color-yellow;
    
  }
}


}
