@import '../../../App.scss';

.password-success{
  display: grid;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 8rem;
  justify-items: center;
 img{
  width: 80px;
  margin: 0 auto;
 }

 .title {
  padding-top: 1rem;
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    color: #FFF;
    
}
.sub-text{
  color: #FFF;
  font-size: 14px;
  font-weight: 400;
}


}
