@import "../../../App.scss";

.developmentStage {
  text-align: center;
  .title {
    font-size: 27px;
    color: #fff;
    font-weight: 900;
    padding: 35px 0 41px 0;
    display: block;
    span {
      color: $color-yellow;
    }
    &.document-wrap {
      margin-top: 66px;
    }
  }

  .developmentStage-links {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: grid;
      grid-template-columns: repeat(3, calc(33% - 11px));
      gap: 22px;
      @media (max-width: $breakpoint-mobile) {
        grid-template-columns: 1fr;
      }
      @media (max-width: $breakpoint-tablet) {
        grid-template-columns: 1fr;
      }
      @media (max-width: $breakpoint-ipad) {
        // grid-template-columns: 1fr;
      }

      li {
        list-style: none;
        a {
          background: #fff;
          padding: 14px 32px;
          display: flex;
          align-items: center;
          flex-direction: row;
          border: 8px solid #fff;
          border-radius: 28px;
          transition: 0.5s ease;
          min-height: 144px;
          text-align: left;
          height: 100%;
          .img-wrap {
            height: 100%;
            display: flex;
            align-items: center;
          }
          img {
            width: auto;
            height: 53px;
          }
          &:hover {
            border: 8px solid $color-yellow;
          }
          &.project-active {
            border: 8px solid $color-yellow;
            border-radius: 28px;
          }
          span {
            font-weight: 500;
            font-size: 18px;
            line-height: 29px;
            text-transform: capitalize;
            color: #000000;
            padding-left: 25px;
            font-family: "Roboto";
            font-style: normal;
          }
        }
      }
    }
  }
}

.upload-file {
  .upload-docs-dragger {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .icon-upload {
    margin: 10px auto 20px auto;
    img {
      width: 100px;
    }
  }
  .file-main-wrap {
    margin-top: 15px;
    .file-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 20px;
      width: 80%;
      margin: 15px auto 0 auto;
      gap: 10px;
      p {
        margin: 0;
        display: flex;
        align-items: center;
        span {
          text-align: left;
        }
      }
    }
  }
  .ant-upload.ant-upload-drag {
    background: $color-black;
    border: 7px dashed $color-yellow;
    border-radius: 33px;
    color: #fff;
    &:hover {
      border: 7px dashed $color-yellow;
    }
    p {
      &.ant-upload-text {
        color: #fff;
      }
      &.ant-upload-hint {
        color: #fff;
      }
    }
    .ant-upload {
      &.ant-upload-btn {
        padding: 56px 15px;
      }
    }
    .ant-upload-drag-container {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 60px;
      .icon-upload {
        margin: 0;
      }
      .upload-right-section {
        p {
          font-weight: 700;
          font-size: 19px;
          line-height: 23px;
          text-align: center;
          color: #ffffff;
          margin: 0 20px 12px 20px;
        }
        button {
          background: #e5c243;
          border-radius: 36px;
          font-weight: 700;
          font-size: 20px;
          line-height: 24px;
          text-align: center;
          color: #000000;
          padding: 20px;
          height: auto;
          width: 100%;
          border: 0;
        }
      }
    }
  }
}

.anticon-file-pdf {
  margin-right: 15px;
}
@media (max-width: 1199px) {
  .developmentStage {
    .developmentStage-links {
      ul {
        li {
          a {
            padding: 14px 20px;
            flex-direction: column;
            text-align: center;
            gap: 22px;
            .img-wrap {
              height: auto;
            }
            span {
              padding: 0;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .developmentStage {
    .developmentStage-links {
      ul {
        gap: 29px;
        margin: 0 48px;
        li {
          a {
            padding: 38px 27px 45px 27px;
            height: auto;
          }
        }
      }
    }
  }
  .upload-file {
    .ant-upload {
      &.ant-upload-drag {
        .ant-upload-drag-container {
          flex-direction: column;
          .upload-right-section {
            p {
              margin: 20px 0;
            }
          }
        }
      }
    }
    .ant-upload.ant-upload-drag {
      .ant-upload.ant-upload-btn {
        padding: 56px 20px;
      }
    }
    .file-main-wrap {
      .file-wrap {
        width: 95%;
        font-size: 14px;
      }
    }
  }
  .helpful-documents-wrap {
    padding: 0 20px;
  }
}
