.bank-account-details-wrap {
  padding: 0 40px;
  text-align: left;
  width: 100%;
  .btn-wrap {
    display: flex;
    align-items: flex-end;
    .bank-card-button {
      background: #e5c243;
      border: 3px solid #e5c243;
      box-sizing: border-box;
      border-radius: 56px;
      padding: 8px 20px;
      margin-left: auto;
      .bank-card-img {
        width: 22px;
      }
      .bank-card-text {
        font-weight: normal;
        font-size: 15px;
        line-height: 15px;
        color: #000000;
        margin-left: 10px;
      }
    }
  }
  .bank-card-button-wrap {
    border: 0;
    background: transparent;
    display: flex;
    align-items: center;
    column-gap: 5px;
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
    text-transform: capitalize;
    color: #000000;
  }

  .bank-detail-headding {
    margin: 55px 0 50px 0;
    width: fit-content;
    .headding-bank {
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: #000000;
      gap: 15px;
      align-items: center;
      display: flex;
      gap: 10px;
      flex-wrap: wrap;
      max-width: 100%;
      width: fit-content;
      min-width: 100%;
      .bank-details-edit-icon {
        width: 23px;
        height: 23px;
        border: 0.5px solid #000000;
        border-radius: 50%;
        padding: 3px;
        margin-left: 5px;
        .edit-icon {
          font-size: 14px;
        }
      }
      button {
        background: #e6c350;
        color: #000;
        border-radius: 30px;
        border: 0;
        &:hover {
          border: 0;
        }
      }
    }
    .headding-text {
      font-weight: normal;
      font-size: 12px;
      line-height: 17px;
      color: #000000;
    }
  }
  .bank-desc-wrapper {
    .bank-desc {
      .bank-desc-lable {
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        color: #000000;
        margin-bottom: 12px;
      }
      .bank-desc-text {
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        color: #000000;
        display: flex;
        align-items: center;
        gap: 10px;
        svg {
          cursor: pointer;
        }
        .btn-toggle-wrapper {
          display: flex;
          gap: 10px;
          flex-wrap: wrap;
          .custom-toggle-button {
            padding-left: 0;
            border: 1px solid #000;
            height: 25px;
            box-sizing: border-box;
            border-radius: 20px !important;
            .btn-default {
              padding: 0 10px;
              margin-bottom: 0;
              font-weight: normal;
              font-size: 15px;
              line-height: 20px;
              align-items: center;
              display: flex;
              @media (max-width: 320px) {
                font-size: 14px;
              }
            }
            .active {
              background: #000;
              color: #fff;
              border-radius: 20px;
              border: 1px solid transparent;
              padding: 0 20px;
              font-weight: 500;
              align-items: center;
              display: flex;
              margin: -1px -1px;
            }
          }
          .save-btn {
            background: #e5c243;
            border: 3px solid #e5c243;
            box-sizing: border-box;
            border-radius: 56px;
            padding: 0px 10px;
            height: 25px;

            font-size: 15px;
            line-height: 20px;
          }
        }
      }
    }
  }
  @media (max-width: 576px) {
    padding: 0;
    .bank-card-button {
      margin: 24px auto auto auto;
    }
  }
}
