@import "../../../App.scss";
.application-wrap {
  max-width: 1028px;
  margin: 84px auto 0 auto;
  .budjet {
    text-align: center;
    .title {
      font-weight: 900;
      font-size: 27px;
      line-height: 49px;
      color: #fff;
      padding-bottom: 41px;
      display: block;
      span {
        color: $color-yellow;
      }
    }

    .budjet-links {
      margin-bottom: 98px;
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        gap: 29px;
        li {
          list-style: none;
          margin: 4px;
          // flex: 0 1 calc(25% - 4px);

          a {
            background: #fff;
            border-radius: 100px;
            padding: 9px 22px;
            display: flex;
            align-items: center;
            flex-direction: row;
            border: 8px solid #fff;
            transition: 0.5s ease;
            img {
              width: 33px;
            }
            &:hover {
              border: 8px solid $color-yellow;
            }
            &.project-active {
              border: 8px solid $color-yellow;
            }
            span {
              font-weight: 500;
              font-size: 21px;
              line-height: 34px;
              padding-left: 14px;
              color: $color-black;
            }
          }
        }
      }
    }
  }

  .budject-slider {
    width: 90%;
    margin: 0 auto 153px auto;
    .ant-slider {
      height: 15px;
      padding: 0;
      &:hover {
        .ant-slider-handle:not(.ant-tooltip-open) {
          border-color: #fff;
          background-color: $color-yellow;
        }
      }
      .ant-slider-dot {
        display: none;
      }
      .ant-slider:hover .ant-slider-track {
        background-color: $color-yellow;
      }
      .ant-slider-step {
        height: 15px;
      }
      .ant-slider-mark {
        top: 42px;
        font-size: 18px;
        strong {
          font-weight: 900;
          font-size: 27px;
          line-height: 49px;
          /* or 181% */

          text-align: center;
          text-transform: capitalize;

          color: #ffffff;
        }
      }

      .ant-slider-dot {
        height: 14px;
        width: 14px;
      }

      .ant-slider-rail {
        height: 15px;
        border-radius: 35px;
      }
      .ant-slider-track {
        height: 15px;
        background-color: $color-yellow;
        border-radius: 35px;
      }
      .ant-slider-dot-active {
        border-color: $color-yellow;
      }
      .ant-slider-handle {
        border: solid 8px #fff;
        width: 51px;
        height: 51px;
        top: -15px;
        background-color: $color-yellow;
        box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.38);
        position: relative;
      }
    }
    .title {
      color: #fff;
      font-weight: 900;
      font-size: 27px;
      line-height: 49px;
      margin-bottom: 44px;
      display: block;
      text-align: center;
    }
  }
}
.ant-tooltip {
  .ant-tooltip-content {
    .ant-tooltip-arrow {
      .ant-tooltip-arrow-content {
        background-color: #ffff;
        &::before {
          background: #fff;
        }
      }
    }
    .ant-tooltip-inner {
      background-color: #fff;
      color: black;
      padding: 8px 14px;
      font-weight: 700;
      font-size: 22px;
      line-height: 34px;
      text-transform: capitalize;
      color: #000000;
    }
  }
}

@media (max-width: 767px) {
  .application-wrap {
    .budject-slider {
      .ant-slider {
        .ant-slider-mark {
          left: calc(100% - 95%);
          width: 90%;
        }
      }
    }
  }
}
@media (max-width: 576px) {
  .application-wrap {
    .budject-slider {
      width: auto;
      margin: 0 11px 143px 11px;
      .ant-slider {
        height: 37px;
        .ant-slider-mark {
          left: calc(100% - 97%);
          width: 90%;
        }
        .ant-slider-rail {
          height: 37px;
        }
        .ant-slider-track {
          height: 37px;
        }
        .ant-slider-handle {
          top: -8px;
        }
        .ant-slider-mark strong {
          font-weight: 700;
          font-size: 16px;
          line-height: 49px;
        }
        .ant-slider-mark {
          top: 37px;
        }
      }
      .title {
        font-size: 20px;
        line-height: 49px;
        margin-bottom: 20px;
      }
    }
    .budjet {
      .budjet-links {
        margin: 0 17px 82px 17px;
        ul {
          flex-direction: column;
          gap: 27px;
          a {
            justify-content: center;
          }
        }
      }
      .title {
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
        max-width: 276px;
        margin: 0 auto;
        padding-bottom: 35px;
      }
    }
  }
}
