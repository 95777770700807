.reviewPage {
  text-align: left;
  .dashboard-content {
    .welcomeCon {
      .frameIcon {
        left: -40px;
        top: 27px;
      }
      .welcomeContentCon {
        padding: 15px 80px 15px 130px;
        .leftText {
          font-size: 20px;
        }
      }
    }
    .updateCard {
      .creditText {
        font-weight: 500;
        font-size: 17px;
        line-height: 31px;
        text-align: center;
        color: #000000;
        margin-top: 20px;
      }
      .claimCon {
        min-height: 130px;
        justify-content: center;
        .learnMoreButton {
          min-width: 170px;
        }
        .whiteButton {
          border: 2px solid #e5c243 !important;
          background: #fff;
        }
      }
    }
  }
}
@media (max-width: 920px) {
  .reviewPage .dashboard-content .welcomeCon {
    .frameIcon {
      left: calc(50% - 57.5px);
      top: -35px;
    }
    .welcomeContentCon {
      padding: 70px 15px 20px;
    }
  }
}
