.ProjectInfoPage-main-wrapper {
  .mainContainer {
    max-width: 1320px;
    // min-width: 1170px;
    margin: auto;
    // @media (max-width: 1320px) {
    //   min-width: 1170px;
    // }
    @media (max-width: 767px) {
      min-width: 100%;
    }
    @media (max-width: 991px) {
      min-width: 100%;
    }
    @media (max-width: 1200px) {
      min-width: 100%;
    }

    .dashboard-content {
      padding: 15px 60px;
      @media (max-width: 767px) {
        padding: 50px 30px 30px 30px;
      }

      .updateDetailCon {
        .backCon {
          display: flex;
          align-items: center;
          margin-bottom: 30px;

          @media (max-width: 767px) {
            justify-content: center;
          }

          .backIcon {
            cursor: pointer;
          }
          .backText {
            margin-left: 5px;
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 24px;
            text-transform: capitalize;
            color: #000000;
          }
        }

        .detailCardCon {
          background: #ffffff;
          box-shadow: 0px 2px 19px rgba(32, 14, 50, 0.12);
          border-radius: 13px;
          padding: 40px 50px 60px;
          text-align: left;
          margin-bottom: 30px;

          @media (max-width: 767px) {
            padding: 25px;
          }

          .detailHeaderCon {
            display: flex;
            align-items: center;
            margin-bottom: 30px;

            svg {
              margin-right: 25px;
              width: 140px;
              height: auto;
            }

            @media (max-width: 767px) {
              flex-direction: column;
              text-align: center;
              svg {
                width: 90px;
                height: auto;
                margin: 15px 0;
              }
            }

            .detailDateText {
              font-weight: normal !important;
              font-style: normal;
              font-size: 16px;
              line-height: 24px;
              color: #162642;
            }
            .detailHeaderText {
              font-style: normal;
              font-weight: 500;
              font-size: 27px;
              line-height: 41px;
              color: #000000;
              svg {
                width: 20px;
                margin-left: 10px;
                cursor: pointer;
              }
            }
          }

          .projectDetailCon {
            padding: 0 15px;

            .mainProjectDetails {
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;

              .sectionCon {
                max-width: 300px;
                min-width: 220px;
                margin-bottom: 60px;

                @media (max-width: 767px) {
                  margin-bottom: 40px;
                }

                .detailsHeaderCon {
                  position: relative;
                  font-family: Roboto;
                  font-style: normal;
                  font-weight: normal;
                  font-size: 16px;
                  line-height: 49px;
                  color: #000000;
                  display: flex;
                  align-items: center;

                  svg {
                    margin-left: 10px;
                    cursor: pointer;
                  }
                }

                .contentText {
                  font-family: Roboto;
                  font-style: normal;
                  font-weight: 500;
                  font-size: 17px;
                  line-height: 24px;
                  text-transform: capitalize;
                  color: #000000;
                  max-width: 190px;
                  text-align: left;
                  svg {
                    margin-right: 15px !important;
                    @media (max-width: 767px) {
                      margin-right: 15px !important;
                    }
                  }
                }
              }
            }

            .featuresContentCon {
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;

              .sectionCon {
                max-width: 600px;
                min-width: 220px;

                @media (max-width: 767px) {
                  margin-bottom: 40px;
                }

                .detailsHeaderCon {
                  position: relative;
                  font-family: Roboto;
                  font-style: normal;
                  font-weight: normal;
                  font-size: 16px;
                  line-height: 49px;
                  color: #000000;
                  display: flex;
                  align-items: center;

                  svg {
                    margin-left: 10px;
                    cursor: pointer;
                  }
                }
                .fileContainer {
                  .fileContentCon {
                    display: flex;
                    gap: 10px;
                  }
                }

                .contentText {
                  max-width: 500px;
                  font-family: Roboto;
                  font-style: normal;
                  font-weight: 500;
                  font-size: 17px;
                  line-height: 24px;
                  text-transform: capitalize;
                  color: #000000;
                  text-align: left;
                }
              }
            }
          }
        }
      }
    }
  }
}
