.perkPage {
  display: flex;
  padding: 15px 60px;
  @media (max-width: 768px) {
    padding: 0;
  }
  .dashboard-content {
    padding-bottom: 35px;
    flex-direction: column;
    display: flex;
    .welcomeCon {
      background: #ffffff;
      box-shadow: 0px 2px 19px rgba(32, 14, 50, 0.27);
      border-radius: 20px;
      padding: 13px;
      position: relative;
      margin: 40px 0;
      @media (max-width: 767px) {
        margin: 100px 0 0;
      }
      .welcomeContentCon {
        padding: 15px 80px 15px 200px;
        background: #e5c243;
        border-radius: 20px;

        .welcomeText {
          font-style: normal;
          font-weight: 500;
          font-size: 25px;
          line-height: 41px;
          text-transform: capitalize;
          color: #000000;
          margin-bottom: 10px;
        }
        .welcomeSubText {
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 26px;
        }
      }
      .flyIconCon {
        position: absolute;
        top: -40px;
        left: 0;
      }
    }
    .updateColumn {
      .updateCard {
        background: #ffffff;
        box-shadow: 0px 2px 19px rgba(32, 14, 50, 0.12);
        border-radius: 13px;
        padding: 35px 15px;
        .perks-custom-divider {
          margin: 20px 0;
          border: 2px solid #ececec;
        }
        .updateIcon {
          height: 50px;
          width: 100%;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
        .highValueText {
          background: rgba(145, 215, 71, 0.34);
          border-radius: 22px;
          font-weight: normal;
          font-size: 11px;
          line-height: 31px;
          text-align: center;
          color: #529e02;
          // min-width: 86px;
          margin-top: 20px;
          padding: 0 20px;
        }
        .amazonHeadingText {
          font-weight: 500;
          font-size: 17px;
          line-height: 16px;
          text-align: center;
          color: #000000;
          height: 30px;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
        .amazonNormalText {
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          line-height: 22px;
          text-align: center;
          color: #000000;
          margin-top: 15px;
          height: 65px;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
        .amazonBottomCon {
          padding: 0px 25px;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        .learnMoreButton {
          background: #e5c243;
          border-radius: 22px;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          text-align: center;
          color: #000000;
          margin-top: 20px;
          border: 0;
          &:hover {
            border: 0;
          }
        }
      }
    }
  }
}
.perkDetailPage {
  .updateDetailCon {
    padding: 0 140px 0;
    @media (min-width: 992px) and (max-width: 1299px) {
      padding: 0 80px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      padding: 0 40px;
    }
    @media (max-width: 767px) {
      padding: 50px 25px;
    }
    .backCon {
      display: flex;
      align-items: center;
      margin-bottom: 30px;
      .backIcon {
        cursor: pointer;
      }
      .backText {
        margin-left: 5px;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 24px;
        text-transform: capitalize;
        color: #000000;
      }
    }
    .detailCardCon {
      background: #ffffff;
      box-shadow: 0px 2px 19px rgba(32, 14, 50, 0.12);
      border-radius: 13px;
      padding: 45px;
      @media (max-width: 767px) {
        padding: 35px 25px;
      }
      .perkDetailHeaderCon {
        // padding: 0 15px;
        gap: 30px;
        img {
          width: 165px;
          height: auto;
        }
        .perkDetailWrapper {
          .detailHeaderText {
            font-weight: 500;
            font-size: 31px;
            line-height: 41px;
            color: #000000;
            margin-bottom: 5px;
            @media (max-width: 767px) {
              font-size: 23px;
            }
          }
          .detailText {
            font-weight: 400;
            font-size: 20px;
            line-height: 34px;
            color: #000000;
            margin-bottom: 23px;
            text-align: left;
            @media (max-width: 767px) {
              font-size: 18px;
            }
          }
          .highValueCon {
            display: flex;
            column-gap: 100px;
            .typePerkCon {
              // margin-right: 100px;
              .highValueText {
                background: rgba(145, 215, 71, 0.34);
                border-radius: 22px;
                font-family: Roboto;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 41px;
                text-align: center;
                color: #529e02;
                min-width: 113px;
              }
            }
            .typeText {
              margin-bottom: 10px;
              color: #000000;
              opacity: 0.53;
              font-family: Roboto;
              font-style: normal;
              font-weight: normal;
              font-size: 17px;
              line-height: 34px;
              text-align: left;
              @media (max-width: 767px) {
                text-align: center;
              }
            }
            .websiteText {
              font-family: Roboto;
              font-style: normal;
              font-weight: normal;
              font-size: 20px;
              line-height: 34px;
              color: #000000;
              text-align: justify;
              @media (max-width: 767px) {
                text-align: center;
              }
            }
          }
        }
      }
      .perk-divider {
        border: 1px solid #e5c243;
        margin: 46px 0;
      }
      .perkDetailContent {
        // padding: 0 15px;
        margin-top: 46px;
        .contentHeading {
          font-weight: 500;
          font-size: 26px;
          line-height: 41px;
          color: #000000;
          margin-bottom: 8px;
          @media (max-width: 767px) {
            font-size: 23px;
          }
        }
        .contentText {
          font-weight: 400;
          font-size: 20px;
          line-height: 34px;
          color: #000000;
          text-align: justify;
          @media (max-width: 767px) {
            font-size: 18px;
          }
        }
      }
    }
  }
}

.marginBottom30 {
  margin-bottom: 30px;
}

@media (max-width: 1020px) {
  // .perkDetailPage .updateDetailCon .detailCardCon .detailHeaderCon {
  //   flex-direction: column;
  //   svg {
  //     margin-bottom: 20px;
  //     margin-right: 0 !important;
  //   }
  //   .detailHeaderText,
  //   .detailDateText {
  //     text-align: center;
  //   }
  //   .detailHeaderText {
  //     margin-bottom: 10px;
  //   }
  // }
  .perkDetailHeaderCon .highValueCon {
    flex-direction: column;
    // align-items: center;
    .typeText {
      text-align: center;
    }
    .typePerkCon {
      margin-right: 0;
      margin-bottom: 35px;
      .typeText {
        text-align: center;
      }
    }
  }
  .perkDetailContent {
    .contentHeading,
    .contentText {
      text-align: center;
    }
  }
}

@media (max-width: 920px) {
  .perkPage .dashboard-content .welcomeCon {
    .frameIcon {
      left: 50%;
      transform: translateX(-50%);
      bottom: inherit;
      top: -60px;
    }
    .welcomeContentCon {
      padding: 110px 15px 20px;
    }
  }
}

@media (max-width: 768px) {
  .updatesPage {
    padding: 15px 30px;
  }
}
@media (max-width: 375px) {
  .perkPage .dashboard-content .welcomeCon {
    .welcomeContentCon {
      padding: 80px 15px 20px;
    }
  }
}
