.setting-main {
  width: 100%;
  @media (max-width: 767px) {
    padding: 40px 20px 30px 20px;
  }
  .backCon {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    margin-left: 100px;
    margin-right: 100px;
    .backIcon {
      cursor: pointer;
    }
    .backText {
      margin-left: 5px;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 24px;
      text-transform: capitalize;
      color: #000000;
    }
    @media (max-width: 1199.98px) {
      margin: 0 50px 50px 50px;
    }
    @media (max-width: 768px) {
      justify-content: center;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      margin: 0 30px 50px 30px;
    }
  }
  .settings-main-wrap {
    margin: 0 100px 50px 100px;
    .ant-tabs-tabpane {
      padding-right: 24px;
    }
    @media (max-width: 1199.98px) {
      margin: 0 50px 50px 50px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      margin: 50px 30px;
    }
    @media (max-width: 768px) {
      margin: 0px;
      .ant-tabs-tabpane {
        padding-right: 0;
      }
    }
    .setting-left-wrap {
      min-height: calc(100vh - 185px);
      height: 100%;
      background: #ffffff;
      box-shadow: 0px 2px 19px rgba(32, 14, 50, 0.12);
      border-radius: 13px;
      .ant-tabs {
        width: 100%;
        @media (max-width: 991px) {
          width: 100%;
        }
        .ant-tabs-nav {
          .ant-tabs-nav-wrap {
            margin-left: 8px;
            @media (max-width: 768px) {
              margin: 0px;
            }
            @media (max-width: 991px) {
              display: -webkit-box;
              justify-content: center;
            }
            .ant-tabs-nav-list {
              // border: 2px solid #e8e8e8;
              min-width: 215px;
              min-height: 500px;
              border-radius: 13px 0 0 13px;
              @media (max-width: 991px) {
                min-height: 0;
              }
              .ant-tabs-tab {
                padding: 26px 31px;
                margin: 0;
                justify-content: center;
                @media (max-width: 991px) {
                  padding: 20px 10px;
                  margin: 0;
                }
                .ant-tabs-tab-btn {
                  font-size: 16px;
                  font-weight: 400;
                  line-height: 19px;
                  display: flex;
                  align-items: center;
                  color: #000000;
                  @media (max-width: 767px) {
                    font-size: 13px;
                    line-height: 15px;
                  }
                }
              }
              .ant-tabs-tab-active {
                background: rgba(229, 194, 67, 0.14);

                .ant-tabs-tab-btn {
                  font-weight: 500;
                }
              }
            }
            .ant-tabs-ink-bar {
              background: #e5c243;
              width: 7px;
            }
          }
        }
      }
      .tab-payment-info {
        .payment-wrapper {
          padding: 150px;
          @media (min-width: 992px) and (max-width: 1199px) {
            padding: 50px;
          }
          @media (min-width: 768px) and (max-width: 991px) {
            padding: 50px 0;
          }
          @media (max-width: 767px) {
            padding: 50px 0;
          }
          .payment-headding {
            .payment-head {
              font-weight: 500;
              font-size: 18px;
              line-height: 24px;
              text-align: center;
              color: #000000;
              margin-bottom: 40px;
              cursor: pointer;
            }
          }
          .payment-cards {
            background: #ffffff;
            border: 3px solid #e5c243;
            box-sizing: border-box;
            padding: 10px 55px;
            border-radius: 56px;
            margin-bottom: 40px;
            cursor: pointer;
            @media (max-width: 767px) {
              padding: 8px 20px;
            }
            .payment-card-text {
              font-weight: 500;
              font-size: 18px;
              line-height: 41px;
              color: #000000;
              margin-left: 15px;
              @media (max-width: 767px) {
                font-size: 15px;
              }
            }
          }
          .payment-bank {
            background: #ffffff;
            border: 3px solid #e5c243;
            box-sizing: border-box;
            padding: 10px 55px;
            border-radius: 56px;
            cursor: pointer;
            @media (max-width: 767px) {
              padding: 8px 20px;
            }
            .payment-bank-text {
              font-weight: 500;
              font-size: 18px;
              line-height: 41px;
              color: #000000;
              margin-left: 15px;
              @media (max-width: 767px) {
                font-size: 15px;
              }
            }
          }
        }
      }

      .payment-info-wrap {
        .ant-tabs-nav {
          margin-top: 45px;
          &::before {
            border-bottom: unset;
          }
          .ant-tabs-nav-wrap {
            .ant-tabs-nav-list {
              border: none;
              width: unset;
              background: #ffffff;
              box-shadow: 0px 2px 19px 0px rgba(32, 14, 50, 0.12);
              border-radius: 51px;
              margin: 15px auto;
              min-height: unset;
              max-width: 471px;
              width: 100%;
              height: 67px;

              @media only screen and (max-width: 767px) {
                max-width: 327px;
                width: 100%;
                height: 46px;
              }
              @media (max-width: 575px) {
                width: calc(100% - 20px);
              }
              .ant-tabs-tab {
                // padding: 10px 39px;
                padding: 0;
                flex: 1;
                @media only screen and (max-width: 767px) {
                  padding: 8px 20px;
                }
                @media only screen and (max-width: 575px) {
                  padding: 0;
                }
                .ant-tabs-tab-btn {
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 41px;
                  color: #000;
                  @media only screen and (max-width: 767px) {
                    line-height: 26px;
                    font-size: 14px;
                  }
                  @media only screen and (max-width: 575px) {
                    line-height: 22px;
                    font-size: 11px;
                  }
                }
              }
              .ant-tabs-tab-active {
                border: 3px solid #e5c243;
                border-radius: 36px;
                background: transparent;
              }
              .ant-tabs-ink-bar {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
