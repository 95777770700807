@import "../../../App.scss";
.onboard {
  height: 100vh;
  overflow: auto;

  .header {
    padding: 34px 44px 0 44px;
  }

  .signin-form {
    background-color: #fff;
    padding: 2rem;
    border-radius: 1.5rem;
    width: 420px;
    .ant-form {
      &.ant-form-vertical {
        .ant-form-item {
          .ant-form-item-label {
            padding-bottom: 11px;
            label {
              font-weight: 400;
              font-size: 15px;
              line-height: 18px;
              color: #000000;
            }
          }
          .ant-form-item-control-input {
            border: 1px solid #d4d4d4;
            border-radius: 5px;
            input {
              padding: 11px 50px 11px 11px;
              font-weight: 400;
              font-size: 15px;
              line-height: 18px;
              color: #000000;
            }
          }
        }
      }
    }
    iframe {
      margin: 0 auto !important;
    }
    .remember-forgot-wrap {
      flex-wrap: wrap;
      gap: 10px;
      padding-top: 12px;
      span {
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        color: #000000;
      }
      a {
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
      }
    }
    .signin-google-button {
      width: 100%;
      justify-content: center;
      box-shadow: none !important;
      border: 1px solid #d4d4d4 !important;
    }
    .terms {
      text-align: center;
      color: $color-grey;

      &--links {
        color: $color-blue;
      }
    }
    .login-link {
      color: $color-yellow;
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      text-decoration: underline;
    }
    .ant-divider-horizontal {
      &.ant-divider-with-text {
        margin: 18px 0;
        &::before,
        &::after {
          border-top: 1px solid #707070;
        }
      }
      .ant-divider-inner-text {
        padding: 0 10px;
        font-size: 12px;
        line-height: 14px;
        color: #333333;
      }
    }
    .ant-form-item {
      margin-bottom: 31px;
      .ant-input-password {
        .ant-input-suffix {
          .ant-input-feedback-icon {
            display: flex;
          }
        }
      }
    }
    .btn-primary {
      background-color: $color-yellow;
      border-color: $color-yellow;
      color: $color-black;
      font-weight: 700;
      padding: 11px;
      height: auto;
      .btn-info-wrap {
        display: grid;
        grid-template-columns: 20px 1fr;
        min-width: 80px;
        margin: 0 auto;
        width: fit-content;
        margin-left: calc(50% - 50px);
        span {
          font-weight: 700;
          font-size: 17px;
          line-height: 20px;
          text-align: center;
          color: #000000;
        }
      }
    }
    .forgot-link {
      a {
        color: $color-yellow;
      }
    }

    .ant-checkbox-checked::after {
      border: 1px solid $color-yellow;
    }
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: $color-yellow;
      border-color: $color-yellow;
    }
  }
}
