@import "../../App.scss";

.wizard-btns {
  .next-btn {
    background: $color-yellow;
    border: 4px solid $color-yellow;
    padding: 14px 24px;
    border-radius: 0px 100px 100px 0px;
    color: #000;

    img {
      margin-left: 15px;
    }
  }
}
