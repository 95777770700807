.add-bank-account-wrapper {
  padding: 100px 50px;
  text-align: left;

  @media (min-width: 992px) and (max-width: 1199px) {
    padding: 60px 40px;
  }
  @media (max-width: 991px) {
    padding: 62px 38px;
  }
  .bank-account-box {
    .hadeing_title {
      margin-bottom: 50px;
      @media only screen and (max-width: 992px) {
        margin-bottom: 45px;
      }
      .edit-bank-title {
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        color: #000;
        margin-bottom: 11px;
      }
      .edit-bank-desc {
        font-weight: normal;
        font-size: 12px;
        line-height: 17px;
        color: #000;
        max-width: 380px;
        margin: 0;
      }
    }
  }
  .bank-input-box {
    .input-text {
      .input-label {
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        display: block;
        margin-bottom: 10px;
      }
      .StripeElement {
        height: 46px;
        align-items: center;
        justify-content: flex-start;
        display: flex;
        border: 2px solid #d4d4d4;
        border-radius: 38px;
        padding: 4px 11px;
        .__PrivateStripeElement {
          width: 100%;
        }
      }
      .ant-input {
        background: #ffffff;
        border: 2px solid #d4d4d4;
        border-radius: 38px;
        height: 46px;
        outline: none;
        @media only screen and (max-width: 575px) {
          width: 100%;
        }
      }
      .btn-toggle-wrapper {
        @media (max-width: 767px) {
          width: 100%;
        }
        .custom-toggle-button {
          padding-left: 0;
          height: 40px;
          border: 2px solid #000;
          box-sizing: border-box;
          border-radius: 20px !important;
          .btn-default {
            padding: 0 20px;
            margin-bottom: 0;
            font-weight: normal;
            font-size: 15px;
            line-height: 20px;
            align-items: center;
            display: flex;
            @media (max-width: 320px) {
              font-size: 14px;
            }
          }
          .active {
            background: #000;
            color: #fff;
            border-radius: 20px;
            border: 1px solid transparent;
            padding: 0 20px;
            font-weight: 500;
            align-items: center;
            display: flex;
            margin: -1px -1px;
          }
        }
      }
    }
  }
  .check_box {
    .con_input {
      display: block;
      position: relative;
      padding-left: 35px;
      margin-bottom: 12px;
      cursor: pointer;
      font-size: 18px;
    }

    .con_input input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 21px;
      width: 21px;
      border: 1px solid #000;
      border-radius: 3px;
    }

    .con_input input:checked ~ .checkmark {
      background-color: #000;
      border-radius: 3px;
    }

    .checkmark::after {
      content: "";
      position: absolute;
      display: none;
    }

    .con_input input:checked ~ .checkmark:after {
      display: block;
    }

    .con_input .checkmark::after {
      left: 6px;
      top: 2px;
      width: 7px;
      height: 13px;
      border: 1px solid white;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg);
    }
    .checkbox-text {
      margin-bottom: 0;

      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      color: #000;
    }
  }
  .btn_banking {
    background: #e5c243;
    border-radius: 38px;
    padding: 4px 38px;
    border: none;
    min-width: 142px;
    font-weight: 500;
    font-size: 19px;
    line-height: 36px;
    display: block;
    color: #000;
  }
}
