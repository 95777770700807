@import '../../../App.scss';

.features {
  text-align: center;
  .title {
    font-size: 20px;
    color: #fff;
    font-weight: 700;
    padding-bottom: 2rem;
    display: block;
    span {
      color: $color-yellow;
    }
  }

  .features-links {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      flex-flow: row wrap;
      li {
        list-style: none;
        margin: 4px;
        // flex: 0 1 calc(25% - 4px);

        a {
          background: #fff;
          border-radius: 100px;
          padding: 10px 22px;
          display: flex;
          align-items: center;
          flex-direction: row;
          border: 4px solid $color-black;
          transition: 0.5s ease;
          img {
            width: 20px;
          }
          &:hover {
            border: 4px solid $color-yellow;
          }
          &.project-active {
            border: 4px solid $color-yellow;
          }
          span {
            font-size: 16px;
            padding-left: 14px;
            color: $color-black;
          }
        }
      }
    }
  }
}

.project-form {
  .ant-form-item-label > label {
    color: #fff;
  }
  .ant-input {
    border-radius: 100px;
  }

  textarea.ant-input {
    border-radius: 20px;
  }
}
