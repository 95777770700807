.testimonialCarousel {
  .carousel-control-next-icon {
    background-image: url(../../Assets/images/home/arrow-right.svg);
  }
  .carousel-control-prev-icon {
    background-image: url(../../Assets/images/home/arrow-left.svg);
  }
  .carousel-indicators {
    bottom: 73px;
    left: 50%;
    transform: translateX(-50%);
    right: unset;
    margin: 0;
    button {
      width: 14px;
      height: 14px;
      margin-left: 11px;
      margin-right: 11px;
    }
  }
  .carousel-inner {
    height: 100%;
  }
}
