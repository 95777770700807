.header-logo {
  @media (max-width: 767px) {
    justify-content: center;
  }
}
.App {
  .onboard {
    .header {
      @media (max-width: 576px) {
        padding: 28px 15px 0 15px;

        .logo {
          height: fit-content;
          img {
            width: auto;
            height: 36px;
          }
        }
        .logout-btn {
          button {
            padding: 6px 15px;
          }
        }
      }
    }
  }
}
