.support-chat-main {
  width: 100%;
  padding: 35px 65px;
  @media (max-width: 767px) {
    padding: 25px 20px;
  }
  .support-setting-wrapper {
    @media (max-width: 767px) {
      row-gap: 25px;
    }
    .support-chat-wrapper {
      .support-tex {
        font-weight: 500;
        font-size: 23px;
        line-height: 24px;
        color: #000000;
        margin-left: 14px;
      }
    }
    .support-setting-button {
      background: #e5c243;
      border-radius: 53px;
      padding: 12px 25px;
      border: unset;
      span {
        font-size: 25px;
      }
      .button-text {
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        text-transform: capitalize;
        color: #000000;
        margin-left: 11px;
        font-weight: 500;
      }
    }
  }
  .support-chat-card {
    background: #ffffff;
    box-shadow: 0px 2px 19px rgba(197, 197, 197, 0.47);
    border-radius: 13px;
    .support-card-header {
      background: #000000;
      border-radius: 13px 13px 0px 0px;
      margin-top: 27px;
      .support-header-main {
        padding: 16px 20px;
        img {
          width: 53px;
          height: 53px;
          border-radius: 100%;
        }
        .support-user-wrapper {
          margin-left: 16px;
          .support-user-name {
            font-weight: bold;
            font-size: 21px;
            line-height: 41px;
            text-transform: capitalize;
            color: #ffffff;
          }
          .support-user-chat {
            font-weight: normal;
            font-size: 11px;
            line-height: 13px;
            color: #ffffff;
          }
        }
      }
    }
    .support-card-body {
      padding: 0 58px 42px 58px;
      @media (max-width: 767px) {
        padding: 20px 20px;
      }
      .support-card-wrapper {
        height: 500px;
        overflow-x: hidden;
        overflow-y: auto;
        &::-webkit-scrollbar {
          width: 0px;
          background: transparent;
        }
        @media (max-width: 991px) {
          height: 400px;
        }
        .support-chat-date {
          .chat-date {
            font-weight: normal;
            font-size: 12px;
            line-height: 150%;
            text-align: center;
            color: rgba(0, 0, 0, 0.7);
          }
        }
        .user-img {
          border: 1px solid #000000;
          border-radius: 50%;
        }
        .support-admin-chat {
          width: 100%;
          margin: 10px 0;
          .support-divider {
            font-weight: 400;
            font-size: 12px;
            line-height: 150%;
            text-align: center;
            color: rgba(0, 0, 0, 0.7);
            margin: 19px 0;
            &::after,
            ::before {
              border-top-color: #e8e6ea;
            }
            .ant-divider-inner-text {
              min-width: 200px;
            }
          }
          .admin-chat-wrapper {
            background: rgba(0, 0, 0, 0.07);
            border-radius: 15px 15px 15px 0px;
            padding: 20px 25px;
            max-width: calc(50% - 31.5px);
            @media (min-width: 768px) and (max-width: 991px) {
              max-width: 85%;
            }
            @media (max-width: 767px) {
              max-width: 93%;
            }
            .admin-chat-message {
              margin-left: 20px;
              .admin-name {
                font-weight: bold;
                font-size: 14px;
                line-height: 150%;
                color: #000000;
                margin-bottom: 3px;
              }
              .admin-meassage {
                font-weight: normal;
                font-size: 14px;
                line-height: 150%;
                color: #000000;
                word-break: break-all;
              }
              .icon-group {
                display: flex;
                align-items: center;
                gap: 5px;
                color: #000;
                img {
                  width: 30px;
                  height: 30px;
                }
              }
            }
          }
          .admin-chat-date {
            font-weight: normal;
            font-size: 12px;
            line-height: 150%;
            color: rgba(0, 0, 0, 0.4);
          }
        }
        .support-user-chat {
          width: 100%;
          margin: 10px 0;
          .support-divider {
            font-weight: 400;
            font-size: 12px;
            line-height: 150%;
            text-align: center;
            color: rgba(0, 0, 0, 0.7);
            margin: 19px 0;
            &::after,
            ::before {
              border-top-color: #e8e6ea;
            }
            .ant-divider-inner-text {
              min-width: 200px;
            }
          }
          .user-chat-wrapper {
            background: #000;
            border-radius: 15px 15px 0 15px;
            padding: 20px 25px;
            max-width: calc(50% - 31.5px);
            @media (min-width: 768px) and (max-width: 991px) {
              width: 85%;
            }
            @media (max-width: 767px) {
              width: 93%;
            }
            .user-chat-message {
              .user-meassage {
                font-weight: normal;
                font-size: 14px;
                line-height: 150%;
                color: #fff;
                word-break: break-all;
              }
              .attachment-img {
                max-width: 150px;
                width: 100%;
              }
              .icon-group {
                display: flex;
                align-items: center;
                gap: 5px;
                color: #fff;
                img {
                  width: 30px;
                  height: 30px;
                }
              }
            }
          }
          .user-chat-date {
            display: flex;
            gap: 5px;
            font-weight: normal;
            font-size: 12px;
            line-height: 150%;
            color: rgba(0, 0, 0, 0.4);
          }
        }
        .support-features-chat {
          width: 100%;
          margin: 10px 0;
          .features-chat-wrapper {
            background: #e5c243;
            border-radius: 15px 15px 0px 15px;
            // padding: 29px 32px;
            width: 100%;
            .features-settings {
              background: #000000;
              border-radius: 14px 0px 69px;
              padding: 15px 32px;
              width: 288px;
              @media (max-width: 320px) {
                width: 90%;
              }
              .features-settings-icon {
                color: #fff;
                margin-right: 6px;
                font-size: 22px;
              }
              .features-settings-text {
                font-weight: 500;
                font-size: 18px;
                line-height: 24px;
                text-transform: capitalize;
                color: #ffffff;
              }
            }
            .features-chat-message {
              padding: 15px 32px 30px;
              .features-name {
                font-weight: 500;
                font-size: 18px;
                line-height: 41px;
                color: #000000;
                margin-bottom: 3px;
              }
              .features-meassage {
                font-weight: normal;
                font-size: 14px;
                line-height: 153%;
                color: #000000;
                word-break: break-all;
              }
            }
          }
          .features-chat-date {
            display: flex;
            gap: 5px;
            font-weight: normal;
            font-size: 12px;
            line-height: 150%;
            color: rgba(0, 0, 0, 0.4);
            margin-top: 5px;
          }
        }
      }

      .support-card-meassage {
        margin-top: 42px;
        .emoijibox-wrap {
          right: -70px;
          aside.EmojiPickerReact.epr-main {
            width: 100% !important;
          }
          @media (max-width: 576px) {
            right: -80px;
          }
        }
        .support-meassage-wrapper {
          column-gap: 15px;
          .measssage-input {
            position: relative;
            width: 100%;
            .input {
              width: 100%;
              border: 2px solid #000000;
              box-sizing: border-box;
              border-radius: 15px;
              padding: 10px 50px 10px 15px;
            }
            .meassage-icon {
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              right: 20px;
            }
          }
          .chat-icons {
            background: #000000;
            border-radius: 15px;
            padding: 12px;
            .icons {
              color: #fff;
              font-size: 20px;
            }
            .close-icon {
              height: 30px;
              width: 30px;
              background: #e6c350;
              border-radius: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              position: absolute;
              right: -10px;
              z-index: 9;
              top: -15px;
              font-size: 15px;
              border: 2px solid #000;
            }
          }
          .chat-files {
            background: #000000;
            border-radius: 15px;
            padding: 12px;
            .files {
              color: #fff;
              font-size: 20px;
            }
          }
        }
      }
    }
  }
}
