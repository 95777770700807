@import "../../App.scss";

.header-main-wrapper {
  .navLink:hover,
  .navLinkActive {
    border-bottom: 2px solid #e4c144;
  }
  .navbar {
    padding: 1.5rem 40px;
    background: #000000;
    .navbar-brand {
      height: 54px;
    }
    @media (max-width: 992px) {
      padding: 24px 32px;
    }
    @media (max-width: 576px) {
      padding: 24px 3px;
    }
    .signup-btn {
      background: #e5c243;
      color: #000;
      border-radius: 50px;
      padding: 5px 30px;
      white-space: nowrap;
      transition: 0.3s;
      display: inline-block;
      margin: 0 1rem;
      @media (max-width: 768px) {
        display: block;
        margin-top: 1rem;
      }
    }
    .navbar-nav {
      display: contents;
      .nav-link {
        color: #ffffff;
        font-size: large;
        margin: 0 0.5rem;
        @media (max-width: 768px) {
          padding: 1rem 0;
          border-bottom: dashed 1px rgba(255, 255, 255, 0.5);
          &:last-child {
            border-top: dashed 1px rgba(255, 255, 255, 0.5);
            margin-top: 1rem;
          }
        }
      }
    }

    .navbar-collapse {
      justify-content: end;
      @media (min-width: 1024px) and (max-width: 1920px) {
        display: flex;
        flex-basis: auto;
        flex-direction: row;
        justify-content: end;
      }
    }

    .navbar-toggler {
      color: transparent;
      border-color: transparent;
    }
    .navbar-toggler-icon {
      background: url(../../Assets/images/home/menu-lines.svg) no-repeat center
        center scroll;
    }
  }
}
