.invoice-details-page {
  background: #ffffff;
  box-shadow: 0px 2px 19px rgba(32, 14, 50, 0.12);
  border-radius: 13px;
  width: 835px;
  margin: 0 auto 50px;
  @media (min-width: 768px) and (max-width: 991px) {
    width: 90%;
  }
  @media (max-width: 767px) {
    width: 90%;
    margin: 50px auto;
  }
  .invoice-details-page-header {
    background: #000000;
    border-radius: 13px 13px 0px 0px;
    padding: 16px 25px;
    column-gap: 100px;
    @media (max-width: 767px) {
      column-gap: 25px;
      padding: 31px 25px 31px 30px;
    }
    .invoice-steps {
      position: relative;
      // &:nth-of-type() {
      //   .step {
      //     &::before {
      //       content: "";
      //       background: #fff;
      //     }
      //   }
      // }
      &:last-child {
        .stap {
          &::before {
            content: none;
          }
        }
      }
      .stap {
        background: transparent;
        width: 43px;
        height: 43px;
        left: 580px;
        border-radius: 50%;
        font-weight: bold;
        font-size: 23px;
        line-height: 41px;
        text-align: center;
        text-transform: capitalize;
        color: #fff;
        border: 3px solid #fff;
        &::before {
          content: "";
          position: absolute;
          top: 26%;
          left: 50%;
          transform: translate(50%, -50%);
          bottom: 0;
          right: 0;
          width: 90px;
          height: 2px;
          background-color: #fff;
          @media (max-width: 767px) {
            width: 32px;
            left: 70%;
            top: 29%;
          }
          @media (max-width: 320px) {
            width: 16px;
            left: 90%;
            top: 25%;
          }
        }
      }
      .stap.active {
        background-color: #e5c243;
        color: #000;
        border: 3px solid transparent;
        &::before {
          background-color: #e5c243;
        }
      }
      .invoice-steps-text {
        font-weight: bold;
        font-size: 13px;
        line-height: 41px;
        text-align: center;
        text-transform: capitalize;
        color: #ffffff;
        @media (max-width: 767px) {
          font-weight: 700;
          font-size: 13px;
          line-height: 13px;
          margin-top: 15px;
        }
      }
    }
    .paid-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      .invoice-id {
        font-weight: 700;
        font-size: 22px;
        line-height: 24px;
        text-transform: capitalize;
        color: #ffffff;
      }
      .right-header {
        display: flex;
        align-items: center;
        .paid-icon {
          color: #53a100;
          font-size: 32px;
          margin-right: 6px;
        }
        .paid-text {
          font-weight: 500;
          font-size: 18px;
          line-height: 24px;
          color: #53a100;
          margin-right: 20px;
        }
        .download-wrap {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 36px;
          width: 36px;
          border-radius: 50%;
          background: #e5c243;
          cursor: pointer;
          .anticon {
            font-size: 22px;
            font-weight: 900;
          }
        }
      }
    }
  }
  .invoice-details-page-body {
    // height: calc(100vh - 320px);
    // display: flex;
    // flex-direction: column;
    // justify-content: space-evenly;
    .invoice-details-page-button {
      flex-direction: column;
      margin: 20px 0 30px;
      .invoice-next-button {
        background: #e5c243;
        border-radius: 53px;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        text-transform: capitalize;
        color: #000000;
        max-width: 197px;
        height: 54px;
        width: 100%;
        border: none;
        gap: 10px;
        span {
          margin-left: 5px;
        }
      }
      .invoice-back-button {
        background-color: transparent;
        font-weight: 500;
        font-size: 20px;
        border-radius: 53px;
        line-height: 24px;
        text-align: center;
        text-transform: capitalize;
        color: #000000;
        max-width: 197px;
        height: 54px;
        width: 100%;
        border: none;
        gap: 10px;
        span {
          margin-right: 5px;
        }
      }
    }
  }
}

.invoice-paid-wrap {
  margin-top: 160px;
  .check-circle-paid-icon {
    font-size: 100px;
    color: #000;
    margin-bottom: 12px;
  }
  .paid-text {
    font-size: 26px;
    line-height: 41px;
    text-align: center;
    color: #000000;
    font-weight: 500;
  }
}
