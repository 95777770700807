.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
// variables
$color-yellow: #e5c243;
$color-grey: #b5b5b5;
$color-blue: #1d6ac5;
$color-black: #000;
$color-white: #ffffff;

// mediaQueries
// mediaQueries
$breakpoint-ipad: 820px;
$breakpoint-tablet: 768px;
$breakpoint-mobile: 639px;

body {
  font-family: "Roboto", sans-serif;
  // background: $color-black;
}
a {
  color: $color-yellow;
  &:hover {
    color: $color-yellow;
  }
}

.text-yellow {
  color: $color-yellow;
}

.btn-primary {
  background-color: $color-yellow;
  border-color: $color-yellow;
  color: $color-black;
  font-weight: 700;
  &:hover {
    background-color: rgba($color-yellow, 0.8);
  }
}
.bodyVh90 {
  height: calc(100vh - 78px);
  @media (max-width: 767px) {
    padding: 0 13px;
  }
}

i.ant-spin-dot-item {
  background: yellow;
}
.ant-message {
  .ant-message-notice {
    .ant-message-notice-content {
      .ant-message-custom-content {
        &.ant-message-success,
        &.ant-message-error,
        &.ant-message-info {
          display: flex;
          align-items: center;
        }
      }
    }
  }
}
.ant-form-item-feedback-icon-error {
  display: flex;
}
.ant-form-item-feedback-icon-success {
  display: flex;
}
.ant-message {
  z-index: 1030;
}
.ant-form-item-control-input {
  .ant-form-item-control-input-content {
    .ant-input-affix-wrapper {
      .ant-input-suffix {
        .ant-form-item-feedback-icon {
          &.ant-form-item-feedback-icon-error {
            background-color: #fff;
            height: 14px;
            width: 14px;
            span {
              height: 100%;
              width: 100%;
              background-image: url(./Assets/images/validate-icon.svg);
              background-size: cover;
              background-position: center;
            }
            svg {
              display: none;
            }
          }
        }
      }
    }
  }
}
.ant-input-affix-wrapper {
  padding: 0;
  .ant-input:not(textarea) {
    padding: 4px 50px 4px 11px;
  }
  .ant-input-suffix {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
}
.custom-textarea {
  &.error-wrapper {
    .ant-form-item-control-input-content {
      border: 1px solid red;
    }
  }
  .ant-form-item-control-input-content {
    padding: 15px;
    background-color: #fff;
    border-radius: 20px;
    border: 1px solid #fff;
    textarea.ant-input {
      border: 0;
      border-color: transparent;
      border-radius: 0;
      box-shadow: none;
      &.ant-input-status-error:not(.ant-input-disabled):not(
          .ant-input-borderless
        ).ant-input:focus {
        box-shadow: none;
      }
    }
  }
}
