.no-schedule-meeting-main {
  font-family: "Roboto";
  font-style: normal;
  min-height: calc(100vh - 165px);
  width: 100%;
  margin: 0 134px;
  .no-schedule-header {
    padding: 5px 0;
    .back-task {
      width: fit-content;
      display: flex;
      align-items: flex-start;
      align-items: center;
      cursor: pointer;
      gap: 11px;
      img {
        width: 23px;
        height: 16px;
      }
      .back-text {
        font-weight: 500;
        font-size: 24px;
        line-height: 24px;
        text-transform: capitalize;
      }
    }
  }
  .no-schedule-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    h3 {
      font-weight: 500;
      font-size: 20px;
      line-height: 41px;
      text-align: center;
      color: #000000;
      margin-bottom: 5px;
    }
    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 26px;
      text-align: center;
      margin: 0;
    }
  }
}
@media screen and (max-width: 768px) {
  .no-schedule-meeting-main {
    margin-top: 37px;
    margin: 37px 16px 0 16px;
    .no-schedule-header {
      .back-task {
        .back-text {
          font-size: 22px;
        }
      }
    }
    .no-schedule-content {
      img {
        max-width: 100%;
        height: auto;
      }
      h3 {
        line-height: 23px;
        margin: 0;
        max-width: 305px;
        margin: 0 auto;
      }
      p {
        max-width: 290px;
        margin: 0 auto;
      }
    }
  }
}
