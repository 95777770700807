.editBudgets-main-wrapper {
  font-family: "Roboto";
  width: 100%;

  .mainContainer {
    max-width: 1320px;
    // min-width: 1170px;
    margin: auto;
    // @media (max-width: 1320px) {
    //   min-width: 1170px;
    // }
    @media (max-width: 767px) {
      min-width: 100%;
    }
    @media (max-width: 991px) {
      min-width: 100%;
    }
    @media (max-width: 1200px) {
      min-width: 100%;
    }

    .dashboard-content {
      padding: 15px 60px;
      @media (max-width: 767px) {
        padding: 50px 30px 30px 30px;
      }

      .updateDetailCon {
        .backCon {
          display: flex;
          align-items: center;
          margin-bottom: 30px;
          .backIcon {
            cursor: pointer;
          }
          .backText {
            margin-left: 5px;
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 24px;
            text-transform: capitalize;
            color: #000000;
          }
        }

        .detailCardCon {
          background: #ffffff;
          box-shadow: 0px 2px 19px rgba(32, 14, 50, 0.12);
          border-radius: 13px;
          padding: 40px 50px 60px;
          text-align: left;
          @media (max-width: 767px) {
            box-shadow: none !important;
            padding: 0 !important;
            display: flex;
            flex-direction: column;
            align-items: center;
            height: 70vh;
          }

          .maxConatiner {
            max-width: 750px;
            margin: auto;

            .editHeading {
              font-family: Roboto;
              font-style: normal;
              font-weight: 500;
              font-size: 27px;
              line-height: 49px;
              text-align: center;
              text-transform: capitalize;
              color: #000000;
              margin-bottom: 50px;
              @media (max-width: 767px) {
                font-size: 20px;
                line-height: 28px;
                text-align: center;
                margin-bottom: 30px;
              }
            }

            .buttonContainer {
              display: flex;
              align-items: center;
              justify-content: center;
              .ant-space {
                gap: 20px !important;
              }
              button {
                font-family: Roboto;
                font-style: normal;
                font-weight: 500;
                font-size: 20px;
                line-height: 24px;
                text-align: center;
                text-transform: capitalize;
                color: #000000;
                min-width: 125px;
                min-height: 50px;
              }
              .sendButton {
                background: #e5c243;
                border-radius: 53px;
              }
              .cancelButton {
                border: 2px solid #e5c243;
                box-sizing: border-box;
                border-radius: 53px;
              }
            }

            .budgetSliderContainer {
              margin-bottom: 180px;
              margin-top: 100px;
              @media (max-width: 767px) {
                argin-bottom: 230px;
                margin-top: 150px;
              }
              .ant-slider {
                width: 100%;
                .ant-slider-track {
                  background-color: #e5c243;
                  height: 25px;
                  border-radius: 22px;
                }
                .ant-slider-rail {
                  height: 25px;
                  background: #000000;
                  border-radius: 25px;
                }
                .ant-slider-handle.ant-tooltip-open {
                  border-color: #1890ff;
                  border: 8px solid #ffffff;
                }
                .ant-slider-handle {
                  width: 50px;
                  height: 50px;
                  background: #e5c243;
                  margin-top: -13px;
                }
                .ant-slider-step {
                  display: none;
                }
                .ant-tooltip-arrow {
                  box-shadow: -3px -3px 7px rgba(0, 0, 0, 1);
                }
                .ant-tooltip-inner {
                  background-color: rgba(0, 0, 0, 1) !important;
                  padding: 8px 20px !important;
                  border-radius: 8px !important;
                }
              }
            }
          }
        }
      }
    }
  }
}
