.dashboard-page {
  font-family: "Roboto";
  padding: 15px 60px;
  @media (max-width: 768px) {
    padding: 75px 30px;
  }
  .mainContainer {
    max-width: 1320px;
    // min-width: 1170px;
    margin: auto;
  }
  .dashboard-content {
    .welcomeCon {
      background: #ffffff;
      box-shadow: 0px 2px 19px rgba(32, 14, 50, 0.27);
      border-radius: 20px;
      padding: 13px;
      position: relative;
      text-align: left;
      .welcomeContentCon {
        padding: 15px 80px 15px 180px;
        background: #e5c243;
        border-radius: 20px;
        .welcomeText {
          font-style: normal;
          font-weight: 500;
          font-size: 25px;
          line-height: 41px;
          text-transform: capitalize;
          color: #000000;
          margin-bottom: 10px;
        }
        .welcomeSubText {
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 26px;
        }
      }
      .flyIconCon {
        position: absolute;
        top: -40px;
        left: 0;
      }
    }
    .bottomContentCon {
      margin-top: 30px !important;
      max-width: 800px;
      margin: auto;
      .ant-progress-bg {
        background-color: #35e75c !important;
      }
      .projectOnboardingCon {
        display: flex;
        justify-content: space-between;
        .onboardingText {
          font-style: normal;
          font-weight: bold;
          font-size: 19px;
          line-height: 41px;
          text-transform: capitalize;
          color: #000000;
        }
        .percentText {
          font-style: normal;
          font-weight: normal;
          font-size: 17px;
          line-height: 41px;
          text-transform: capitalize;
          color: #000000;
        }
      }
      .dashboardlistCon {
        margin-top: 40px;
        .dashboardlistItem {
          background: #ffffff;
          box-shadow: 0px 0px 19px rgba(32, 14, 50, 0.12);
          border-radius: 72px;
          padding: 10px 20px;
          display: flex;
          align-items: center;
          margin-bottom: 25px;
          position: relative;
          padding-right: 150px;
          .completeNow {
            background: #e5c243;
            border-radius: 34px;
            font-style: normal;
            font-weight: 500;
            text-align: center;
            text-transform: capitalize;
            color: #000000;
            position: absolute;
            right: 20px;
          }
          .disabledCircle {
            width: 40px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #000000;
            opacity: 0.09;
            border-radius: 50%;
            margin-right: 20px;
            svg {
              width: 20px;
            }
          }
          .enableOpacity {
            opacity: 1;
          }
          .itemContentText {
            font-style: normal;
            font-weight: 500;
            font-size: 19px;
            line-height: 41px;
            color: #000000;
          }
          .completedText {
            font-style: normal;
            font-weight: 500;
            font-size: 17px;
            line-height: 41px;
            text-align: center;
            text-transform: capitalize;
            color: #53a100;
            position: absolute;
            right: 40px;
          }
        }
        .disabledListItem {
          opacity: 0.4;
          pointer-events: none;
        }
      }
    }
  }
}
@media (max-width: 920px) {
  .dashboard-page .dashboard-content .welcomeCon .welcomeContentCon {
    padding: 50px;
  }
  .dashboard-page .dashboard-content .welcomeCon .flyIconCon {
    top: -80px;
    svg {
      width: 115px;
    }
  }
}
@media (max-width: 768px) {
  .dashboard-page
    .dashboard-content
    .bottomContentCon
    .dashboardlistCon
    .dashboardlistItem {
    padding-right: 0;
    flex-direction: column;
    border-radius: 36px;
    padding: 20px;
    .completedText,
    .completeNow {
      position: relative;
      right: inherit;
    }
    .completeNow {
      margin-top: 3px;
    }
    .disabledCircle {
      margin-right: 0;
    }
    .itemContentText,
    .completedText {
      line-height: 30px;
      text-align: center;
    }
  }
}
