@import "../../../App.scss";

.logout-btn {
  .ant-btn {
    background: #000;
    border: 4px solid $color-yellow;
    color: $color-yellow;
    height: auto;
    padding: 6px 24px;
    font-size: medium;
    font-weight: 600;

    &:hover {
      color: #fff;
    }
  }
}

.step-form {
  &--hanger {
    position: relative;
    &::before {
      content: "";
      border-bottom: 2px solid #fff;
      width: 100%;
      display: block;
    }
    ul {
      display: grid;
      grid-template-columns: repeat(8, auto);
      padding: 0;
      li {
        a {
          &.bulb-even {
            position: relative;
            background: url(../../../../public/img/bulb-even.svg);
            width: 40px;
            height: 90px;
            display: inline-block;
            color: #000;
          }

          &.bulb-even-active {
            background: url(../../../../public/img/bulb-even-active.svg);
            width: 40px;
            height: 90px;
            color: #000;
          }

          &.bulb-odd {
            position: relative;
            background: url(../../../../public/img/bulb-odd.svg);
            width: 40px;
            height: 111px;
            display: inline-block;
            color: #000;
          }

          &.bulb-odd-active {
            background: url(../../../../public/img/bulb-odd-active.svg);
            width: 40px;
            height: 111px;
            color: #000;
          }
          span {
            position: absolute;
            font-size: 20px;
            font-weight: 700;
            bottom: 10px;
            left: 14px;
            color: #fff;
          }
        }
      }
    }
  }
}
.project-type-wrap {
  max-width: 733px;
  width: 100%;
  .project-type {
    text-align: center;
    .title {
      font-size: 20px;
      color: #fff;
      font-weight: 700;
      padding-bottom: 2rem;
      display: block;
      span {
        color: $color-yellow;
      }
    }

    .project-type-links {
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        li {
          list-style: none;
          margin: 4px;
          flex: 0 1 calc(20% - 8px);

          a {
            img {
              height: 24px;
            }
            background: #fff;
            border-radius: 100px;
            padding: 10px 22px;
            display: flex;
            align-items: center;
            flex-direction: row;
            border: 4px solid $color-white;
            transition: 0.5s ease;
            &:hover {
              border: 4px solid $color-yellow;
            }
            &.project-active {
              border: 4px solid $color-yellow;
            }
            span {
              font-size: 16px;
              padding-left: 14px;
              color: $color-black;
            }
          }
        }
      }
      @media (max-width: 767px) {
        ul {
          justify-content: flex-start;
          li {
            flex-basis: calc(50% - 8px);
            a {
              justify-content: center;
            }
          }
        }
      }
      @media (max-width: 576px) {
        ul {
          justify-content: center;
          li {
            flex-basis: 100%;
            margin: 10px 0;
            a {
              span {
                padding-left: 5px;
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 576px) {
    padding: 0 50px;
  }
}

.project-form {
  .ant-form-item-label > label {
    color: #fff;
  }
  .ant-input {
    border-radius: 100px;
    &:focus {
      box-shadow: none;
    }
  }
  textarea.ant-input {
    border-radius: 20px;
    -webkit-appearance: searchfield;
  }
}

.wizard-btns {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  grid-gap: 2rem;
  justify-content: center;
  a {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: auto auto;
    align-items: center;
    img {
      width: 29px;
      height: 15px;
    }
    font-size: 16px;

    &.prev-btn {
      background: $color-black;
      border: 4px solid $color-yellow;
      padding: 14px 24px;
      border-radius: 100px 0px 0px 100px;
    }
    &.next-btn {
      background: $color-yellow;
      border: 4px solid $color-yellow;
      padding: 14px 24px;
      border-radius: 0px 100px 100px 0px;
      color: #000;
    }
  }
}
