.bank-account-details-wrap {
  padding: 30px 40px 50px;
  text-align: left;
  // max-width: 650px;
  width: 100%;
  margin: 0 auto;
  @media (max-width: 767px) {
    padding: 30px 15px 50px;
  }
  .bank-card-wrapper {
    margin-bottom: 30px;
    @media (max-width: 767px) {
      margin-bottom: 50px;
    }
    .bank-card-button-wrap {
      border: 0;
      background: transparent;
      display: flex;
      align-items: center;
      column-gap: 5px;
      font-weight: 400;
      font-size: 18px;
      line-height: 18px;
      text-transform: capitalize;
      color: #000000;
    }
    .bank-card-button {
      background: #e5c243;
      border: 3px solid #e5c243;
      box-sizing: border-box;
      border-radius: 56px;
      padding: 8px 20px;
      margin-left: auto;
      .bank-card-img {
        width: 22px;
      }
      .bank-card-text {
        font-weight: normal;
        font-size: 15px;
        line-height: 15px;
        color: #000000;
        margin-left: 10px;
      }
    }
  }
  .bank-detail-headding {
    max-width: 320px;
    margin-bottom: 50px;
    .headding-bank {
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: #000000;
      .bank-details-edit-icon {
        width: 23px;
        height: 23px;
        border: 0.5px solid #000000;
        border-radius: 50%;
        padding: 3px;
        margin-left: 5px;
        .edit-icon {
          font-size: 14px;
        }
      }
    }
    .headding-text {
      font-weight: normal;
      font-size: 12px;
      line-height: 17px;
      color: #000000;
    }
  }
  .bank-desc-wrapper {
    .bank-desc {
      .bank-desc-lable {
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        color: #000000;
        margin-bottom: 12px;
      }
      .bank-desc-text {
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        color: #000000;
        display: flex;
        align-items: center;
        gap: 10px;
        svg {
          cursor: pointer;
        }
        .btn-toggle-wrapper {
          display: flex;
          gap: 10px;
          flex-wrap: wrap;
          .custom-toggle-button {
            padding-left: 0;
            border: 1px solid #000;
            height: 25px;
            box-sizing: border-box;
            border-radius: 20px !important;
            .btn-default {
              padding: 0 10px;
              margin-bottom: 0;
              font-weight: normal;
              font-size: 15px;
              line-height: 20px;
              align-items: center;
              display: flex;
              @media (max-width: 320px) {
                font-size: 14px;
              }
            }
            .active {
              background: #000;
              color: #fff;
              border-radius: 20px;
              border: 1px solid transparent;
              padding: 0 20px;
              font-weight: 500;
              align-items: center;
              display: flex;
              margin: -1px -1px;
            }
          }
          .save-btn {
            background: #e5c243;
            border: 3px solid #e5c243;
            box-sizing: border-box;
            border-radius: 56px;
            padding: 0px 10px;
            height: 25px;

            font-size: 15px;
            line-height: 20px;
          }
        }
      }
    }
  }
}
