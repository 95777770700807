.email-notification-wrapper {
  .email-notification-main {
    padding: 100px 220px;
    @media (min-width: 992px) and (max-width: 1199px) {
      padding: 80px 80px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      padding: 80px 50px;
    }
    @media (max-width: 767px) {
      padding: 50px 25px;
    }
    .email-notification-headding {
      font-weight: 500;
      font-size: 17px;
      line-height: 24px;
      color: #000000;
      margin-bottom: 40px;
      text-align: left;
    }
    .email-notification {
      border-bottom: 1px solid rgba(0, 0, 0, 0.13);
      padding-bottom: 24px;
      margin-bottom: 30px;
      &:last-child {
        border-bottom: unset;
        padding-bottom: unset;
        margin-bottom: unset;
      }
      .email-notification-name {
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
      }
      .cm-switch.ant-switch {
        background-color: #edeef1;
        .ant-switch-handle {
          &::before {
            background-color: rgba(22, 38, 66, 0.19);
          }
        }
      }
      .cm-switch.ant-switch-checked {
        background: #edeef1;
        .ant-switch-handle {
          &::before {
            background-color: #000;
          }
        }
      }
    }
  }
}
