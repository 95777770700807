.profile-main-wrapper {
  padding: 0 50px;
  margin: 3px 60px 0 55px;
  width: 100%;
  height: calc(100vh - 160px);

  @media (max-width: 767px) {
    margin: 0px;
  }
  .profile-head-wrapper {
    margin-bottom: 36px;
    @media (max-width: 767px) {
      justify-content: center;
      margin-bottom: 2rem !important;
    }
    .profile-head {
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      text-transform: capitalize;
      margin-left: 8px;
    }
  }
  .profile-wrapper {
    background: #ffffff;
    box-shadow: 0px 2px 19px rgba(32, 14, 50, 0.12);
    border-radius: 13px;
    height: calc(100% - 80px);
    .profile-img-wrapper {
      position: relative;
      height: 121px;
      width: 121px;
      border-radius: 100%;
      background-color: #e5c243;
      border: 6px solid #fff;
      box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.26);
      img {
        overflow: hidden;
        border-radius: 100%;
        width: 100%;
        height: auto;
      }
      .profile-camera-img {
        position: absolute;
        bottom: 0;
        right: 0;
        background: #ffffff;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        width: 31px;
        height: 31px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .profilePicLoader {
        position: absolute;
        top: 50%;
        width: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .profile-name {
      font-weight: bold;
      font-size: 24px;
      line-height: 41px;
      text-transform: capitalize;
      color: #000000;
      margin: 13px 0 0 0;
    }
    .profile-info-wrap {
      display: flex;
      width: 100%;
      align-items: center;
      flex-direction: column;
      .profile-email-wrapper {
        margin-top: 40px;
        max-width: 300px;
        width: 100%;
        .email-circle {
          background: #e5c243;
          min-width: 49px;
          min-height: 49px;
          border-radius: 50%;
          margin-right: 14px;
          font-size: 24px;
        }
        .email-wrapper {
          .email-lable {
            font-weight: normal;
            font-size: 13px;
            line-height: 26px;
            text-transform: capitalize;
            color: #000000;
            text-align: left;
          }
          .email-text {
            word-break: break-word;
            font-weight: 500;
            font-size: 19px;
            line-height: 26px;
            color: #000000;
            text-align: left;
          }
        }
      }
      .profile-time-wrapper {
        margin-top: 30px;
        max-width: 300px;
        width: 100%;
        .time-circle {
          background: #e5c243;
          min-width: 49px;
          min-height: 49px;
          border-radius: 50%;
          margin-right: 14px;
          font-size: 24px;
        }
        .time-wrapper {
          .time-zone-selector {
            font-family: "Roboto";
            font-style: normal;
            .ant-select-selector {
              border: 1px solid #000000;
              border-radius: 45px;
              font-weight: 500;
              font-size: 17px;
              line-height: 26px;
              text-transform: capitalize;
              color: #000000;
              padding: 9px 22px;
              min-height: 44px;
              display: flex;
              align-items: center;
              justify-content: center;
              .ant-select-selection-item {
                color: #000000;
                font-size: 17px;
                font-weight: 500;
                line-height: 26px;
              }
            }
          }
          .ant-select-arrow {
            background-image: url("../../Assets/images/Profile/up-arrow.svg");
            background-repeat: no-repeat;
            background-position: center;
            right: 20px;
          }
          .time-lable {
            font-weight: normal;
            font-size: 13px;
            line-height: 26px;
            text-transform: capitalize;
            color: #000000;
            width: 230px;
            text-align: left;
          }
          .time-text {
            background-color: #e5c243;
            .ant-select-selector {
              border: unset;
              width: 236px;
              box-shadow: unset;

              @media (max-width: 320px) {
                width: 200px;
              }

              .ant-select-selection-search {
                right: 0;
                left: 0;
                .ant-select-selection-search-input {
                  border: 1px solid #000000;
                  box-sizing: border-box;
                  border-radius: 45px;
                  padding: 9px 20px;
                  &:focus {
                    box-shadow: unset;
                  }
                }
              }
              .ant-select-selection-placeholder {
                color: #000;
                padding: 0 18px 0 10px;
              }
              .ant-select-selection-item {
                padding: 0 18px 0 10px;
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 992px) {
  .profile-main-wrapper {
    margin: 3px 0px 0 0px;
  }
}
@media (max-width: 767px) {
  .profile-main-wrapper {
    padding: 0 20px;
    margin: 56px 0 0 0;
  }
}
@media (max-width: 576px) {
  .profile-main-wrapper {
    padding: 0 20px;
    margin: 56px 0 0 0;
    .profile-wrapper {
      .profile-info-wrap {
        .profile-email-wrapper,
        .profile-time-wrapper {
          max-width: 259px;
          .time-wrapper {
            min-width: 192px;
            width: 100%;
          }
        }
      }
    }
  }
}
