.updates-page-main-wrap {
  padding: 12px 54px 18px;
  width: 100%;
  @media (max-width: 767px) {
    padding: 24px 24px 18px;
  }
  .header-main {
    display: flex;
    align-items: center;
    margin-bottom: 48px;
    @media (max-width: 767px) {
      margin-bottom: 30px;
    }
    img {
      margin-right: 12px;
      cursor: pointer;
    }
    .header-text {
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      text-transform: capitalize;
      color: #000000;
    }
  }
  .updateCard {
    background: #ffffff;
    box-shadow: 0px 2px 19px rgba(32, 14, 50, 0.12);
    border-radius: 13px;
    padding: 30px 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    .noteUpdatesText {
      font-weight: normal;
      font-size: 13px;
      line-height: 22px;
      text-align: justify;
      color: #000000;
    }
    .headingCard {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 41px;
      text-transform: capitalize;
      color: #000000;
      margin-top: 10px;
    }
    .dateCardText {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #162642;
      margin-bottom: 20px;
    }
  }
  .updateDetailCon {
    .backCon {
      display: flex;
      align-items: center;
      margin-bottom: 30px;
      .backIcon {
        cursor: pointer;
      }
      .backText {
        margin-left: 5px;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 24px;
        text-transform: capitalize;
        color: #000000;
      }
    }
    .detailCardCon {
      background: #ffffff;
      box-shadow: 0px 2px 19px rgba(32, 14, 50, 0.12);
      border-radius: 13px;
      padding: 40px 50px 60px;
      text-align: left;

      .detailHeaderCon {
        display: flex;
        align-items: center;
        margin-bottom: 30px;
        svg {
          margin-right: 15px;
          width: 56px;
          height: 56px;
        }
        .detailHeaderText {
          font-style: normal;
          font-weight: 500;
          font-size: 27px;
          line-height: 41px;
          color: #000000;
        }
        .detailDateText {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #162642;
        }
      }
      .detailContentText {
        .contentText {
          font-style: normal;
          font-weight: normal;
          font-size: 20px;
          line-height: 34px;
          color: #000000;
          margin-bottom: 30px;
        }
      }
    }
  }
  .paginationContainer {
    display: flex;
    justify-content: center;
    margin: 50px 0;
    .ant-pagination {
      .ant-pagination-item-link,
      .ant-pagination-item {
        background: #ffffff;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
        border-radius: 28px;
        svg {
          margin-bottom: 7px;
        }
      }
      .ant-pagination-item-active {
        background: #e5c243;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
        border-radius: 28px;
        border: none;
      }
    }
  }
}
