.add-bank-main-wrap {
  width: 100%;
  .add-bank-wrap {
    padding: 0 50px;
    text-align: left;
    .bank-account-box {
      max-width: 409px;
      margin: 0 auto;
      p {
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
      }
      .hadeing_title {
        margin-bottom: 11px;
        .edit-bank-title {
          font-weight: 500;
          font-size: 18px;
          line-height: 24px;
          color: #000;
          margin-bottom: 11px;
        }
        .edit-bank-desc {
          font-weight: normal;
          font-size: 12px;
          line-height: 17px;
          color: #000;
          max-width: 380px;
          margin: 0;
        }
      }
      .bank-account-details {
        margin-top: 38px;
        padding: 0;
      }
    }
    .bank-input-box {
      .input-text {
        .input-label {
          font-weight: 500;
          font-size: 16px;
          line-height: 22px;
          display: block;
          margin-bottom: 10px;
        }
        .ant-input {
          background: #ffffff;
          border: 2px solid #d4d4d4;
          border-radius: 38px;
          height: 46px;
          outline: none;
          @media only screen and (max-width: 575px) {
            width: 100%;
          }
        }
        .btn-toggle-wrapper {
          @media (max-width: 767px) {
            width: 100%;
          }
          .custom-toggle-button {
            padding-left: 0;
            height: 40px;
            border: 2px solid #000;
            box-sizing: border-box;
            border-radius: 20px !important;
            width: 100%;
            .btn-default {
              padding: 0 20px;
              margin-bottom: 0;
              font-weight: normal;
              font-size: 15px;
              line-height: 20px;
              align-items: center;
              display: flex;
              flex: 1;
              justify-content: center;
              @media (max-width: 320px) {
                font-size: 14px;
              }
            }
            .active {
              background: #000;
              color: #fff;
              border-radius: 20px;
              border: 1px solid transparent;
              padding: 0 20px;
              font-weight: 500;
              align-items: center;
              display: flex;
              flex: 1;
            }
          }
        }
        .StripeElement {
          height: 46px;
          align-items: center;
          justify-content: flex-start;
          display: flex;
          border: 2px solid #d4d4d4;
          border-radius: 38px;
          padding: 4px 11px;
          .__PrivateStripeElement {
            width: 100%;
          }
        }
      }
    }
    .check_box {
      .con_input {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 18px;
      }

      .con_input input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }

      .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 21px;
        width: 21px;
        border: 1px solid #000;
        border-radius: 3px;
      }

      .con_input input:checked ~ .checkmark {
        background-color: #000;
        border-radius: 3px;
      }

      .checkmark::after {
        content: "";
        position: absolute;
        display: none;
      }

      .con_input input:checked ~ .checkmark:after {
        display: block;
      }

      .con_input .checkmark::after {
        left: 6px;
        top: 2px;
        width: 7px;
        height: 13px;
        border: 1px solid white;
        border-width: 0 3px 3px 0;
        transform: rotate(45deg);
      }
      .checkbox-text {
        margin-bottom: 0;

        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color: #000;
      }
    }
    .btn_banking {
      background: #e5c243;
      border-radius: 38px;
      padding: 4px 38px;
      border: none;
      min-width: 142px;
      font-weight: 500;
      font-size: 19px;
      line-height: 36px;
      display: block;
      color: #000;
    }
    @media (max-width: 768px) {
      padding: 25px 5px;
      .bank-account-box {
        max-width: 100%;
        .bank-account-details {
          margin-top: 35px;
        }
      }
      .btn_banking {
        margin: 0 auto;
      }
    }
  }
}
