.blog-details-main {
  background-color: black;
  .blog-detail-main-layout {
    display: flex;
    flex-direction: column;
    margin: 102px 102px 123px 102px;
    padding: 0 66px;
    .blog-detail-body {
      width: 100%;
      margin: 40px auto;
      padding: 37px 100px;
      background-color: #ffffff;
      box-shadow: 0 2px 19px rgba(32, 14, 50, 0.12);
      border-radius: 50px;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        width: calc(100% - 100px);
        height: 7px;
        left: 50px;
        transform: translateY(-50%);
        bottom: -7px;
        background: #e5c243;
        border-radius: 100px;
      }
      .reading-material-detail-header {
        margin-top: 30px;
        margin: 0 auto;
        .header-text {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 70px;
          .back-button {
            gap: 10px;
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            text-transform: capitalize;
            color: #000000;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            width: fit-content;
          }
        }
      }
      .mark-btn {
        background: #e5c243;
        font-weight: 500;
        font-size: 17px;
        line-height: 24px;
        color: #000000;
        border: 1px solid transparent;
        border-radius: 25px;
        margin-right: 16px;
        &:hover {
          background: transparent;
          border: 1px solid #e5c243;
          color: #e5c243;
        }
      }
      .blog-detail-container {
        margin: 0 auto;
        .blog-content-wrapper {
          display: flex;
          flex-direction: column;
          margin-top: 16px;
          align-items: flex-start;
          h5 {
            color: #e5c243;
            font-weight: 400;
            font-size: 29px;
            line-height: 22px;
            margin-bottom: 28px;
            width: 100%;
            text-align: left;
          }
          .body-header-text {
            font-weight: 500;
            font-size: 28px;
            line-height: 44px;
            text-transform: capitalize;
            color: #000000;
            position: relative;
            word-break: break-word;
            text-align: start;
            &:after {
              content: "";
              width: 193px;
              height: 1px;
              position: absolute;
              background: #000;
              bottom: -28px;
              left: 0;
            }
          }
          .btn-active {
            background: rgba(107, 209, 0, 0.34);
            border-radius: 34px;
            font-weight: 500;
            font-size: 17px;
            line-height: 27px;
            text-align: center;
            text-transform: capitalize;
            color: #53a200;
            border: none;
            max-width: 90px;
            margin-top: 20px;
          }
        }
        .blog-section {
          margin: 28px 0 44px 0;
          h5 {
            font-weight: 400;
            font-size: 29px;
            line-height: 25px;
            text-transform: capitalize;
            color: #000000;
            margin-bottom: 30px;
            text-align: left;
          }
          .profile-info {
            display: flex;

            justify-content: space-between;
            align-items: center;
            .user-detail {
              display: flex;
              align-items: center;
              .user-icon {
                margin-right: 18px;
                .ant-avatar {
                  height: 64px;
                  width: 64px;
                  overflow: hidden;
                  border-radius: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  span {
                    font-size: 30px;
                  }
                  .ant-avatar-string {
                    text-transform: capitalize;
                  }
                }
              }
              .user-info {
                h6 {
                  font-weight: 500;
                  font-size: 22px;
                  line-height: 27px;
                  text-transform: capitalize;
                  color: #000000;
                  margin: 0;
                  text-align: left;
                }
                p {
                  font-size: 21px;
                  line-height: 27px;
                  color: #000;
                  margin-bottom: 0;
                }
              }
            }
            .btn-group {
              display: flex;
              gap: 15px;
            }
          }
        }
        .blog-detail-context {
          p {
            font-weight: 500;
            font-size: 20px;
            line-height: 40px;
            text-align: justify;
            text-transform: capitalize;
            color: #000000;
            margin: 74px 0 64px 0;
            word-break: break-word;
          }
        }
      }
    }
  }
}
@media (max-width: 1199px) {
  .blog-details-main {
    .blog-detail-main-layout {
      margin: 102px 0 123px 0;
      .blog-detail-body {
        padding: 37px 52px;
      }
    }
  }
}
@media (max-width: 992px) {
  .blog-details-main {
    .blog-detail-main-layout {
      padding: 0px 35px;
      .blog-detail-body {
        margin: 49px auto;
        .reading-material-detail-header {
          .header-text {
            margin-bottom: 40px;
          }
        }
        .blog-detail-container {
          .blog-content-wrapper {
            text-align: center;
            align-items: center;
            .body-header-text {
              text-align: center;
              font-size: 19px;
              line-height: 25px;
              margin-bottom: 15px;
              &:after {
                left: 50%;
                transform: translate(-50%);
                bottom: -15px;
                width: 116px;
              }
            }
            h5 {
              font-size: 17px;
              line-height: 22px;
              margin-bottom: 15px;
              text-align: center;
            }
          }
          .blog-section {
            font-size: 16px;
            line-height: 25px;
            margin: 15px 0 41px 0;
            h5 {
              text-align: center;
              font-size: 16px;
              line-height: 25px;
              margin-bottom: 16px;
            }
            .profile-info {
              flex-direction: column;
              gap: 28px;
              .user-detail {
                .user-icon {
                  .ant-avatar {
                    height: 52px;
                    width: 52px;
                  }
                }
                .user-info {
                  h6 {
                    font-size: 17px;
                    line-height: 27px;
                  }
                  p {
                    font-size: 15px;
                    line-height: 27px;
                  }
                }
              }
            }
          }
          .blog-detail-context {
            p {
              font-weight: 500;
              font-size: 18px;
              line-height: 32px;
              text-align: justify;
              text-transform: capitalize;
              color: #5f697a;
              margin: 35px 0;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 576px) {
  .blog-details-main {
    .blog-detail-main-layout {
      padding: 0 15px;
      margin: 102px 0 0 0;
      .blog-detail-body {
        padding: 15px 15px;
        border-radius: 15px;
        &::after {
          width: calc(100% - 40px);
          left: 20px;
        }
      }
    }
  }
}
