.Faq {
  .faqList {
    margin-top: 100px;
    .listItemFaq {
      background: #ffffff;
      border-radius: 50px;
      margin-bottom: 38px;
      @media (max-width: 992px) {
        margin-bottom: 48px;
      }
      .innerWrapper {
        padding: 60px 42px 60px 74px;
        text-align: left;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        row-gap: 18px;
        @media (max-width: 992px) {
          row-gap: 24px;
          padding: 51px 27px 42px;
        }
        &::after {
          content: "";
          position: absolute;
          width: 8px;
          height: calc(100% - 70px);
          left: -2px;
          top: 50%;
          background: #e5c243;
          border-radius: 100px 0 0 100px;
          transform: translateY(-50%);
          @media (max-width: 992px) {
            width: 4px;
            height: calc(100% - 65px);
            left: 0;
          }
        }
        .title {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 700;
          font-size: 31px;
          line-height: 30px;
          color: #000000;
          @media (max-width: 1400px) {
            font-size: 27px;
            line-height: 12px;
          }
          @media (max-width: 992px) {
            width: 100%;
            font-size: 20px;
            line-height: 30px;
            text-align: center;
          }
        }
        .subText {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 400;
          font-size: 25px;
          line-height: 42px;
          color: rgba(0, 0, 0, 0.8);
          @media (max-width: 1400px) {
            font-size: 22px;
            line-height: 35px;
          }
          @media (max-width: 992px) {
            width: 100%;
            font-size: 20px;
            line-height: 30px;
            text-align: center;
          }
        }
      }
    }
    @media (max-width: 992px) {
      margin-top: 72px;
    }
  }
  .banner {
    @media (max-width: 992px) {
      padding-bottom: 90px;
    }
    .banner-grid {
      margin-top: 105px;
      padding-top: 107px;
      @media (max-width: 992px) {
        padding: 0 37px;
      }
      .banner-set {
        display: flex;
        padding: 0;
        margin: 0 0 28px 0;
        @media (max-width: 992px) {
          flex-direction: column;
          row-gap: 47px;
          margin: 0;
        }
        &--right {
          flex: 1;
          @media (max-width: 992px) {
            order: 1;
          }
        }
        &--left {
          display: flex;
          flex-direction: column;
          row-gap: 19px;
          flex: 1;
          @media (max-width: 992px) {
            order: 2;
            row-gap: 33px;
          }
          @media (max-width: 768px) {
            max-width: 100%;
          }
          .custom_h1 {
            margin-bottom: 0;
            font-weight: 900;
            font-size: 41px;
            line-height: 55px;
            color: #fff;
            @media (max-width: 1400px) {
              font-size: 35px;
            }
            @media (max-width: 992px) {
              font-size: 39px;
              text-align: center;
            }
            @media (max-width: 768px) {
              font-size: 35px;
              line-height: 50px;
            }
            span.custom_h1_yellow {
              color: #e5c243;
            }
          }
          .custom_para {
            margin-bottom: 0;
            font-weight: 400;
            font-size: 24px;
            line-height: 41px;
            color: #ffffff;
            @media (max-width: 1400px) {
              font-size: 24px;
            }
            @media (max-width: 992px) {
              font-size: 25px;
              text-align: center;
            }
          }
        }
      }
    }
  }
}

// @media (max-width: 768px) {
//   .Faq {
//     .faqList {
//       .listItemFaq {
//         .title,
//         .subText {
//           // font-size: 20px;
//           // line-height: 30px;
//         }
//         .innerWrapper {
//           text-align: center;
//           padding: 40px 28px;
//         }
//       }
//     }
//   }
// }
@media (min-width: 767px) {
  .Faq {
    .banner .banner-grid .banner-set--right {
      justify-content: flex-end;
    }
  }
}
