.support-home-main {
  max-width: 438px;
  margin: 0 auto;
  min-height: calc(100vh - 160px);
  display: flex;
  align-items: center;
  justify-content: center;
  .support-home-client-name {
    font-weight: bold;
    font-size: 27px;
    line-height: 41px;
    text-align: center;
    text-transform: capitalize;
    color: #000000;
    margin: 30px 0 6px 0;
  }
  .support-home-client-posiction {
    font-weight: 500;
    font-size: 20px;
    line-height: 41px;
    text-align: center;
    color: #000000;
    margin-bottom: 12px;
  }
  .support-home-text {
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    color: #000000;
    margin-bottom: 11px;
  }
  .support-home-button {
    background: #e5c243;
    border-radius: 34px;
    font-weight: 500;
    font-size: 26px;
    line-height: 27px;
    text-align: center;
    text-transform: capitalize;
    color: #000000;
    height: 59px;
    padding: 15px 44px;
    border: unset;
    max-width: 209px;
    width: 100%;
    margin-bottom: 63px;
  }
}
@media (max-width: 768px) {
  .support-home-main {
    max-width: 307px;
    min-height: unset;
    margin-top: 88px;
    img {
      width: 80%;
      margin: 0 auto;
    }
    .support-home-client-name {
      margin: 52px 0 20px 0;
    }
    .support-home-client-posiction {
      line-height: 33px;
    }
    .support-home-text {
      margin-bottom: 88px;
    }
  }
}
