.agreement-details-page {
  width: 100%;
  .card-wrapper {
    margin: 36px 0;
    .sub-header {
      width: 835px;
      a {
        .back-icon {
          color: #000000;
          font-weight: bold;
          margin-right: 11px;
          font-size: 24px;
        }
        .back-text {
          font-weight: 500;
          font-size: 24px;
          line-height: 24px;
          color: #000000;
        }
      }
    }
    .card {
      width: 835px;
      background: #ffffff;
      box-shadow: 0px 2px 19px rgba(32, 14, 50, 0.12);
      border-radius: 13px;
      margin-top: 30px;
      .card-header {
        background: #000000;
        border-radius: 13px 13px 0px 0px;
        height: 88px;
        padding: 24px;
        h5 {
          font-size: 22px;
          color: #ffffff;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          width: 80%;
          text-align: left;
        }
        .download-icon {
          width: 40px;
          height: 40px;
          background: #e5c243;
          border-radius: 50%;
          cursor: pointer;
          .anticon {
            color: #000000;
            font-weight: bold;
            font-size: 20px;
          }
        }
      }
      .card-body {
        height: 648px;
        padding: 60px;
        @media (max-width: 767px) {
          height: 400px;
          padding: 30px 16px !important;
        }
        .pdf-wrapper {
          width: 100%;
          height: 100%;
          .agreement-details {
            &::-webkit-scrollbar {
              width: 5px;
              height: 8px;
              background-color: #efefef;
            }
            &::-webkit-scrollbar-thumb {
              background: #16264238;
            }
          }
        }
      }
      .card-footer {
        padding: 15px;
        .card-agreement {
          .ant-checkbox-inner {
            width: 21px;
            height: 21px;
          }
          .ant-checkbox-wrapper {
            .ant-checkbox-input:focus + .ant-checkbox-inner,
            .ant-checkbox-wrapper:hover .ant-checkbox-inner,
            .ant-checkbox:hover .ant-checkbox-inner {
              border-color: #e5c243;
              width: 21px;
              height: 21px;
            }
            .ant-checkbox-checked {
              &::after {
                border: 1px solid transparent;
              }
              .ant-checkbox-input:focus + .ant-checkbox-inner,
              .ant-checkbox-wrapper:hover .ant-checkbox-inner,
              .ant-checkbox:hover .ant-checkbox-inner {
                border-color: #e5c243;
              }
              .ant-checkbox-inner {
                background-color: #e5c243;
                border-color: #e5c243;
                width: 21px;
                height: 21px;
              }
            }
          }
          .agree-text {
            font-size: 16px;
            line-height: 24px;
            color: #000000;
            margin-left: 12px;
          }
        }
        .btn {
          width: 115px;
          height: 38px;
          background: #e5c243;
          border-radius: 53px;
          margin-top: 12px;
          font-weight: 500;
          font-size: 17px;
          line-height: 24px;
          color: #000000;
        }
        .after-agreement {
          margin: 17px;
          .anticon {
            font-size: 22px;
            color: #53a100;
            margin-right: 7px;
          }
          span {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #162642;
            margin-left: 8px;
          }
          & > span {
            margin-top: 14px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .agreement-details-page {
    .card-wrapper {
      .sub-header {
        width: 100%;
      }
      .card {
        width: 100%;
        .card-body {
          padding: 44px 30px 55px;
        }
      }
    }
  }
}
