@import "../../../App.scss";

.wantStart {
  text-align: center;
  .title {
    font-size: 20px;
    color: #fff;
    font-weight: 700;
    padding-bottom: 2rem;
    display: block;
    span {
      color: $color-yellow;
    }
  }

  .wantStart-links {
    margin-bottom: 3rem;
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
      grid-gap: 1rem;
      li {
        list-style: none;
        margin: 4px;
        a {
          background: #fff;
          border-radius: 100px;
          padding: 10px 22px;
          display: flex;
          align-items: center;
          flex-direction: row;
          border: 4px solid $color-black;
          transition: 0.5s ease;
          @media (max-width: 768px) {
            gap: 10px;
            padding: 26px 55px;
            flex-direction: column;
          }
          img {
            width: 25px;
            @media (max-width: 768px) {
              width: 65px;
            }
          }
          &:hover {
            border: 4px solid $color-yellow;
          }
          &.project-active {
            border: 4px solid $color-yellow;
          }
          span {
            font-size: 16px;
            padding-left: 14px;
            color: $color-black;
            @media (max-width: 768px) {
              font-weight: 700;
              font-size: 20px;
              line-height: 34px;
            }
          }
        }
      }
    }
  }
}
