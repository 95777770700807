@import "../../../App.scss";
.onboard {
  height: 100vh;

  .header {
    padding: 34px 44px 0 44px;
  }

  .newPassword-form {
    background-color: #fff;
    padding: 2rem;
    border-radius: 1.5rem;
    width: 420px;
    text-align: left;

    .form-header {
      .sub-text {
        color: #7d8185;
      }
    }

    .ant-form-item {
      margin-bottom: 15px;
    }
    .btn-primary {
      background-color: $color-yellow;
      border-color: $color-yellow;
      color: $color-black;
      font-weight: 700;
    }
  }
}
