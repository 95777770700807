.change-password-wrapper {
  padding: 100px 150px;
  @media (min-width: 992px) and (max-width: 1199px) {
    padding: 80px 80px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    padding: 80px 50px;
  }
  @media (max-width: 767px) {
    padding: 50px 25px;
  }
  .change-password-main {
    width: 60%;
    text-align: left;
    @media (max-width: 767px) {
      width: 90%;
    }
    .change-password-headding {
      font-weight: 500;
      font-size: 17px;
      line-height: 24px;
      color: #000000;
      margin-bottom: 27px;
    }
    .change-password-formgroup {
      margin-bottom: 24px;
      .formgroup-wrapper {
        position: relative;
        .formgroup-input {
          background: #ffffff;
          opacity: 1;
          border: 1px solid #000000;
          box-sizing: border-box;
          border-radius: 38px;
          padding: 8px 18px;
          font-weight: normal;
          font-size: 15px;
          line-height: 24px;
          color: #000000;
          &::placeholder {
            color: rgba(0, 0, 0, 0.5);
          }
        }
        .change-password-icon {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 18px;
          color: #989898;
          cursor: pointer;
          font-size: 20px;
        }
      }
    }
    .change-password-button {
      background: #e5c243;
      border-radius: 38px;
      font-weight: 500;
      font-size: 17px;
      line-height: 2px;
      text-align: center;
      padding: 23px 25px;
      border: unset;
      width: 100%;
      margin-top: 25px;
      border: 0;
      &:active,
      &:hover,
      &:focus {
        color: rgba(0, 0, 0, 0.85);
        border: 0;
      }
    }
  }
}
