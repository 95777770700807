.agreements-page-main-wrap {
  width: 100%;
  padding: 0 12px;
  .sub-header {
    margin-top: 40px;
    .heading-wrapper {
      .agreement-icon {
        width: 27px;
        height: 27px;
        margin-right: 11px;
      }
      h4 {
        font-size: 20px;
        color: #000000;
      }
    }
    .all-complete-tabs {
      height: 40px;
      background: #ffffff;
      box-shadow: 0px 0px 13px rgba(32, 14, 50, 0.18);
      border-radius: 34px;
      .tabs {
        width: 100%;
        height: 100%;
        font-weight: 500;
        font-size: 15px;
        line-height: 41px;
        color: #000000;
        padding: 18px;
        cursor: pointer;
        &.active {
          background: #e5c243;
          cursor: auto;
        }
        &:nth-of-type(1) {
          &.active {
            border-radius: 34px 0 0 34px;
          }
        }
        &:nth-of-type(2) {
          border-left: 1px solid #e1e1e1;
          border-right: 1px solid #e1e1e1;
        }
        &:nth-of-type(3) {
          &.active {
            border-radius: 0 34px 34px 0;
          }
        }
      }
    }
  }
  .cards-group {
    margin: 58px auto;
    .card {
      height: 100%;
      background: #ffffff;
      box-shadow: 0px 2px 19px rgba(32, 14, 50, 0.12);
      border-radius: 13px;
      .card-header {
        background: #000000;
        border-radius: 13px 13px 0px 0px;
        height: 50px;
        padding: 10px 19px;
        h5 {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          width: 80%;
          text-align: left;
          font-weight: 500;
          font-size: 18px;
          color: #ffffff;
        }
        .download-icon {
          width: 30px;
          height: 30px;
          background: #e5c243;
          cursor: pointer;
          .anticon {
            color: #000000;
            font-weight: bold;
            font-size: 20px;
          }
        }
      }
      .card-body {
        padding: 36px 9px;
        .pdf-wrapper {
          width: 100%;
          height: 400px;
          .agreement-details {
            &::-webkit-scrollbar {
              width: 5px;
              height: 5px;
              background-color: #efefef;
            }
            &::-webkit-scrollbar-thumb {
              background: #16264238;
            }
          }
        }
        .horizontal-divider {
          width: 100%;
          height: 2px;
          margin: 5px auto 31px;
          background: #000000;
          opacity: 0.12;
        }
        .issue-date {
          font-weight: 500;
          font-size: 13px;
          line-height: 24px;
          color: #162642;
        }
        .btn {
          width: 178px;
          height: 40px;
          margin-top: 17px;
          background: #e5c243;
          border-radius: 34px;
          font-weight: 500;
          font-size: 15px;
          line-height: 41px;
          color: #000000;
        }
        .completion-date {
          font-weight: 500;
          font-size: 13px;
          line-height: 24px;
          color: #162642;
        }
        .check-icon {
          margin-top: 11px;
          width: 34px;
          height: 34px;
          background: #53a100;
          .anticon {
            color: #ffffff;
            font-weight: bold;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .agreements-page-main-wrap {
    .sub-header {
      margin-top: 40px;
      .all-complete-tabs {
        margin-top: 40px;
      }
    }
    .cards-group {
      .card {
        .card-body {
          .horizontal-divider {
            width: 100%;
          }
        }
      }
    }
  }
}
