.blogs-wrap {
  .banner {
    padding: 0;
    margin-top: 102px;
    .banner-grid {
      padding: 100px 0;
      .banner-set {
        padding: 0;
        margin: 0;
      }
    }
  }
  .tabscon-wrap {
    max-width: 1320px;
    padding: 0 12px;
    margin: 0 auto;
    @media (min-width: 992px) {
      max-width: 960px;
    }
    @media (min-width: 1200px) {
      max-width: 1140px;
    }
    @media (min-width: 1400px) {
      max-width: 1320px;
    }

    .categoryMenuListCon {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 30px 0;
      @media (max-width: 768px) {
        flex-direction: column-reverse;
        row-gap: 20px;
      }
      .leftTabsCon {
        .ant-tabs-top > .ant-tabs-nav::before {
          border-bottom: none;
        }
        .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar {
          height: 6px;
          background: #e5c243;
        }
        .ant-tabs-tab-active {
          .ant-tabs-tab-btn {
            color: #000;
          }
        }
        .ant-tabs-tab:hover {
          color: #000;
        }
        .ant-tabs-tab + .ant-tabs-tab {
          margin: 0 0 0 8px;
        }
        .ant-tabs-tab {
          padding: 12px;
        }
        .ant-tabs-top > .ant-tabs-nav {
          margin-bottom: 5px;
        }
      }
      .rightTabCon {
        text-align: left;
        p {
          font-weight: 700;
          font-size: 19px;
          line-height: 21px;
          color: #000000;
          margin-bottom: 8px;
        }
        @media (max-width: 768px) {
          text-align: center;
        }
        .ant-input-group.ant-input-group-compact {
          display: flex;
          align-items: center;
          input {
            border-top-left-radius: 25px;
            border-bottom-left-radius: 25px;
            border: 1px solid #000000;
            height: 46px;
            font-weight: 400;
            font-size: 17px;
            line-height: 21px;
            color: #000000;
            padding: 4px 14px;
            text-align: left;
          }
          button {
            background-color: #e5c243;
            border-color: #e5c243;
            color: #000;
            border-top-right-radius: 25px;
            height: 44px;
            border: 1px solid #e5c243;
            border-bottom-right-radius: 25px;
            font-weight: 700;
            font-size: 17px;
            line-height: 21px;
            color: #000000;
          }
        }
      }
    }
  }
  .tabsCon {
    background: #fff;
    margin-bottom: 103px;
  }

  .blogsListCon {
    margin: 0;
    background-color: #000;
    padding: 0 30px;
    .featuredcard {
      cursor: pointer;
      padding: 35px 40px;
      border-radius: 50px;
      background: #fff;
      max-width: 1320px;
      margin: auto;
      text-align: left;
      border-bottom: 6px solid #e5c243;
      margin-bottom: 30px;
      .mainFlexCard {
        display: flex;
        width: 100%;
        .imageCon {
          width: calc(50% - 40px);
          margin-right: 40px;
          border-radius: 0px 0px 0px 50px;

          img {
            width: 100%;
            // height: 100%;
            height: 220px;
          }
        }
        .rightFeatureCon {
          width: 50%;
          .avatarCon {
            display: flex;
            align-items: center;
            .ant-avatar {
              margin-right: 15px;
            }
            .nameText {
              font-family: "Roboto";
              font-style: normal;
              font-weight: 500;
              font-size: 17px;
              line-height: 27px;
              text-transform: capitalize;
              color: #000000;
            }
            .dateText {
              font-family: "Roboto";
              font-style: normal;
              font-weight: 400;
              font-size: 15px;
              line-height: 27px;
              text-transform: capitalize;
              color: #000000;
            }
          }
          .trendingText {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 500;
            font-size: 22px;
            line-height: 22px;
            text-transform: capitalize;
            color: #e5c243;
            margin-bottom: 5px;
          }
          .title {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 500;
            font-size: 26px;
            line-height: 38px;
            text-transform: capitalize;
            color: #000000;
          }
          .ant-divider {
            min-width: 100px;
            width: 100px;
            border-top: 1px solid #000;
            margin: 12px 0;
          }
          .subtitle {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 500;
            font-size: 22px;
            line-height: 25px;
            text-transform: capitalize;
            color: #000000;
            word-break: break-word;
          }
          .summary {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 400;
            font-size: 17px;
            line-height: 25px;
            text-transform: capitalize;
            color: rgba(0, 0, 0, 0.8);
            margin-top: 10px;
            margin-bottom: 10px;
            word-break: break-word;
          }
        }
      }
      .categoryText {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 500;
        font-size: 26px;
        line-height: 45px;
        text-transform: capitalize;
        color: #162642;
      }
    }
  }
  .no-data-section {
    .no-blog {
      background: #ffffff;
      position: relative;
      padding: 133px 15px;
      color: #000000;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 50px;
      &::after {
        content: "";
        position: absolute;
        bottom: -2px;
        width: calc(100% - 70px);
        background: #e5c243;
        height: 7px;
        border-radius: 0 0 20px 20px;
      }
      h4 {
        font-weight: 900;
        font-size: 36px;
        line-height: 68px;
        margin: 43px 0 6px 0;
      }
      p {
        font-weight: 400;
        font-size: 20px;
        line-height: 40px;
        margin: 0;
      }
    }
  }
  .normalCardMain {
    .featuredcard {
      padding: 20px;
      .mainFlexCard {
        flex-direction: column;
        row-gap: 30px;
        .imageCon,
        .rightFeatureCon {
          width: 100%;
        }
        .imageCon {
          border-top-left-radius: 30px;
          border-top-right-radius: 30px;
          overflow: hidden;
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
        }
      }
    }
  }
}
@media (max-width: 992px) {
  .blogs-wrap {
    .banner {
      .banner-grid {
        padding: 100px 0 0 0;
        .banner-set {
          grid-template-columns: 1fr;
          &--left {
            order: 2;
            text-align: center;
            h1 {
              margin: 30px 0 16px 0;
            }
            p {
              font-weight: 400;
              font-size: 25px;
              line-height: 34px;
            }
          }
          &--right {
            order: 1;
            margin-top: 0;
          }
        }
      }
    }
    .blogsListCon {
      .featuredcard {
        .mainFlexCard {
          flex-direction: column;
          row-gap: 30px;
          .imageCon,
          .rightFeatureCon {
            width: 100%;
          }
          .imageCon {
            border-top-left-radius: 30px;
            border-top-right-radius: 30px;
            overflow: hidden;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
          }
        }
      }
      .tabsCon {
        background-color: transparent;
        .tabscon-wrap {
          padding: 0;
          border-radius: 0;
          .categoryMenuListCon {
            flex-direction: column;
            .leftTabsCon {
              order: 2;
              background-color: #fff;
              width: 100%;
              padding: 31px;
              margin: 74px 0 0 0;
              .ant-tabs {
                .ant-tabs-nav-wrap {
                  .ant-tabs-nav-list {
                    margin: 0 auto;
                  }
                }
              }
            }
            .rightTabCon {
              order: 1;
              text-align: center;

              p {
                color: #fff;
                font-weight: 700;
                font-size: 21px;
                line-height: 21px;
                margin-bottom: 21px;
              }
            }
          }
        }
      }
    }
  }
  .tabsCon {
    background-color: transparent;
    .tabscon-wrap {
      padding: 0;
      border-radius: 0;
      .categoryMenuListCon {
        flex-direction: column;
        .leftTabsCon {
          order: 2;
          background-color: #fff;
          width: 100%;
          padding: 31px;
          margin: 74px 0 0 0;
          .ant-tabs {
            .ant-tabs-nav-wrap {
              .ant-tabs-nav-list {
                margin: 0 auto;
              }
            }
          }
        }
        .rightTabCon {
          order: 1;
          text-align: center;

          p {
            color: #fff;
            font-weight: 700;
            font-size: 21px;
            line-height: 21px;
            margin-bottom: 21px;
          }
        }
      }
    }
  }
  .normalCard {
    flex: 100%;
    max-width: 100%;
  }
}

@media (max-width: 768px) {
  .blogs-wrap {
    .no-data-section {
      .no-blog {
        padding: 60px 15px;
        h4 {
          font-size: 24px;
          line-height: 30px;
          margin: 23px 0 6px 0;
        }
        p {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
    .banner {
      .banner-grid {
        padding: 46px 0 0 0;
        .banner-set {
          &--right {
            margin-top: 0;
          }
          p {
            margin-bottom: 34px;
          }
        }
      }
    }
  }
}
// @media (min-width: 990px) {
//   .blogs-wrap {
//     .normalCardMain {
//       .featuredcard {
//         min-height: 500px;
//       }
//     }
//   }
// }
