@import "../../App.scss";
.Service {
  background-color: #000000;
  .banner {
    background: $color-black;
    height: auto;
    padding-bottom: 70px;
    @media (max-width: 768px) {
      padding-top: 6rem;
    }

    .banner-grid {
      display: grid;
      grid-template-rows: auto auto;
      align-items: stretch;
      .banner-set {
        display: grid;
        grid-template-columns: 55% 45%;
        align-items: center;
        padding: 100px 0;
        margin: 93px 0;
        @media (max-width: 768px) {
          grid-template-columns: 100%;
        }

        &--left {
          padding-right: 0rem;
          text-align: left;
          @media (max-width: 768px) {
            padding-right: 3rem;
            padding-left: 3rem;
            text-align: center;
            margin-top: 1rem;
          }
          h1 {
            font-weight: 900;
            font-size: 41px;
            line-height: 55px;
            margin-bottom: 10px;
            color: #fff;

            span {
              font-size: 41px;
              font-weight: 900;
            }
            .yellowText {
              color: $color-yellow;
              font-weight: 900;
              font-size: 41px;
              line-height: 55px;
              margin-left: 10px;
            }
          }
          p {
            color: #fff;
            font-weight: 400;
            font-size: 26px;
            line-height: 41px;
            color: #ffffff;
            margin-bottom: 33px;
          }

          .getstarted-btn {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 700;
            font-size: 30px;
            line-height: 24px;
            text-align: center;
            text-transform: capitalize;
            padding: 26px 36px;
            white-space: nowrap;
            display: inline-block;
            transition: 0.3s;
            border-radius: 50px;
            color: $color-black;
            background: $color-yellow;
            &:hover {
              background: rgba($color-yellow, 0.8);
            }
          }
        }

        &--right {
          display: flex;
          justify-content: center;
          img {
            max-width: 100%;
            height: auto;
          }
        }
      }

      .strategyDevContainer {
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 100%;
        justify-content: space-between;
        grid-row-gap: 40px;
        column-gap: 54px;
        row-gap: 63px;
        position: relative;
        margin-bottom: 100px;

        .strategyDevItem {
          background: #ffffff;
          box-shadow: 0px 0px 33px rgba(235, 215, 140, 0.5);
          border-radius: 94px;
          padding: 25px 75px;
          display: flex;
          align-items: center;
          width: 100%;
          position: relative;
          &:nth-child(7),
          &:nth-child(8) {
            .more-info-wrap {
              &.isOpen {
                bottom: 0;
                top: unset;
              }
            }
          }
          &.odd {
            .more-info-wrap {
              left: calc(-100% - 54px);
              right: unset;
            }
          }
          .more-info-wrap {
            position: absolute;
            top: 0;
            left: 0;
            background: #ffffff;
            box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.33);
            border-radius: 41px;
            right: calc(-100% - 54px);
            padding: 0;
            text-align: center;
            z-index: 99;

            &.isOpen {
              padding: 54px;
              min-height: 373px;
            }
            .info-header {
              display: flex;
              align-items: center;
              justify-content: center;
              width: fit-content;
              margin: 0 auto;
              padding-bottom: 30px;
            }
            .details-wrap {
              font-weight: 400;
              font-size: 32px;
              line-height: 48px;
              text-align: center;
              color: #000000;
              margin-bottom: 23px;
            }
            .infoText {
              justify-content: center;
            }
          }

          .iconSvg {
            margin-right: 30px;
            svg {
              width: 90px;
              height: 90px;
            }
          }
          .textStrategy {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 500;
            font-size: 26px;
            line-height: 44px;
            text-transform: capitalize;
            color: #000000;
            width: 100%;
            text-align: left;
          }
          .infoText {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 500;
            font-size: 29px;
            line-height: 44px;
            text-transform: capitalize;
            color: #e5c243;
            width: 100%;
            text-align: left;
            display: flex;
            align-items: center;
            gap: 11px;
            cursor: pointer;
            img {
              width: 13px;
              height: 21px;
            }
          }
        }
      }

      .mentioned {
        margin: 0rem auto 1rem auto;
        text-align: center;
        @media (max-width: 768px) {
          width: 100%;
          margin: 2rem 0 0 0;
        }
        span {
          font-size: 16px;
          text-transform: uppercase;
          text-align: center;
          color: #fff;
          padding-bottom: 1rem;
          opacity: 0.5;
          @media (max-width: 768px) {
            font-size: 13px;
            opacity: 0.5;
          }
        }

        &--logos {
          display: grid;
          grid-gap: 1rem;
          margin-top: 15px;
          @media (min-width: 600px) {
            grid-template-columns: repeat(5, 1fr);
          }
          @media (max-width: 768px) {
            display: inline-flex;
            margin: 20px;
            grid-gap: 0rem;
          }
          @media (min-width: 900px) {
            grid-template-columns: repeat(5, 1fr);
          }
          div {
            img {
              @media (min-width: 600px) {
                width: 80px;
              }
              @media (max-width: 768px) {
                width: 100%;
              }
              @media (min-width: 900px) {
                width: 120px;
              }
            }
          }
        }
      }
    }
  }

  .our_process {
    padding: 4rem 0;
    .title {
      font-size: 32px;
      font-weight: 800;
      color: $color-black;
      position: relative;
      text-align: center;
      margin-bottom: 4rem;
      &::before {
        content: "";
        position: absolute;
        bottom: -25px;
        background: $color-black;
        height: 4px;
        width: 100px;
        left: 50%;
        margin-left: -50px;
      }
    }

    .process {
      &--left-content {
        display: grid;
        grid-template-columns: 50% 50%;
        @media (max-width: 767px) {
          grid-template-columns: 100%;
        }
        .content {
          background: $color-black;
          padding: 5rem 5rem;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          text-align: right;
          border-radius: 0px 26px 26px 0px;
          @media (max-width: 767px) {
            text-align: center;
            padding: 5rem 3rem;
          }

          &--number {
            border-radius: 100px;
            border: 2px solid $color-yellow;
            width: 32px;
            height: 32px;
            color: $color-yellow;
            text-align: right;
            margin-left: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 1rem;
            @media (max-width: 767px) {
              text-align: center;
              margin-left: auto;
              margin-right: auto;
            }
          }

          &--title {
            font-size: 18px;
            font-weight: 600;
            color: $color-yellow;
            position: relative;
            display: grid;
            &::before {
              content: "";
              position: absolute;
              bottom: -6px;
              background: #e5c243;
              height: 2px;
              width: 50px;
              right: 0;
              @media (max-width: 767px) {
                left: 50%;
                margin-left: -25px;
              }
            }
          }

          p {
            padding-top: 1rem;
            color: #fff;
          }
        }

        .image {
          display: flex;
          justify-content: center;
          width: 300px;
          margin: 0 auto;
          align-items: center;
          @media (max-width: 767px) {
            padding: 2rem;
          }
        }
      }

      &--right-content {
        display: grid;
        grid-template-columns: 50% 50%;
        @media (max-width: 767px) {
          grid-template-columns: 100%;
        }
        .content {
          background: $color-black;
          padding: 5rem 5rem;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          text-align: left;
          border-radius: 26px 0px 0px 26px;
          @media (max-width: 767px) {
            text-align: center;
            padding: 5rem 3rem;
          }

          &--number {
            border-radius: 100px;
            border: 2px solid $color-yellow;
            width: 32px;
            height: 32px;
            color: $color-yellow;
            text-align: left;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 1rem;
            @media (max-width: 767px) {
              text-align: center;
              margin-left: auto;
              margin-right: auto;
            }
          }

          &--title {
            font-size: 18px;
            font-weight: 600;
            color: $color-yellow;
            position: relative;
            display: grid;
            &::before {
              content: "";
              position: absolute;
              bottom: -6px;
              background: #e5c243;
              height: 2px;
              width: 50px;
              left: 0;
              @media (max-width: 767px) {
                left: 50%;
                margin-left: -25px;
              }
            }
          }

          p {
            padding-top: 1rem;
            color: #fff;
          }
        }

        .image {
          display: flex;
          justify-content: center;
          width: 300px;
          margin: 0 auto;
          align-items: center;
          @media (max-width: 767px) {
            padding: 2rem;
          }
        }
      }
    }
  }

  .our-recent-works-main-wrapper {
    .our-recent-works {
      background-color: #000000;
      min-height: 200px;
      border-bottom-right-radius: 50px;
      .RecentWorksCarousel-main-wrapper {
        .carousel-item {
          color: #ffffff;

          .carousel-item-inner-set {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 3rem 0;
            margin: 3rem 0;
            @media (max-width: 768px) {
              padding: 0rem 5rem;
              margin: 4rem 0 0rem 0;
            }

            img {
              margin-bottom: 2rem;
            }
            p {
              font-size: x-large;
              font-weight: bold;
            }
          }
        }
      }
    }
  }

  .heading {
    // padding-top: 124px;
    background-color: white;
    .title {
      color: $color-black;
      text-align: center;
      font-weight: 900;
      font-size: 42px;
      line-height: 44px;
      &.title-1 {
        padding: 102px 0 96px 0;
      }

      &.title-2 {
        padding: 125px 0 94px 0;
      }
      &.title-3 {
        padding: 124px 0 87px 0;
      }
      .title-text {
        position: relative;
        width: fit-content;
        margin: 0 auto;
        &::before {
          content: "";
          position: absolute;
          bottom: -25px;
          background: $color-black;
          height: 9px;
          width: 90%;
          left: 50%;
          transform: translateX(-50%);
        }
      }

      @media (max-width: 767px) {
        font-size: 24px;
        &::before {
          bottom: -15px;
        }
      }
    }
  }

  .investmentStrategy {
    background-color: #000000;
    color: #ffffff;
    padding: 63px 0px;
    border-radius: 0px 0px 50px 0px;
    svg {
      width: 100%;
      height: 100%;
    }
    @media (max-width: 767px) {
      padding: 4rem 3rem 4rem 3rem;
    }

    .left {
      text-align: left;
      padding-right: 3rem;
      @media (max-width: 767px) {
        padding-right: 0rem;
      }
      .desktop-hub-view {
        display: flex;
        @media (max-width: 992px) {
          display: none;
        }
      }
      .mobile-hub-view {
        display: none;
        @media (max-width: 992px) {
          display: flex;
        }
      }
      h3 {
        color: #e5c243;
        font-weight: bold;
      }
      p {
        font-size: x-large;
        padding-left: 40px;
        margin: 1.5rem 0;
        &::before {
          content: "";
          position: absolute;
          background: #e5c243;
          width: 15px;
          height: 60px;
          margin-left: -40px;
          border-radius: 10px;
          margin-top: 5px;
        }
        span {
          color: $color-yellow;
          font-weight: bolder;
        }
        @media (max-width: 767px) {
          font-size: large;
        }
        @media (min-width: 1400px) {
          margin: 1.5rem 0 2.5rem 0;
        }
      }
    }
    .right {
      text-align: center;
      display: flex;
    }
  }

  .our-stats-main-wrapper {
    .ourStats {
      background-color: #000000;
      color: #ffffff;
      padding: 54px 0 46px 0;
      border-bottom-right-radius: 50px;
      .our-stats-inner {
        @media (max-width: 992px) {
          margin-bottom: 70px;
        }
        @media (max-width: 767px) {
          padding: 0 45px;
        }
        @media (max-width: 540px) {
          padding: 0 15px;
        }
        .our-stats-set {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 52px;
          padding: 30px 0;
          img {
            max-height: 75px;
          }
          .wrap {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }
          h3 {
            color: #ffffff;
            font-weight: bolder;
            font-size: x-large;
          }
          p {
            color: #e5c243;
            font-weight: bold;
            font-size: large;
            text-transform: capitalize;
          }
        }
        .our-stats-set.left {
          position: relative;
          &::after {
            content: "";
            position: absolute;
            right: -15px;
            top: 0;
            width: 1px;
            height: 100%;
            background-color: #fff;
          }
        }
      }
      .additionalPerkCon {
        .upToWhiteText {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 700;
          font-size: 40px;
          line-height: 40px;
          text-align: center;
          text-transform: capitalize;
          color: #ffffff;
          word-break: break-word;
          @media (max-width: 1190px) {
            font-size: 35px;
            line-height: 59px;
          }
          @media (max-width: 992px) {
            font-size: 40px;
            line-height: 59px;
          }
          // @media (max-width: 540px) {
          //   font-size: 50px;
          // }
        }
        .upToYellowText {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 500;
          font-size: 32px;
          line-height: 59px;
          text-align: center;
          text-transform: capitalize;
          color: #e5c243;
          word-break: break-word;
          @media (max-width: 1190px) {
            font-size: 27px;
            line-height: 59px;
          }
        }
        .mlLeft {
          margin-left: 10px;
        }
        .addMoreText {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 700;
          font-size: 32px;
          line-height: 38px;
          color: #ffffff;
          margin: 49px 0 0;
          @media (max-width: 992px) {
            margin: 20px 0 0;
          }
        }
      }
    }
  }

  .buildwithus-main-wrapper {
    padding: 103px 0;
    background-color: #ffffff;
    .buildwithus-main-group {
      .btn {
        background: #e5c243;
        border-radius: 74px;
        padding: 45px 33px;
        font-weight: 700;
        font-size: 37px;
        line-height: 24px;
        max-width: 395px;
        width: 100%;
      }
    }
  }
}
.testimonialCon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 80px 0;
  min-height: 475px;
  height: 100%;
  width: 70%;
  margin: 0 auto;
  .imageContainer {
    min-width: 224px;
    min-height: 224px;
    overflow: hidden;
    border-radius: 50%;
    padding: 10px;
    background: #fff;
    margin-right: 119px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .textContainer {
    display: flex;
    max-width: 500px;
    text-align: left;
    position: relative;
    .quoteImgCon {
      margin-right: 10px;
      position: absolute;
      left: -70px;
      top: -30px;
    }
  }
  .textRightCon {
    padding-top: 20px;
    .whiteText {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 700;
      font-size: 28px;
      line-height: 32px;
      color: #ffffff;
      margin-bottom: 25px;
    }
    .nameText {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 700;
      font-size: 25px;
      line-height: 35px;
      color: #e5c243;
    }
    .desigText {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 19px;
      line-height: 35px;
      color: #ffffff;
    }
  }
}
.testimonialCarousel {
  .carousel-indicators {
    button {
      background: #ffffff;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      border-top: inherit;
      border-bottom: inherit;
      margin-left: 6px;
      margin-right: 6px;
      opacity: 1;
    }
    .active {
      background: #e5c243;
    }
  }
}
@media (max-width: 1199px) {
  .Service {
    .banner {
      .banner-grid {
        .banner-set {
          &--left {
            h1 {
              .yellowText {
                display: block;
                margin-left: 0;
              }
            }
          }
        }
        .strategyDevContainer {
          row-gap: 40px;
          column-gap: 20px;
          .strategyDevItem {
            padding: 25px 55px;
            &.odd {
              .more-info-wrap {
                left: calc(-100% - 20px);
              }
            }
            .iconSvg {
              margin-right: 20px;
              svg {
                width: 70px;
                height: 70px;
              }
            }
            .textStrategy {
              font-size: 26px;
              line-height: 34px;
            }
            .infoText {
              font-size: 24px;
              line-height: 34px;
            }
            .more-info-wrap {
              right: calc(-100% - 20px);
              &.isOpen {
                padding: 45px;
                min-height: 323px;
              }
              .details-wrap {
                font-size: 26px;
                line-height: 38px;
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 992px) {
  .testimonialCon {
    flex-direction: column;
    min-height: 771px;
    .imageContainer {
      margin-bottom: 20px;
      margin-right: 0;
      width: 160px;
      height: 160px;
    }
    .textContainer {
      text-align: center;
    }
    .textRightCon {
      .whiteText {
        min-height: 96px;
      }
    }
  }
  .Service {
    .heading {
      .title {
        &.title-1 {
          padding: 102px 0 59px 0;
        }
        .title-text {
          font-weight: 900;
          font-size: 35px;
          line-height: 44px;
        }
      }
    }
    .our-stats-main-wrapper {
      .ourStats {
        padding: 20px 0 25px 0;
        .our-stats-inner {
          .our-stats-set {
            &.left {
              padding: 70px 0;
              &::after {
                bottom: 0;
                top: unset;
                height: 1px;
                width: 70%;
                left: 50%;
                transform: translateX(-50%);
              }
            }
            &.right {
              padding: 70px 0;
            }
          }
        }
        .additionalPerkCon {
          .upToYellowText {
            font-weight: 500;
            font-size: 32px;
            line-height: 59px;
            span {
              font-size: 40px;
            }
          }
        }
      }
    }
    .banner {
      padding-bottom: 98px;
      .banner-grid {
        .banner-set {
          grid-template-columns: 1fr;
          margin: 0;
          &--left {
            order: 2;
            padding: 0;
            max-width: 80%;
            margin: 1rem auto 0 auto;
            text-align: center;
            h1 {
              font-size: 39px;
              line-height: 54px;
              margin-bottom: 33px;
            }
            p {
              font-size: 25px;
              line-height: 34px;
              margin-bottom: 55px;
            }
            .getstarted-btn {
              font-size: 27px;

              padding: 22px 31px;
            }
          }
          &--right {
            order: 1;
            margin-top: 100px;
            img {
              max-width: 75%;
            }
          }
        }
        .strategyDevContainer {
          grid-template-columns: 1fr;
          margin: 0;
          gap: 45px;
          .strategyDevItem {
            &.odd {
              .more-info-wrap {
                right: 0;
                left: 0;
              }
            }
            .more-info-wrap {
              left: 0;
              &.isOpen {
                right: 0;
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .testimonialCon {
    max-width: 300px;
    width: 100%;
    .textContainer {
      .quoteImgCon {
        left: -50px;
        top: -10px;
        margin: 0;
        img {
          width: 50px;
        }
      }
    }
  }
  .Service {
    .heading {
      .title {
        &.title-1 {
          .title-text {
            &::before {
              width: 190px;
            }
          }
        }
        .title-text {
          &::before {
            width: 100%;
          }
        }
        &.title-2 {
          padding: 127px 0 64px 0;
        }
        &.title-3 {
          padding: 106px 0 56px 0;
        }
      }
    }
    .banner {
      .banner-grid {
        .banner-set {
          padding: 0 0 88px 0;
          &--left {
            order: 2;
            padding: 0;
            max-width: 80%;
            margin: 1rem auto 0 auto;
            h1 {
              font-size: 39px;
              line-height: 54px;
              margin-bottom: 33px;
            }
            p {
              font-size: 25px;
              line-height: 34px;
              margin-bottom: 55px;
            }
            .getstarted-btn {
              font-size: 27px;

              padding: 22px 31px;
            }
          }
          &--right {
            order: 1;
            margin-top: 100px;
            img {
              max-width: 75%;
            }
          }
        }
      }
    }
    .our-stats-main-wrapper {
      .ourStats {
        .additionalPerkCon {
          .upToYellowText {
            white-space: pre;
          }
        }
      }
    }
  }
}
@media (max-width: 576px) {
  .testimonialCarousel {
    .carousel-control-next-icon,
    .carousel-control-prev-icon {
      width: 20px;
      height: 20px;
    }
  }
  .testimonialCon {
    flex-direction: column;
    padding: 50px 15px;
    min-height: 760px;
    max-width: 220px;

    .imageContainer {
      margin-bottom: 20px;
      margin-right: 0;
      min-width: 160px;
      min-height: 160px;
      padding: 5px;
    }
    .textContainer {
      text-align: center;
      .quoteImgCon {
        width: 30px;
        height: fit-content;
        left: -30px;
        top: 0px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .textRightCon {
      // padding-top: 20px;
      .whiteText {
        font-size: 22px;
      }
      .nameText {
        font-size: 19px;
      }
      // .desigText {
      //   font-family: "Roboto";
      //   font-style: normal;
      //   font-weight: 400;
      //   font-size: 15px;
      //   line-height: 20px;
      //   color: #ffffff;
      // }
    }
  }
  .Service {
    .heading {
      .title {
        &.title-1 {
          padding: 70px 0 59px 0;
        }
        &.title-2 {
          padding: 70px 0 59px 0;
        }
        &.title-3 {
          padding: 70px 0 59px 0;
        }
      }
    }
    .banner {
      .banner-grid {
        .strategyDevContainer {
          gap: 35px;
          .strategyDevItem {
            padding: 15px 30px;
            .more-info-wrap {
              &.isOpen {
                padding: 25px;
                border-radius: 25px;
              }
              .info-header {
                padding-bottom: 15px;
              }
              .infoText {
                font-size: 20px;
              }
              .details-wrap {
                font-size: 20px;
                margin-bottom: 15px;
              }
            }
            .infoText {
              font-size: 20px;
            }
          }
          .strategyDevItem {
            .textStrategy {
              font-size: 20px;
            }
          }
        }
      }
    }
    .our-stats-main-wrapper {
      .ourStats {
        .additionalPerkCon {
          .upToYellowText {
            white-space: unset;
            font-size: 23px;
            line-height: 40px;
            span {
              font-size: 30px;
              line-height: 40px;
            }
          }
          .upToWhiteText {
            font-size: 30px;
            line-height: 35px;
            span {
              line-height: 40px;
              font-size: 24px;
            }
          }
        }
        .our-stats-inner {
          padding: 0;
          .additionalPerkCon {
            .upToYellowText {
              font-size: 24px;
              line-height: 24px;
              span {
                font-size: 24px;
              }
            }
            .upToWhiteText {
              font-size: 30px;
              line-height: 30px;
            }
          }
          .our-stats-set {
            img {
              max-height: 50px;
            }
          }
        }
      }
    }
    .buildwithus-main-wrapper {
      padding: 60px 0;
      .buildwithus-main-group {
        .btn {
          width: fit-content;
          padding: 35px 40px;
          font-size: 30px;
        }
      }
    }
  }
}
