.payment-type-step-main {
  width: 100%;
  .credit-card-btn {
    background: #ffffff;
    border: 4px solid #e5c243;
    box-sizing: border-box;
    border-radius: 56px;
    max-width: 326px;
    height: 90px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: 0 auto;
    img {
      height: 43px;
      width: 43px;
      margin-right: 23px;
    }
    span {
      font-weight: 500;
      font-size: 26px;
      color: #000000;
    }
  }
  @media (max-width: 576px) {
    width: 100%;
    .credit-card-btn {
      padding: 0;
      height: 90px;
      max-width: 278px;
      width: 100%;
      margin: 0 auto;
      img {
        height: 37px;
        width: 37px;
        margin-right: 20px;
      }
      span {
        font-weight: 500;
        font-size: 23px;
        line-height: 41px;
      }
    }
  }
}
