.update-details-page-main-wrap {
  padding: 15px 60px;
  text-align: left;
  @media (max-width: 768px) {
    padding: 15px 30px;
  }
  .backCon {
    display: flex;
    align-items: center;
    .backIcon {
      margin-right: 8px;
      cursor: pointer;
    }
    .backText {
      font-weight: 500;
      font-size: 24px;
      line-height: 24px;
      text-transform: capitalize;
      color: #000000;
    }
  }
  .detailCardCon {
    margin-top: 30px;
    padding: 42px 64px 74px;
    background: #ffffff;
    box-shadow: 0px 2px 19px rgba(32, 14, 50, 0.12);
    border-radius: 13px;
    @media (max-width: 991px) {
      padding: 34px 44px 64px;
    }
    @media (max-width: 767px) {
      padding: 24px 34px 54px;
    }
    @media (max-width: 425px) {
      padding: 24px 26px 42px;
    }
    .detailHeaderCon {
      display: flex;
      align-items: center;
      margin-bottom: 40px;
      .header-right {
        margin-left: 18px;
        .detailHeaderText {
          font-weight: 500;
          font-size: 24px;
          line-height: 34px;
          color: #000000;
        }
        .detailDateText {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: #162642;
          text-align: left;
        }
      }
    }
    .detailContentText {
      display: flex;
      flex-direction: column;
    }
  }
}
