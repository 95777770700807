@import "../../App.scss";
footer {
  background-color: $color-black;
  padding: 0rem 0;
  color: $color-white;

  .footer-top {
    padding: 71px 54px 48px 54px;
    @media (max-width: 768px) {
      padding: 3rem 5rem;
    }

    .left {
      text-align: left;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      .list-inline-item {
        margin: 0 20px 0 3px;
        @media (max-width: 768px) {
          display: block;
          margin-bottom: 10px;
        }
        a {
          color: #ffffff;
          font-weight: 400;
          font-size: 20px;
          line-height: 41px;
        }
      }

      .list-inline {
        margin: 0;
      }
    }
    .middle {
      text-align: left;
      display: flex;
      flex-direction: column;
      justify-content: center;
      p {
        &:nth-child(1) {
          font-weight: 700;
          font-size: 20px;
          line-height: 21px;
          margin: 0 0 14px 0;
        }
        &:nth-child(2) {
          margin-bottom: 12px;
          font-weight: 400;
          font-size: 20px;
          line-height: 26px;
        }
      }
      a {
        font-weight: 400;
        font-size: 20px;
        line-height: 21px;
      }
    }
    .right {
      text-align: left;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .social-media-icons {
        color: #ffffff;
        font-size: x-large;
        display: flex;
        gap: 10px;
        a {
          color: #c4c4c4;
        }
        svg {
          margin-right: 5px;
          cursor: pointer;
          opacity: 0.5;
        }
      }

      p {
        font-weight: 400;
        font-size: 19px;
        line-height: 21px;
        margin: 10px 0 11px 0;
      }

      .ant-input-group-compact {
        display: flex;
        max-width: 278px;
        height: 38px;
        .ant-input {
          width: 100%;
          border-top-left-radius: 25px;
          border-bottom-left-radius: 25px;
          &::placeholder {
            color: #0000006e;
          }
        }
        .ant-btn-primary {
          background-color: $color-yellow;
          border-color: $color-yellow;
          color: $color-black;
          border-top-right-radius: 25px;
          border-bottom-right-radius: 25px;
          height: 38px;
        }
      }
    }
  }
  .footer-bottom {
    border-top: solid 1px rgba($color: #ffffff, $alpha: 0.2);
    padding: 36px 0;
    font-weight: 300;
    font-size: 16px;
    line-height: 21px;
  }
}
@media (max-width: 992px) {
  footer {
    .footer-top {
      padding: 46px 15px;
      .right {
        .social-media-icons {
          gap: 5px;
        }
      }
    }
  }
}
@media (max-width: 767px) {
  footer {
    .footer-top {
      max-width: 308px;
      margin: 0 auto;
      padding: 46px 0;
      .left {
        .list-inline-item {
          margin-bottom: 0;
          a {
            font-size: 18px;
            line-height: 30px;
          }
        }
        .list-inline {
          margin: 10px 0 40px 0;
          padding: 0 10px;
        }
      }
      .middle {
        a {
          margin-bottom: 40px;
          padding: 0 10px;
          font-size: 18px;
          line-height: 30px;
        }
        p {
          padding: 0 10px;
          &:nth-child(2) {
            font-size: 18px;
            line-height: 30px;
          }
        }
      }
      .right {
        .social-media-icons,
        p,
        .ant-input-group {
          padding: 0 10px;
          font-size: 18px;
          line-height: 30px;
        }
      }
    }
    .footer-bottom {
      padding: 57px 0 49px 0;
      .footer-bottom-wrap {
        max-width: 308px;
        margin: 0 auto;
        span {
          &:first-child {
            display: none;
          }
        }
      }
    }
  }
}
