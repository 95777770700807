@import "../../App.scss";
.Home {
  margin-top: 102px;
  .banner {
    background: $color-black;
    height: auto;
    padding-bottom: 53px;

    .banner-grid {
      display: grid;
      grid-template-rows: auto auto;
      align-items: stretch;
      .banner-set {
        display: grid;
        grid-template-columns: 48% 52%;
        padding: 142px 0 149px 0;
        @media (max-width: 768px) {
          grid-template-columns: 100%;
          padding: 66px 0 88px 0;
        }

        &--left {
          text-align: left;
          @media (max-width: 768px) {
            padding-right: 3rem;
            padding-left: 3rem;
            text-align: center;
            margin-top: 1rem;
          }
          h1 {
            color: #ffffff;
            font-weight: 900;
            font-size: 41px;
            line-height: 55px;
            margin-bottom: 10px;

            span {
              color: $color-yellow;
            }
          }
          p {
            color: #fff;
            font-weight: 400;
            font-size: 26px;
            line-height: 41px;
            margin-bottom: 36px;
          }

          .getstarted-btn {
            font-weight: 700;
            font-size: 30px;
            line-height: 24px;
            background: #e5c243;
            border-radius: 56px;
            color: $color-black;
            padding: 26px 40px;
            white-space: nowrap;
            transition: 0.3s;
            display: inline-block;
            &:hover {
              background: rgba($color-yellow, 0.8);
            }
          }
        }

        &--right {
          display: flex;
          justify-content: center;
          img {
            @media (max-width: 768px) {
              width: 70%;
            }
          }
        }
      }

      .mentioned {
        margin: 44px auto 0 auto;
        text-align: center;
        @media (max-width: 768px) {
          width: 100%;
          margin: 0;
        }
        span {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 300;
          font-size: 22px;
          line-height: 23px;
          text-align: center;
          text-transform: uppercase;
          color: #ffffff;
        }

        &--logos {
          display: grid;
          grid-gap: 1rem;
          margin-top: 15px;
          @media (min-width: 600px) {
            grid-template-columns: repeat(5, 1fr);
          }
          @media (max-width: 768px) {
            display: inline-flex;
            margin: 20px;
            grid-gap: 0rem;
          }
          @media (min-width: 900px) {
            grid-template-columns: repeat(5, 1fr);
          }
          div {
            img {
              @media (min-width: 600px) {
                width: 80px;
              }
              @media (max-width: 768px) {
                width: 100%;
              }
              @media (min-width: 900px) {
                width: 120px;
              }
            }
          }
        }
      }
    }
  }

  .our_process {
    padding: 124px 0 0 0;
    .title {
      color: $color-black;
      position: relative;
      text-align: center;
      margin-bottom: 81px;
      font-weight: 900;
      font-size: 42px;
      line-height: 44px;
      text-align: center;
      text-transform: capitalize;

      &::before {
        content: "";
        position: absolute;
        bottom: -32px;
        background: $color-black;
        height: 9px;
        left: 50%;
        width: 190px;
        transform: translateX(-50%);
      }
    }

    .process {
      &--left-content {
        display: grid;
        grid-template-columns: 50% 50%;
        @media (max-width: 767px) {
          grid-template-columns: 100%;
        }
        .content {
          background: $color-black;
          padding: 64px 100px;
          display: flex;
          justify-content: center;
          flex-direction: column;
          text-align: right;
          border-radius: 0px 50px 50px 0px;
          min-height: 444px;
          @media (max-width: 992px) {
            padding: 64px 50px;
          }
          @media (max-width: 767px) {
            text-align: center;
            padding: 86px 92px;
          }
          @media (max-width: 576px) {
            text-align: center;
            padding: 40px 15px;
          }

          &--number {
            font-weight: 700;
            font-size: 24px;
            line-height: 40px;
            border-radius: 100px;
            border: 4px solid $color-yellow;
            width: 50px;
            height: 50px;
            color: $color-yellow;
            text-align: right;
            margin-left: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 1rem;
            @media (max-width: 767px) {
              text-align: center;
              margin-left: auto;
              margin-right: auto;
            }
          }

          &--title {
            font-weight: 700;
            font-size: 35px;
            line-height: 41px;
            color: $color-yellow;
            position: relative;
            display: grid;

            &::before {
              content: "";
              position: absolute;
              bottom: -18px;
              background: #e5c243;
              height: 5px;
              width: 107px;
              right: 0;
              @media (max-width: 767px) {
                left: 50%;
                transform: translateX(-50%);

                max-width: unset;
              }
            }
          }

          p {
            font-weight: 400;
            font-size: 24px;
            line-height: 40px;
            padding-top: 25px;
            color: #fff;
            margin: 18px 0 0 auto;
            max-width: 533px;
            @media (max-width: 576px) {
              margin: 18px auto 0 auto;
              max-width: 409px;
            }
            @media (max-width: 767px) {
              margin: 18px auto 0 auto;
              max-width: 100%;
            }
          }
        }

        .image {
          display: flex;
          justify-content: center;
          width: 300px;
          margin: 0 auto;
          align-items: center;
          @media (max-width: 767px) {
            padding: 2rem;
            &.mobile-img-wrap {
              display: none;
            }
          }
        }
      }

      &--right-content {
        display: grid;
        grid-template-columns: 50% 50%;
        @media (max-width: 767px) {
          grid-template-columns: 100%;
        }
        .content {
          min-height: 444px;
          background: $color-black;
          padding: 64px 100px;
          display: flex;
          justify-content: center;
          flex-direction: column;
          text-align: left;
          border-radius: 50px 0px 0px 50px;
          @media (max-width: 992px) {
            padding: 64px 50px;
          }
          @media (max-width: 767px) {
            text-align: center;
            padding: 86px 92px;
          }
          @media (max-width: 576px) {
            text-align: center;
            padding: 40px 15px;
          }

          &--number {
            font-weight: 700;
            font-size: 24px;
            line-height: 40px;
            border-radius: 100px;
            border: 4px solid $color-yellow;
            width: 50px;
            height: 50px;
            color: $color-yellow;
            text-align: left;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 1rem;
            @media (max-width: 767px) {
              text-align: center;
              margin-left: auto;
              margin-right: auto;
            }
          }

          &--title {
            font-weight: 700;
            font-size: 35px;
            line-height: 39px;
            color: $color-yellow;
            position: relative;
            display: grid;
            max-width: 493px;
            @media (max-width: 767px) {
              max-width: 100%;
            }
            &::before {
              content: "";
              position: absolute;
              bottom: -20px;
              background: #e5c243;
              height: 5px;
              width: 107px;
              left: 0;

              @media (max-width: 767px) {
                left: 50%;
                transform: translateX(-50%);
                margin: 0 auto;
              }
            }
          }

          p {
            font-weight: 400;
            font-size: 24px;
            line-height: 40px;
            padding-top: 25px;
            color: #fff;
            max-width: 507px;
            margin-top: 18px;
            @media (max-width: 576px) {
              max-width: 409px;
              margin: 18px auto 0 auto;
            }
            @media (max-width: 767px) {
              max-width: 100%;
            }
          }
        }

        .image {
          display: flex;
          justify-content: center;
          width: 300px;
          margin: 0 auto;
          align-items: center;
          @media (max-width: 767px) {
            padding: 2rem;
          }
        }
      }
    }
  }

  .our-recent-works-main-wrapper {
    .our-recent-works {
      background-color: #000000;
      border-radius: 0px 0px 50px 0;

      .RecentWorksCarousel-main-wrapper {
        .carousel-item {
          color: #ffffff;

          .carousel-item-inner-set {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            @media (max-width: 768px) {
              padding: 0rem 5rem;
              // margin: 4rem 0 0rem 0;
            }

            img {
              margin-bottom: 67px;
            }
            p {
              font-weight: 700;
              margin: 0 0 61px 0;
              font-size: 34px;
              line-height: 51px;
              max-width: 484px;
            }
          }
        }
      }
    }
  }

  .heading {
    .title {
      font-weight: 900;
      font-size: 42px;
      line-height: 44px;
      color: $color-black;
      position: relative;
      text-align: center;
      margin-bottom: 4rem;
      &.title-2 {
        padding: 72px 0 0 0;
        margin: 73px 0 81px 0;
        @media (max-width: 767px) {
          padding: 62px 0 0 0;
          margin: 62px 0 76px 0;
        }
        &::before {
          bottom: -31px;
        }
      }
      &.title-3 {
        padding: 72px 0 0 0;
        margin: 73px 0 102px 0;
        &.mobile-text {
          display: none;
        }
        &::before {
          bottom: -33px;
        }
        @media (max-width: 767px) {
          display: none;
          &.mobile-text {
            display: flex;
            justify-content: center;
          }
        }
      }
      &.title-4 {
        padding: 72px 0 0 0;
        margin: 73px 0 88px 0;
        &::before {
          bottom: -32px;
        }
        @media (max-width: 767px) {
          font-size: 35px;
          padding: 64px 0 0 0;
          margin: 64px 0 84px 0;
        }
      }
      &::before {
        content: "";
        position: absolute;
        bottom: -32px;
        background: $color-black;
        height: 9px;
        width: 190px;
        left: 50%;
        transform: translateX(-50%);
      }

      @media (max-width: 767px) {
        &::before {
          bottom: -15px;
        }
      }
    }
  }

  .investmentStrategy {
    background-color: #000000;
    color: #ffffff;
    padding: 83px 115px 83px 83px;
    border-radius: 0px 0px 26px 0px;
    @media (max-width: 992px) {
      padding: 82px 57px 116px 57px;
    }
    h3 {
      font-style: normal;
      font-weight: 900;
      font-size: 41px;
      line-height: 55px;
      color: #e5c243;
      margin-bottom: 56px;
      text-align: left;
      @media (max-width: 1400px) {
        font-size: 38px;
      }
      @media (max-width: 1200px) {
        font-size: 35px;
      }
      @media (max-width: 992px) {
        margin-bottom: 46px;
        font-weight: 900;
        font-size: 34px;
        line-height: 49px;
      }
      @media (max-width: 425px) {
        font-size: 30px;
      }
    }
    .investment-main {
      .left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 30px;
        @media (max-width: 992px) {
          gap: 73px;
          margin-bottom: 50px;
        }

        p {
          font-weight: 400;
          font-size: 26px;
          line-height: 41px;
          color: #ffffff;
          padding: 0 0 0px 34px;
          text-align: left;
          position: relative;
          margin: 0;
          &::before {
            content: "";
            position: absolute;
            background: #e5c243;
            width: 16px;
            height: 54px;
            left: 0;
            border-radius: 10px;
            margin-top: 5px;
          }
          span {
            color: $color-yellow;
            font-weight: bolder;
          }
          @media (max-width: 992px) {
            padding: 0 0 0px 37px;
            font-weight: 400;
            font-size: 26px;
            line-height: 39px;
            color: #ffffff;
            &::before {
              height: calc(100% - 10px);
            }
          }
          @media (max-width: 425px) {
            font-size: 22px;
          }
        }
      }
    }
    .right {
      height: 100%;
      display: flex;
      justify-content: end;
      align-items: center;
      img {
        height: 80%;
      }
      @media (max-width: 992px) {
        justify-content: center;
        img {
          height: 100%;
        }
      }
    }
  }

  .our-stats-main-wrapper {
    .ourStats {
      background-color: #000000;
      color: #ffffff;
      padding: 4rem 0;
      border-radius: 0px 0px 26px 0px;
      @media (max-width: 767px) {
        padding: 1.5rem 0;
      }

      .our-stats-inner {
        @media (max-width: 767px) {
          padding: 0 20px;
        }
        .our-stats-set {
          display: flex;
          flex-direction: column;
          padding: 20px 30px;
          @media (max-width: 992px) {
            padding: 90px 0px 79px;
          }
          .our-stats-image-wrap {
            margin-bottom: 60px;
            @media (max-width: 320px) {
              margin-bottom: 70px;
            }
          }
          img {
            @media (max-width: 767px) {
              width: 50%;
            }
          }
          h3 {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 700;
            font-size: 41px;
            line-height: 44px;
            text-transform: capitalize;
            color: #ffffff;
            margin: 0 0 10px;
            @media (max-width: 1190px) {
              font-size: 38px;
            }
            @media (max-width: 992px) {
              font-size: 46px;
              margin: 0 0 17px;
            }
            @media (max-width: 520px) {
              font-size: 45px;
            }
          }
          p {
            color: #e5c243;
            text-transform: capitalize;
            font-weight: 500;
            font-size: 32px;
            line-height: 44px;
            margin: 0;
            white-space: pre;
            @media (max-width: 992px) {
              font-size: 30px;
            }
          }
        }
        .our-stats-set.left {
          border-right: solid 1px #ffffff;
          @media (max-width: 991px) {
            border-right: none;
            border-bottom: solid 1px #ffffff;
          }
        }
        .our-stats-set.middle {
          border-right: solid 1px #ffffff;
          @media (max-width: 767px) {
            border-right: none;
            border-bottom: solid 1px #ffffff;
          }
        }
      }
    }
  }

  .buildwithus-main-wrapper {
    padding: 5rem 0;
    .btn {
      border-radius: 45px;
      font-weight: bold;
      background-color: $color-yellow;
      padding: 1rem 2.5rem;
      font-size: x-large;
    }
  }
}
@media (max-width: 1199px) {
  .Home {
    .our-recent-works-main-wrapper {
      .our-recent-works {
        .RecentWorksCarousel-main-wrapper {
          .carousel-item {
            .carousel-item-inner-set {
              p {
                font-size: 30px;
                max-width: 80%;
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .Home {
    .banner {
      .banner-grid {
        .banner-set {
          &--left {
            margin: 0;
            h1 {
              font-size: 39px;
              line-height: 47px;
              margin: 65px 0 23px 0;
            }
            p {
              font-size: 25px;
              line-height: 34px;
              margin-bottom: 49px;
            }
          }
        }
      }
    }
    .our-recent-works-main-wrapper {
      .our-recent-works {
        .RecentWorksCarousel-main-wrapper {
          .carousel-item {
            .carousel-item-inner-set {
              p {
                width: 80%;
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 992px) {
  .Home {
    .our-recent-works-main-wrapper {
      .our-recent-works {
        border-radius: 0px 0px 50px 0px;
      }
    }
    .banner {
      .banner-grid {
        .banner-set {
          grid-template-columns: 1fr;
          &--left {
            order: 2;
            text-align: center;
            padding: 0;
            max-width: 90%;
            margin: 0 auto;
          }
          &--right {
            order: 1;
          }
        }
      }
    }
    .our_process {
      .title {
        font-weight: 900;
        font-size: 35px;
        line-height: 44px;
      }
    }
  }
}
@media (max-width: 576px) {
  .Home {
    .heading {
      .title.title-4 {
        padding: 0;
        font-size: 35px;
      }
    }
    .investmentStrategy {
      padding: 82px 25px 116px 25px;
    }
    .our-recent-works-main-wrapper {
      .our-recent-works {
        .RecentWorksCarousel-main-wrapper {
          .carousel-item {
            .carousel-item-inner-set {
              padding: 0 15px;
              img {
                margin-bottom: 20px;
              }
              p {
                width: 100%;
                font-size: 22px;
                line-height: 30px;
              }
            }
          }
        }
      }
    }
  }
}
