.invoices-list-page {
  width: 100%;
  padding: 15px 60px;
  @media (max-width: 768px) {
    padding: 15px 30px;
  }
  @media (max-width: 413px) {
    padding: 15px 15px;
  }
  .invoices-list-header {
    background: #ffffff;
    box-shadow: 0px 2px 19px rgba(32, 14, 50, 0.12);
    border-radius: 13px;
    padding: 25px 50px;
    @media (max-width: 991px) {
      flex-direction: column;
      row-gap: 35px;
      margin-top: 35px;
      padding: 35px 35px 45px;
    }
    .header-invoices {
      border-right: 1px solid rgba(0, 0, 0, 0.17);
      padding-right: 40px;
      @media (max-width: 991px) {
        border-right: unset;
        padding-right: 0;
        padding-bottom: 35px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.17);
        width: 100%;
      }
      .header-invoices-text {
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        text-transform: capitalize;
        color: #000000;
      }
    }
    .header-balance {
      border-right: 1px solid rgba(0, 0, 0, 0.17);
      padding-right: 40px;
      column-gap: 20px;
      @media (max-width: 991px) {
        border-right: unset;
        padding-right: 0;
        padding-bottom: 35px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.17);
        width: 100%;
      }
      .header-balance-text {
        .header-total {
          font-weight: normal;
          font-size: 15px;
          line-height: 24px;
          color: #000000;
        }
        .header-amount {
          font-weight: 500;
          font-size: 20px;
          line-height: 24px;
          color: #000000;
        }
      }
    }
    .header-credit {
      border-right: 1px solid rgba(0, 0, 0, 0.17);
      padding-right: 40px;
      column-gap: 20px;
      @media (max-width: 991px) {
        border-right: unset;
        padding-right: 0;
        padding-bottom: 35px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.17);
        width: 100%;
      }
      .header-credit-text {
        .header-your-credit {
          font-weight: normal;
          font-size: 15px;
          line-height: 24px;
          color: #000000;
          display: flex;
          justify-content: center;
          align-items: center;
          .anticon {
            margin-left: 5px;
            cursor: pointer;
          }
        }
        .header-credit-amount {
          font-weight: 500;
          font-size: 20px;
          line-height: 24px;
          color: #000000;
        }
      }
    }

    .all-complete-tabs {
      height: 40px;
      background: #ffffff;
      box-shadow: 0px 0px 13px rgba(32, 14, 50, 0.18);
      border-radius: 34px;
      .tabs {
        width: 100%;
        height: 100%;
        font-weight: 500;
        font-size: 15px;
        line-height: 41px;
        color: #000000;
        padding: 18px;
        cursor: pointer;
        &.active {
          background: #e5c243;
          cursor: auto;
        }
        &:nth-of-type(1) {
          &.active {
            border-radius: 34px 0 0 34px;
          }
        }
        &:nth-of-type(2) {
          border-left: 1px solid #e1e1e1;
          border-right: 1px solid #e1e1e1;
        }
        &:nth-of-type(3) {
          &.active {
            border-radius: 0 34px 34px 0;
          }
        }
      }
    }
  }
  .invoices-grid-wrap {
    display: block;
    .main-invoices-wrapper {
      .table-header {
        background: #000000;
        border-radius: 14px;
        height: 53px;
        color: #ffffff;
        margin: 40px 0 20px;
        padding: 0 34px;
        @media (min-width: 991px) and (max-width: 1199px) {
          padding: 0 12px;
        }
        .th-data {
          font-size: 15px;
          line-height: 24px;
          cursor: pointer;
          color: #fff;
          flex: 1;
          display: flex;
          justify-content: flex-start;
          @media (min-width: 992px) and (max-width: 1199px) {
            font-size: 14px;
          }
          &:first-child {
            flex: 0.85 1;
            display: flex;
            justify-content: flex-start;
          }
        }
      }
      .table-body {
        width: 100%;
        margin-bottom: 60px;
        @media (max-width: 991px) {
          margin-top: 25px;
        }
        .table-row {
          padding: 14px 34px;
          flex: 1;
          display: flex;
          background: #ffffff;
          box-shadow: 0px 2px 19px rgba(32, 14, 50, 0.12);
          border-radius: 13px;
          margin-bottom: 20px;
          @media (min-width: 768px) and (max-width: 991px) {
            padding: 14px 20px;
          }
          @media (max-width: 767px) {
            padding: 25px;
            flex-direction: column;
            align-items: center;
          }
          .invoice-cell {
            display: flex;
            flex: 1;
            align-items: center;
            @media (max-width: 767px) {
              margin-bottom: 20px;
            }
            &:first-child {
              flex: 0.85 1;
              display: flex;
              justify-content: flex-start;
            }
            &:nth-of-type(2) {
              @media (max-width: 991px) {
                flex: 0.5;
              }
            }

            .invoice-wrap {
              @media (max-width: 767px) {
                width: 280px;
                justify-content: space-between;
              }
              @media (max-width: 320px) {
                width: 200px;
              }
            }
            .invoice-icon-wrap {
              width: 45px;
              height: 45px;
              background: #e5c243;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-right: 12px;
              img {
                height: 22px;
                width: 22px;
              }
              @media (max-width: 767px) {
                margin-right: 0;
                margin-bottom: 10px;
              }
            }
            .cell-text-primary {
              font-weight: 500;
              font-size: 16px;
              line-height: 24px;
              color: #162642;
            }
            .cell-text-lable {
              font-weight: 500;
              font-size: 15px;
              line-height: 24px;
              color: #000000;
              // width: 180px;
            }
            .incomplete-text {
              color: #e5c243;
            }
            .paid-text {
              color: #53a100;
            }
            .incomplete-icon {
              color: #e5c243;
              font-size: 18px;
              margin-right: 6px;
            }
            .paid-icon {
              color: #53a100;
              font-size: 18px;
              margin-right: 6px;
            }
            .paynow-btn {
              padding: 7px 14px;
              border-radius: 34px;
              background-color: #e5c243;
              font-weight: 500;
              font-size: 15px;
              line-height: 27px;
              text-align: center;
              color: #000000;
              cursor: pointer;
              margin-right: 12px;
            }
            .download-btn {
              height: 40px;
              width: 40px;
              border-radius: 20px;
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: #e5c243;
              cursor: pointer;
              .anticon {
                font-size: 22px;
                font-weight: bolder;
                color: #000000;
              }
            }
          }
        }
      }
    }
  }
  .paginationContainer {
    display: flex;
    justify-content: center;
    margin: 50px 0;
    .ant-pagination {
      .ant-pagination-item-link,
      .ant-pagination-item {
        background: #ffffff;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
        border-radius: 28px;
        svg {
          margin-bottom: 7px;
        }
      }
      .ant-pagination-item-active {
        background: #e5c243;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
        border-radius: 28px;
        border: none;
      }
    }
  }
}
