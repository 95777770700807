.mainDashboardPage {
  &.dashboard-page {
    .mainContainer {
      min-width: unset;
      .dashboard-content {
        text-align: left;
        .updatesRow {
          .mainDashboardCardCon {
            .headerContentCon {
              padding: 0 0 0 32px;
            }
            .emailSendCon {
              margin: 0 0 0 32px;
              .sendInviteMainCon {
                gap: 22px;
                .sendInviteCon {
                  flex: 1;
                  margin-bottom: 44px;
                  .sendInviteText {
                    margin-bottom: 15px;
                  }
                }
              }
            }
          }
        }
        .welcomeCon {
          padding: 14px;
          box-shadow: 0px 2px 19px rgba(32, 14, 50, 0.12);
          border-radius: 13px;
          .frameIcon {
            top: inherit;
            left: 30px;
            bottom: 13px;
          }
          .welcomeContentCon {
            padding: 18px 80px 24px 180px;
            .welcomeText {
              margin-bottom: 2px;
            }
          }
        }

        .updatesRow {
          margin-top: 36px;

          .mainDashboardCardCon {
            background: #ffffff;
            box-shadow: 0px 2px 19px rgba(32, 14, 50, 0.12);
            border-radius: 13px;
            padding: 18px;
            &.fullHeight {
              .headerCardCon {
                max-height: 81px;
                height: 100%;
                .leftHeaderCon {
                  .updatesText {
                    font-size: 21px;
                    line-height: 41px;
                  }
                }
              }
              .ant-divider-horizontal {
                margin: 21px 0;
              }
              .emailSendCon {
                max-width: unset;
                margin: 0 0 0 32px;
                .sendInviteMainCon {
                  margin-top: 31px;
                }
                .socialMediaCon {
                  margin-bottom: 36px;
                }
              }
              .headerContentCon {
                padding: 0;
                margin-bottom: 30px;
              }
            }
            .ant-divider-horizontal {
              margin: 15px 0;
              border-top: 1px solid #d4d4d4e0;
            }

            .headerCardCon {
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 0 0 0 14px;

              .leftHeaderCon {
                display: flex;
                align-items: center;

                .updatesIcon {
                  margin-right: 15px;
                }

                .updatesText {
                  font-style: normal;
                  font-weight: 500;
                  font-size: 17px;
                  line-height: 41px;
                  text-transform: capitalize;
                  color: #000000;
                }

                .headerNoteText {
                  font-style: normal;
                  font-weight: normal;
                  font-size: 13px;
                  line-height: 20px;
                  color: #000000;
                }
              }

              .rightHeaderCon {
                font-weight: 500;
                font-size: 15px;
                line-height: 41px;
                text-align: right;
                text-transform: capitalize;
                color: #e5c243;
                cursor: pointer;
              }
            }

            .headerContentCon {
              display: flex;
              align-items: center;
              justify-content: space-between;

              .tickIcon {
                margin-top: 30px;
                margin-bottom: 20px;
              }

              .thankyouText {
                font-style: normal;
                font-weight: 500;
                font-size: 21px;
                line-height: 25px;
                color: #131543;
                margin-bottom: 10px;
              }

              .submitFeedbackText {
                font-style: normal;
                font-weight: normal;
                font-size: 13px;
                line-height: 15px;
                color: #131543;
                text-align: center;
              }

              .hoursText {
                font-style: normal;
                font-weight: bold;
                font-size: 21px;
                line-height: 25px;
                color: #131543;
                margin-top: 10px;
              }

              .detailPageText {
                font-weight: 500;
                font-size: 16px;
                line-height: 30px;
                text-transform: capitalize;
                color: #000000;
              }

              .dateText {
                font-weight: normal;
                font-weight: 400;
                font-size: 15px;
                line-height: 30px;
                text-transform: capitalize;
                color: rgba(0, 0, 0, 0.59);
              }

              .viewHeaderButton {
                background: #e5c243;
                border-radius: 34px;
                font-weight: 500;
                font-size: 15px;
                text-align: center;
                text-transform: capitalize;
                color: #000000;
                min-width: 75px;
              }
            }

            .startContenetCon {
              justify-content: center;
              flex-direction: column;

              .ant-rate {
                font-size: 30px;
              }

              textarea {
                background: #ffffff;
                border: 1px solid #b0b0b0;
                box-sizing: border-box;
                border-radius: 10px;
                margin-top: 25px;
                margin-bottom: 25px;
                max-width: 500px;
              }

              .sendButton {
                background: #e5c243;
                border-radius: 34px;
                min-width: 75px;
                font-weight: 500;
                font-size: 15px;
                text-align: center;
                text-transform: capitalize;
                color: #000000;
              }
            }

            .emailSendCon {
              max-width: 500px;
              margin: auto;

              .sendInviteMainCon {
                display: flex;
                justify-content: space-between;
                margin-top: 25px;

                .sendInviteCon {
                  margin-bottom: 30px;

                  .sendInviteText {
                    font-weight: 500;
                    font-size: 17px;
                    line-height: 24px;
                    color: #000000;
                    margin-bottom: 16px;
                  }

                  .emailInputCon {
                    position: relative;

                    .ant-input {
                      background: #ffffff;
                      border: 1px solid #b0b0b0;
                      box-sizing: border-box;
                      border-radius: 37px;
                      min-height: 37px;
                      padding-right: 40px;
                      font-weight: normal;
                      font-size: 14px;
                      line-height: 24px;
                      color: rgba(0, 0, 0, 0.6);
                    }

                    .sendIcon {
                      position: absolute;
                      right: 10px;
                      cursor: pointer;
                      top: 6px;
                    }
                  }
                }
              }

              .socialMediaCon {
                margin-bottom: 16px;

                .socialMediaText {
                  font-style: normal;
                  font-weight: 500;
                  font-size: 17px;
                  line-height: 24px;
                  color: #000000;
                  margin-bottom: 16px;
                }
                .btn-group {
                  display: flex;
                  gap: 17px;
                  flex-wrap: wrap;
                  a {
                    background: #e5c243;
                    border-radius: 35px;
                    color: #000000;
                    font-weight: 600;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 3px;
                    max-width: 136px;
                    width: 100%;
                    height: 33px;
                    font-size: 18px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.fullHeight {
  height: 100%;
}

@media (max-width: 991px) {
  .mainDashboardPage {
    &.dashboard-page {
      .mainContainer {
        padding: 0 15px;
        .dashboard-content {
          padding: 15px 0;
          .welcomeCon {
            .frameIcon {
              left: 30px;
              bottom: 15px;
              top: unset;
              svg {
                width: auto;
              }
            }

            .welcomeContentCon {
              padding-top: 60px;
              .welcomeText,
              .welcomeSubText {
                text-align: center;
              }
            }
          }
          .updatesRow {
            .mainDashboardCardCon {
              height: auto;
              &.fullHeight {
                .emailSendCon {
                  margin: 0 0 0 7px;
                }
                .headerCardCon {
                  padding: 0;
                }
              }
              .emailSendCon {
                .socialMediaCon {
                  .btn-group {
                    button {
                      max-width: 90px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1170px) {
  .mainDashboardPage
    .dashboard-content
    .updatesRow
    .mainDashboardCardCon
    .emailSendCon
    .sendInviteMainCon {
    flex-direction: column;
  }
  .mainDashboardPage {
    &.dashboard-page {
      .mainContainer {
        .dashboard-content {
          .updatesRow {
            .mainDashboardCardCon {
              &.fullHeight {
                .headerCardCon {
                  max-height: 111px;
                }
              }
            }
          }
          .welcomeCon {
            .welcomeContentCon {
              padding: 18px 40px 24px 150px;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 520px) {
  .mainDashboardPage {
    &.dashboard-page {
      .mainContainer {
        .dashboard-content {
          .welcomeCon {
            margin-top: 60px;
            padding: 12px 13px;
            .welcomeContentCon {
              padding: 70px 15px 26px 15px;
              .welcomeSubText {
                width: 70%;
                margin: 0 auto;
              }
            }
            .frameIcon {
              top: -60px;
              left: 50%;
              transform: translateX(-50%);
              svg {
                width: 145px;
              }
            }
          }
          .updatesRow {
            .mainDashboardCardCon {
              padding: 17px 25px 25px 25px;
              .headerCardCon {
                padding: 0;
              }
              .headerContentCon {
                padding: 0;
              }
              &.fullHeight {
                padding: 17px 21px 33px 21px;
                .headerCardCon {
                  max-height: 100%;
                  padding-left: 8px;
                }
                .emailSendCon {
                  .socialMediaCon {
                    margin: 0;
                  }
                  .sendInviteMainCon {
                    gap: 0;
                    .sendInviteCon {
                      margin-bottom: 47px;
                    }
                  }
                }
              }
              .emailSendCon {
                .socialMediaCon {
                  .btn-group {
                    a {
                      max-width: 100%;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 375px) {
  .mainDashboardPage {
    &.dashboard-page {
      .mainContainer {
        .dashboard-content {
          .welcomeCon {
            .welcomeContentCon {
              .welcomeSubText {
                width: 100%;
              }
            }
          }
          .updatesRow {
            .mainDashboardCardCon {
              .headerContentCon {
                padding: 0;
              }
              .headerCardCon {
                padding: 0;
                .leftHeaderCon {
                  .updatesIcon {
                    margin-right: 8px;
                  }
                }
              }
              &.fullHeight {
                .headerCardCon {
                  padding: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}
