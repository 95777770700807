.Service {
  &.Corporate-wrap {
    .banner {
      border-bottom: 2px solid #fff;
      padding-bottom: 0;
      .banner-grid {
        .banner-set {
          padding: 120px 0;
          margin: 102px 0 25px 0;
          &--left {
            h1 {
              margin-bottom: 23px;
              span {
                display: unset;
                margin-left: 8px;
              }
            }
            p {
              margin: 0;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 992px) {
  .Service {
    &.Corporate-wrap {
      .banner {
        border-bottom: 3px solid #fff;
        .banner-grid {
          .banner-set {
            padding: 102px 0 115px 0;
            margin: 0;
            &--left {
              order: 2;
              margin-top: 69px;
            }
            &--right {
              order: 1;
              margin-top: 92px;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .Service {
    &.Corporate-wrap {
      .banner {
        .banner-grid {
          .banner-set {
            padding: 0;
            margin-bottom: 0;
            &--left {
              order: 2;
              margin: 69px auto 115px auto;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 576px) {
  .Service {
    &.Corporate-wrap {
      .banner {
        .banner-grid {
          .banner-set {
            &--left {
              max-width: 90%;
              h1 {
                line-height: 44px;
                font-size: 30px;
                margin-bottom: 13px;
              }
              .yellowText {
                line-height: 44px;
                font-size: 30px;
              }
              p {
                font-size: 20px;
                line-height: 30px;
              }
            }
          }
        }
      }
    }
  }
}
