@import "../../../App.scss";

.AppLive {
  text-align: center;
  .title {
    font-size: 20px;
    color: #fff;
    font-weight: 700;
    padding-bottom: 2rem;
    display: block;
    span {
      color: $color-yellow;
    }
  }

  .AppLive-links {
    margin-bottom: 3rem;
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      li {
        list-style: none;
        margin: 4px;
        // flex: 0 1 calc(25% - 4px);

        a {
          background: #fff;
          border-radius: 100px;
          padding: 10px 22px;
          display: flex;
          align-items: center;
          flex-direction: row;
          border: 4px solid $color-black;
          transition: 0.5s ease;
          img {
            width: 20px;
          }
          &:hover {
            border: 4px solid $color-yellow;
          }
          &.project-active {
            border: 4px solid $color-yellow;
          }
          span {
            font-size: 16px;
            padding-left: 14px;
            color: $color-black;
          }
        }
      }
    }
  }

  .TargetDate {
    .ant-form-item-label {
      label {
        color: #fff;
      }
    }
    .ant-picker {
      border-radius: 100px;
      padding: 14px 18px 14px;
      width: 100%;
    }
    .ant-picker-input {
      gap: 23px;
      input {
        order: 2;
        font-weight: 500;
        font-size: 20px;
        text-transform: capitalize;
        color: #000000;
      }
      .ant-picker-suffix {
        width: 30px;
        height: 30px;
        content: url("../../../Assets/images/Onboarding/date-icon.svg");
      }
    }
  }
  @media (max-width: 768px) {
    .TargetDate {
      .target-date-label {
        .ant-row {
          .ant-col {
            label {
              font-weight: 700;
              font-size: 21px;
              line-height: 25px;
              text-align: center;
              color: #ffffff;
              align-items: center;
              justify-content: center;
              display: flex;
            }
          }
        }
      }
      .ant-picker-input {
        input {
          font-size: 18px;
        }
      }
    }
  }
}
