.payment-step-main {
  .payment-details-wrapper {
    .payment-wrapper {
      @media (max-width: 767px) {
        margin-top: 50px;
      }
      .payment-text {
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #162642;
        margin-bottom: 15px;
      }
      .payment-details {
        font-weight: 500;
        font-size: 29px;
        line-height: 24px;
        color: #162642;
      }
    }
    .balance-and-account-wrapper {
      margin-top: 50px;
      column-gap: 120px;
      @media (max-width: 767px) {
        column-gap: 0;
        row-gap: 55px;
        margin-top: 55px;
      }
      .balance-wrapper {
        .balance-text {
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          color: #162642;
        }
        .balance-details {
          font-weight: 500;
          font-size: 20px;
          line-height: 24px;
          color: #162642;
        }
      }
      .account-wrapper {
        .account-text {
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          color: #162642;
        }
        .account-details {
          font-weight: 500;
          font-size: 20px;
          line-height: 24px;
          color: #162642;
        }
      }
    }
  }
}
