@import "../../../App.scss";

.check-your-mail {
  display: grid;
  align-items: center;
  justify-content: center;
  text-align: center;
  justify-items: center;
  img {
    width: 120px;
    margin: 0 auto;
  }

  .title {
    padding-top: 1rem;
    color: #fff;
    font-size: 24px;
    font-weight: 700;
    color: $color-yellow;
  }
  .sub-text {
    color: #fff;
    font-size: 14px;
    font-weight: 400;
  }
}
