.slider-wrap {
  .slider-img-wrap {
    position: absolute;
    top: 68px;
    right: 0;
    width: 50%;
    height: calc(100% - 68px);
    display: flex;
    align-items: flex-end;
    justify-content: end;

    img {
      height: 100%;
      object-fit: cover;
      &.mobile-img {
        display: none;
      }
    }
  }
  .RecentWorksCarousel-main-wrapper {
    height: 745px;
    .carousel-control-next-icon {
      background-image: url(../../../Assets/images/home/arrow-right.svg);
    }
    .carousel-control-prev-icon {
      background-image: url(../../../Assets/images/home/arrow-left.svg);
    }
    .carousel-control-next {
      right: -40px;
    }
    .carousel-control-prev {
      left: -40px;
    }
    .carousel-item {
      height: 100%;
      div {
        height: 100%;
      }
    }
    .carousel-indicators {
      gap: 22px;
      bottom: 10%;
      button {
        width: 22px;
        height: 22px;
        border-radius: 100%;
        background-color: #fff;
        opacity: unset;
        margin: 0;
        &.active {
          background-color: #e5c243;
        }
      }
    }
    .carousel-inner {
      height: 100%;
    }
  }
}
@media (max-width: 1199px) {
  .slider-wrap {
    .RecentWorksCarousel-main-wrapper {
      height: 625px;
    }
    .slider-img-wrap {
      img {
        width: 80%;
        height: auto;
      }
    }
  }
}
@media (max-width: 991px) {
  .slider-wrap {
    min-height: 1250px;
    padding: 106px 0 150px 0;
    .slider-img-wrap {
      width: 100%;
      margin-left: auto;
      position: unset;
      img {
        height: 90%;
        object-fit: cover;
        width: 90%;
        display: none;
        &.mobile-img {
          display: flex;
        }
      }
    }
    .RecentWorksCarousel-main-wrapper {
      height: 400px;
      position: unset;
      .carousel-item {
        .carousel-item-inner-set {
          justify-content: flex-start;
        }
      }
      .carousel-indicators {
        position: absolute;
        bottom: 92px;
      }
      .carousel-control-next {
        right: 0;
        height: 606px;
      }
      .carousel-control-prev {
        left: 0;
        height: 606px;
      }
      .carousel-control-next,
      .carousel-control-prev {
        .carousel-control-next-icon,
        .carousel-control-prev-icon {
          width: 26px;
          height: 26px;
        }
      }
    }
  }
}
@media (max-width: 576px) {
  .slider-wrap {
    min-height: unset;
    padding: 0 0 150px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .RecentWorksCarousel-main-wrapper {
      height: 350px;
      .carousel-indicators {
        button {
          height: 15px;
          width: 15px;
        }
        gap: 12px;
      }
    }
    .carousel-control-next,
    .carousel-control-prev {
      display: none;
    }
    .carousel-item {
      .carousel-item-inner-set {
        p {
          font-size: 25px;
          line-height: 31px;
          max-width: 100%;
        }
      }
    }
  }
}
