.paid-invoice-details-page {
  background: #ffffff;
  box-shadow: 0px 2px 19px rgba(32, 14, 50, 0.12);
  border-radius: 13px;
  width: 835px;
  margin: 0 auto 50px;
  @media (min-width: 768px) and (max-width: 991px) {
    width: 90%;
  }
  @media (max-width: 767px) {
    width: 90%;
    margin: 50px auto;
  }
  .paid-invoice-details-page-header {
    background: #000000;
    border-radius: 13px 13px 0px 0px;
    padding: 20px 25px;
    .invoice-number-text {
      font-weight: bold;
      font-size: 22px;
      line-height: 24px;
      color: #ffffff;
    }
    .paid-icon {
      font-size: 32px;
      color: #53a100;
      background: white;
      border-radius: 60%;
      margin-right: 8px;
    }
    .paid-text {
      font-weight: 500;
      font-size: 18px;
      color: #53a100;
    }
    .download-btn {
      height: 34px;
      width: 34px;
      border-radius: 17px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #e5c243;
      margin-left: 24px;
      cursor: pointer;
      .anticon {
        font-size: 22px;
        font-weight: bolder;
        color: #000000;
      }
    }
  }
  .paid-invoice-details-page-body {
  }
}
